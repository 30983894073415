import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';
import { UserRole } from 'types/UserRole';
import { isAxiosError } from 'utils/axios';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.user || initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (state) => state.status !== RequestStatus.IDLE && state.status !== RequestStatus.LOADING,
);
export const selectIsAuthorizedSuccessfully = createSelector(
  [selectDomain],
  (state) => state.status === RequestStatus.SUCCESS && state.error === null, // TODO: check required entitlements value
);

export const selectErrorMessage = createSelector(
  [selectDomain],
  ({ error }: any) => (isAxiosError(error) && error.response?.data?.message) || '',
);

export const selectIsForbidden = createSelector([selectDomain], ({ error }: any) => {
  return isAxiosError(error) && error.response?.status === 403;
});
export const selectIsUnauthorized = createSelector([selectDomain], ({ error }: any) => {
  return isAxiosError(error) && error.response?.status === 401;
});
export const selectIsInternalServerError = createSelector([selectDomain], ({ error }: any) => {
  return isAxiosError(error) && error.response?.status === 500;
});

export const selectUserInfo = createSelector([selectDomain], (state) => state.user);
export const selectUserSources = createSelector([selectDomain], (state) => state.user?.sources);
export const selectUsername = createSelector([selectUserInfo], (state) => state?.username);

export const selectIsSqlEditorEnabled = createSelector(
  [selectUserInfo],
  (userInfo) =>
    userInfo !== null &&
    (userInfo.roles.includes(UserRole.SQL_EDITOR) ||
      userInfo.roles.includes(UserRole.OMNISCI_JUPYTER) ||
      userInfo.roles.includes(UserRole.ADMIN) ||
      userInfo.roles.includes(UserRole.ADMIN_CSM) ||
      userInfo.roles.includes(UserRole.ADMIN_NIMS)),
);

export const selectUserCountries = createSelector([selectUserInfo], (userInfo) =>
  userInfo !== null ? userInfo.countries : [],
);

export const selectUserFilters = createSelector([selectUserInfo], (userInfo) =>
  userInfo !== null ? userInfo.userFilters : [],
);

export const selectIsTrialRole = createSelector(
  [selectUserInfo],
  (userInfo) => !!userInfo?.roles.includes(UserRole.IMPACT_TRIAL),
);

export const selectIsCreateDashboardRole = createSelector(
  [selectUserInfo],
  (userInfo) => !!userInfo?.roles.includes(UserRole.CREATE_DASHBOARD),
);

export const selectIsJupyterEnabled = createSelector(
  [selectUserInfo],
  (userInfo) => userInfo !== null && userInfo.roles.includes(UserRole.OMNISCI_JUPYTER),
);
export const selectIsPlanPredictEnabled = createSelector([selectUserInfo], (userInfo) => {
  const roles = userInfo?.roles || [];

  return [
    UserRole.ADMIN_NIMS,
    UserRole.ADMIN_CSM,
    UserRole.ADMIN,
    UserRole.IMPACT_CANADA_ONLY,
    UserRole.IMPACT_US_ONLY,
    UserRole.UPSTREAM_CONTENT_CAN,
    UserRole.UPSTREAM_CONTENT_US,
    UserRole.UPSTREAM_CONTENT_INTERNATIONAL,
  ].some((role) => roles.includes(role));
});

export const hasMultipleSources = createSelector([selectUserInfo], (userInfo) => userInfo?.hasMultipleSources);

export const userEntitlementsSystemSources = createSelector([selectUserInfo], (userInfo) => userInfo?.sources ?? []);

export const userAvailableDatabases = createSelector(
  [selectUserInfo],
  (userInfo) => userInfo?.availableDatabases ?? [],
);

export const userSelectedDatabase = createSelector([selectDomain], (state) => state.selectedDatabase ?? '');
