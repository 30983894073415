import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { selectMapZoom } from 'app/components/containers/widgets/MapWidget/selectors';
import { selectWellsLegendSettings } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/selectors';
import { RootState } from 'types/RootState';
import { MapWidgetId } from 'types/Widget';

import { MIDSTREAM_MIN_MAP_ZOOM, POINT_SIZE_DEFAULT, VEGA_LAYER_IDS, WELL_PATH_MIN_MAP_ZOOM } from './constants';
import { initialState } from './slice';
import { MapColorBy, MapLayer } from './types';

const selectDomain = (state: RootState) => state?.mapWidgetsLayerSettings || initialState;
const selectDomainByWidgetId = createSelector(
  [selectDomain, (state: RootState, mapWidgetId: MapWidgetId) => mapWidgetId],
  (state, mapWidgetId) => state[mapWidgetId],
);

const selectWellsColorsByByWidgetId = createSelector(
  [selectWellsLegendSettings, (state: RootState, mapWidgetId: MapWidgetId) => mapWidgetId],
  (plannedWellsLegend, mapWidgetId) =>
    mapWidgetId === MapWidgetId.PLAN_MAP_WIDGET && plannedWellsLegend ? [plannedWellsLegend] : [],
);

export const selectSelectedMapLayers = createSelector([selectDomainByWidgetId], ({ layers }) => layers);
export const selectSelectedMapLayerIds = createSelector([selectSelectedMapLayers], (layers) =>
  layers.map((layer) => layer.id),
);

export const selectRemovedLayer = createSelector([selectDomainByWidgetId], ({ removedLayer }) => removedLayer);

const selectSelectedVegaMapLayers = createSelector([selectDomainByWidgetId], ({ layers }) =>
  layers.filter((layer) => VEGA_LAYER_IDS.includes(layer.id)),
);
export const selectVisibleVegaMapLayers = createSelector(
  [selectSelectedVegaMapLayers, selectMapZoom],
  (selectedVegaLayers, mapZoom) => {
    return selectedVegaLayers.filter((layer) => {
      return !(
        (mapZoom <= WELL_PATH_MIN_MAP_ZOOM && layer.id === MapLayer.WELL_PATH) ||
        (mapZoom <= MIDSTREAM_MIN_MAP_ZOOM && layer.id === MapLayer.MIDSTREAM)
      );
    });
  },
);

export const selectVisibleMapLayerSettingsById = createSelector(
  [selectVisibleVegaMapLayers, (store, mapLayerSettings, layerId: MapLayer) => layerId],
  (selectedVegaLayers, layerId) => selectedVegaLayers.find((layer) => layer.id === layerId),
);

export const selectVisiblePredictMapLayer = createSelector([selectSelectedMapLayers], (selectedLayers) => {
  return selectedLayers.find((layer) => layer.id === MapLayer.PREDICTION);
});

export const selectLayersVisibleColorsBy = createSelector([selectVisibleVegaMapLayers], (visibleLayers) =>
  visibleLayers.reduce(
    (acc: { id: MapLayer; colorByValue: MapColorBy }[], layer) =>
      layer?.colorByValue ? [...acc, { id: layer.id, colorByValue: layer.colorByValue }] : acc,
    [],
  ),
);

export const selectVisibleColorsBy = createSelector(
  [selectLayersVisibleColorsBy, selectWellsColorsByByWidgetId],
  (visibleLayers, additionalLegends) => [...additionalLegends, ...visibleLayers],
);

export const selectIsVisibleLayersWithoutDomain = createSelector([selectVisibleVegaMapLayers], (layers) =>
  layers.some((layer) => layer && layer.colorByValue?.domain === null),
);

export const selectMapLayerSettings = createSelector(
  [selectDomainByWidgetId, (store, mapWidgetId, layerId: MapLayer) => layerId],
  ({ layers }, layerId) => layers.find((layer) => layer.id === layerId),
);

export const selectPointSize = createSelector([selectMapLayerSettings], (layerSettings) =>
  layerSettings ? layerSettings.size : POINT_SIZE_DEFAULT,
);

export const selectIsColorDomainLoading = createSelector(
  [selectDomainByWidgetId],
  ({ domainStatus }) => domainStatus === RequestStatus.LOADING,
);

export const selectMapLayerOpacity = createSelector(
  [selectMapLayerSettings],
  (mapLayerSettings) => mapLayerSettings?.opacity ?? 100,
);
