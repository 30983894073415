export enum PredictExportDataKey {
  BASIN = 'BASIN',
  GEO_PROVINCE = 'GEO_PROVINCE',
  UWI = 'UWI',
  PRODUCTION_CURVE = 'PRODUCTION_CURVE',
  UNIT = 'UNIT',
  TYPE = 'TYPE',
  CURVE = 'CURVE',
}

export type PredictExportDataRow = Record<PredictExportDataKey | string, string | number | null>;
