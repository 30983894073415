import { isSomeEnum } from 'utils/types/enums-check';

export enum AcreageAndInventoryColumn {
  ACREAGE_EST_CLASS = 'Acreage_Est_Class',
  ACREAGE_IDENTIFIER = 'Acreage_Identifier',
  ACREAGE_OPERATOR_FOOTAGE_DRILLED = 'Acreage_Operator_Footage_Drilled',
  ACREAGE_OPERATOR_FOOTAGE_DRILLED_AND_PROPOSED = 'Acreage_Operator_Footage_Drilled_and_Proposed',
  ACREAGE_PERCENT_ACRES_ATTRIBUTED_TO_OPERATOR = 'Acreage_Percent_Acres_Attributed_to_Operator',
  ACREAGE_TOTAL_FOOTAGE_DRILLED = 'Acreage_Total_Footage_Drilled',
  ACREAGE_TOTAL_FOOTAGE_DRILLED_AND_PROPOSED = 'Acreage_Total_Footage_Drilled_and_Proposed',
  COUNTRY = 'Country',
  OPERATOR = 'Operator',
  GRID_ID = 'Grid_ID',
  PLAY_DESIGNATION = 'Play_Designation',
  ROW_ID = 'Row_ID',
  PLAY_SUBPLAY = 'Play_Subplay',
  WKT = 'WKT',
  BENCHES_AVAILABLE_PER_SECTION = 'Benches_Available_Per_Section',
  WELLS_AVAILABLE_PER_BENCH = 'Wells_Available_Per_Bench',
  WASTAGE = 'Wastage',
  FUTURE_LATERAL_FOOTAGE = 'Future_Lateral_Footage',
  FUTURE_ESTIMATED_CAPEX_PER_WELL = 'Future_Estimated_Capex_Per_Well',
  TOTAL_ACRES_IN_POLYGON = 'Total_Acres_In_Polygon',
  WELLS_ASSUMED_PER_SECTION = 'Wells_Assumed_Per_Section',
  TOTAL_FOOTAGE_AVAILABLE = 'Total_Footage_Available',
  TOTAL_ACRES_AVAILABLE_AFTER_WASTAGE = 'Total_Acres_Available_After_Wastage',
  SPACING_ASSUMED_BETWEEN_WELLS_IN_BENCH = 'Spacing_Assumed_Between_Wells_In_Bench',
  EQUIVALENT_STANDARD_WELLS_DRILLED = 'Equivalent_Standard_Wells_Drilled',
  TOTAL_FOOTAGE_REMAINING_TRUNC = 'Total_Footage_Remaining_Trunc',
  WELLS_REMAINING_TRUNC = 'Wells_Remaining_Trunc',
  CAPEX_REMAINING_TRUNC = 'Capex_Remaining_Trunc',
  CURRENT_ACRES_DEV_AT_ASSUMED_SPACING_UNTRUNC = 'Current_Acres_Dev_At_Assumed_Spacing_Untrunc',
  CURRENT_ACRES_DEV_AT_ASSUMED_SPACING_TRUNC = 'Current_Acres_Dev_At_Assumed_Spacing_Trunc',
  PERCENT_DEVELOPED_TRUNC = 'Percent_Developed_Trunc',
  REMAINING_ACRES_AT_ASSUMED_SPACING_TRUNC = 'Remaining_Acres_At_Assumed_Spacing_Trunc',
}

export const isAcreageAndInventoryColumn = isSomeEnum(AcreageAndInventoryColumn);
