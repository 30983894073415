import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.notifications || initialState;

export const selectActiveProductUpdateInWindow = createSelector(
  [selectDomain],
  (state) => state.activeProductUpdateInWindow,
);

export const selectData = createSelector([selectDomain], (state) => state.notifications);
export const selectProductUpdateType = createSelector([selectDomain], (state) => state.productUpdateType);
export const selectScrollProductUpdateState = createSelector([selectDomain], (state) => state.scrollProductUpdateState);
export const selectStatus = createSelector([selectDomain], (state) => state.status);
export const selectError = createSelector([selectDomain], (state) => state.error);
