import { isSomeEnum } from 'utils/types/enums-check';

export enum CompanyAcreageColumn {
  ACRE_MEASURE = 'Acre_Measure',
  ACREAGE_TOTAL = 'Acreage_Total',
  ACREAGE_TYPE = 'Acreage_Type',
  BASIN = 'Basin',
  BOUNDARY_QUALITY = 'Boundary_Quality',
  BUYER = 'Buyer',
  COMMENTS = 'Comments',
  COMPANY = 'Company',
  OPERATOR = 'Operator',
  COUNTRY = 'Country',
  POLYGON_ID = 'Polygon_ID',
  DEAL_LEVEL = 'Deal_Level',
  DEAL_SUMMARY_LINK = 'Deal_Summary_Link',
  DEAL_TITLE = 'Deal_Title',
  DEAL_TYPE = 'Deal_Type',
  INDUSTRY = 'Industry',
  PAGE_NUMBER = 'Page_Number',
  PLAY_DESIGNATION = 'Play_Designation',
  PRESENTATION_TITLE = 'Presentation_Title',
  PRESENTATION_YEAR = 'Presentation_Year',
  REPORTED_SEGMENT = 'Reported_Segment',
  SELLER = 'Seller',
  TOTAL_TRANSACTION_VALUE_USDMM = 'Total_Transaction_Value_USDMM',
  TRANSACTION_ID = 'Transaction_ID',
  TRANSACTION_TYPE = 'Transaction_Type',
  TRANSACTION_QUARTER = 'Transaction_Quarter',
  TRANSACTION_YEAR = 'Transaction_Year',
  WKT = 'WKT',
  DATE_ADDED = 'Date_Added',
  DATE_ANNOUNCED = 'Date_Announced',
  CURRENT_FLAG = 'Current_Flag',
}

export const isCompanyAcreageColumn = isSomeEnum(CompanyAcreageColumn);
