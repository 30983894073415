import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { selectVisiblePredictMapLayer } from 'app/components/containers/map/MapLayersSelect/selectors';
import { RootState } from 'types/RootState';
import { MapWidgetId } from 'types/Widget';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.mapWidgetsPredictLayers || initialState;
const selectDomainByWidgetId = createSelector(
  [selectDomain, (state: RootState, mapWidgetId: MapWidgetId) => mapWidgetId],
  (state, mapWidgetId) => state[mapWidgetId],
);

export const selectPredictMapLayerData = createSelector([selectDomainByWidgetId], ({ data }) => data);
export const selectPredictMapLayerDataValues = createSelector(
  [selectPredictMapLayerData],
  (data) => data?.map((item) => item.value) || [],
);

export const selectShowPredictMapLayer = createSelector(
  [selectVisiblePredictMapLayer, selectPredictMapLayerDataValues],
  (visiblePredictLayer, predictMapLayerData) => !!visiblePredictLayer && !!predictMapLayerData.length,
);

export const selectIsPredictMapLayerLoading = createSelector(
  [selectDomainByWidgetId],
  (state) => state.status === RequestStatus.LOADING,
);

export const selectIsPredictMapLayerRendered = createSelector([selectDomainByWidgetId], (state) => state.rendered);
export const selectPredictMapLayerError = createSelector([selectDomainByWidgetId], (state) => state.error);
