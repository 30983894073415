import { TestLocators } from 'constants/Locators';

import { useParamSelector } from 'hooks/useParamSelector';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EconomicNumberSliderWithInputType } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/types';
import { actions as wellDesignCapexVariablesActions } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/slice';
import { planPredictEconomicsControlPanelChange } from 'common/analytics';
import { translations } from 'locales/i18n';
import { MapWidgetId } from 'types/Widget';

import { selectWellDesignCapexCalculateOptions, selectWellDesignCapexCalculateVariable } from '../selectors';
import { NumberWellDesignCapexCalculateParameter, NumberWellDesignCapexControl } from '../types';

const labelName = translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX;

type useGetEconomicVariablesDataType = {
  paramName: NumberWellDesignCapexCalculateParameter;
};

export const useGetEconomicVariablesParameters = ({ paramName }: useGetEconomicVariablesDataType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useParamSelector(selectWellDesignCapexCalculateVariable, paramName) ?? 0;
  const options = useParamSelector(selectWellDesignCapexCalculateOptions, paramName);
  const onChangeHandler = useCallback(
    (value: number) => {
      planPredictEconomicsControlPanelChange(
        paramName,
        String(value),
        t(translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.TITLE),
        MapWidgetId.ECONOMICS_MAP_WIDGET,
      );

      dispatch(wellDesignCapexVariablesActions.setWellDesignCapexCalculateNumberValue({ value, paramName }));
    },
    [dispatch, paramName, t],
  );

  return { value, options, onChangeHandler };
};

export const useGetWellDesignCapexData = (): EconomicNumberSliderWithInputType[] => {
  return [
    {
      label: labelName.TRUE_VERTICAL_DEPTH.TITLE,
      testId: TestLocators.WellDesignCapex_Calculate_TrueVerticalDepth,
      ...useGetEconomicVariablesParameters({ paramName: NumberWellDesignCapexControl.TRUE_VERTICAL_DEPTH }),
    },
    {
      label: labelName.LATERAL_LENGTH.TITLE,
      testId: TestLocators.WellDesignCapex_Calculate_LateralLength,
      ...useGetEconomicVariablesParameters({ paramName: NumberWellDesignCapexControl.LATERAL_LENGTH }),
    },
    {
      label: labelName.PROPPANT_VOLUME.TITLE,
      testId: TestLocators.WellDesignCapex_Calculate_ProppantVolume,
      ...useGetEconomicVariablesParameters({ paramName: NumberWellDesignCapexControl.PROPPANT_VOLUME }),
    },
  ];
};
