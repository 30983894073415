import { ComponentType } from 'react';
import { RouteProps } from 'react-router';

export type CommonRouteProps = RouteProps & {
  component: ComponentType<any> | ComponentType<any>;
  layout?: ComponentType<any>;
};

export function CommonRoute({ component: Component, layout: Layout, ...rest }: CommonRouteProps) {
  return Layout ? (
    <Layout>
      <Component {...rest} />
    </Layout>
  ) : (
    <Component {...rest} />
  );
}
