export enum CurveType {
  LIQUID = 'liquid',
  GAS = 'gas',
  WATER = 'water',
}

export enum ProductionTypeValue {
  CUM = 'cum',
  RATE = 'rate',
}

export enum P_Curve {
  P10 = 'P10',
  P30 = 'P30',
  P50 = 'P50',
  P70 = 'P70',
  P90 = 'P90',
}
