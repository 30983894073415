import { TestLocators } from 'constants/Locators';

import { useDispatch, useSelector } from 'react-redux';

import { selectEconomicsMapFilterToggleValue } from 'app/components/common/CommonConfigTabFilter/selectors';
import { actions } from 'app/components/common/CommonConfigTabFilter/slice';
import { LabeledToggle } from 'app/components/common/LabeledToggle';
import { translations } from 'locales/i18n';

export function MapFilterSwitch() {
  const dispatch = useDispatch();
  const filterMapToggleValue = useSelector(selectEconomicsMapFilterToggleValue);
  const onChangeHandler = () => dispatch(actions.changeMapFilterToggle({ mapFilterToggle: !filterMapToggleValue }));

  return (
    <LabeledToggle
      label={translations.CONFIG_PANEL.COMMON.FILTERS.MAP.TOGGLE.TITLE}
      testId={TestLocators.Economics_ConfigPanel_Filter_Map_Filter_Toggle}
      checked={filterMapToggleValue}
      onChange={onChangeHandler}
    />
  );
}
