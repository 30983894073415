import { TestLocators } from 'constants/Locators';

import { ClickAwayListener, Grow, MenuList, MenuProps, Paper, Popper, PopperPlacementType } from '@material-ui/core';
import { ReferenceObject } from 'popper.js';
import { MouseEvent, PropsWithChildren, useCallback } from 'react';

import { ButtonDownListener } from 'app/components/common/ButtonDownListener';
import { useStyles } from 'app/components/containers/layout/Header/HeaderMenu/styles';

import { useStyles as useSelfStyles } from './styles';

export interface CustomHeaderMenuProps extends PropsWithChildren<MenuProps> {
  paperTestId?: TestLocators | string;
  menuListTestId?: TestLocators | string;
  popperPlacement?: PopperPlacementType;
}

export function CustomHeaderMenu({
  open,
  children,
  anchorEl,
  onClose,
  paperTestId,
  menuListTestId,
  popperPlacement = 'bottom-end',
}: CustomHeaderMenuProps) {
  const classes = useStyles();
  const selfClasses = useSelfStyles();

  const handleClose = useCallback(
    (event: MouseEvent<Document>) => {
      if (
        (event.target instanceof HTMLElement && event.target.classList.contains('MuiListItem-root')) ||
        event.target === document.body
      ) {
        return;
      }
      if (onClose) {
        onClose(event, 'backdropClick');
      }
    },
    [onClose],
  );

  const handleListKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        event.preventDefault();
        onClose(event, 'escapeKeyDown');
      }
    },
    [onClose],
  );

  return (
    <Popper
      className={selfClasses.popper}
      open={open}
      anchorEl={anchorEl as ReferenceObject}
      transition
      disablePortal
      placement={popperPlacement}
      data-testid={`${TestLocators.Menu_Popper}_${open ? 'Open' : 'Closed'}`}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement.includes('bottom') ? 'center top' : 'center bottom' }}
        >
          <Paper className={classes.menuPaper} data-testid={paperTestId}>
            <ButtonDownListener handler={handleListKeyDown}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList classes={{ padding: classes.menuPadding }} data-testid={menuListTestId}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </ButtonDownListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
