import cn from 'clsx';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ProductUpdateType } from 'types/ProductUpdate';

import { actions as notificationsActions } from './slice';
import { useStyles } from './styles';
import { DATA_UPDATES_VALUE_ATTRIBUTE, NotificationLinkType, ParsedProductUpdate, ProductUpdateState } from './types';

export interface NotificationsListProps {
  notifications?: ParsedProductUpdate[];
  handleSelectNotificationsCategory: (category: ProductUpdateType) => void;
}

export const NotificationsList = ({
  notifications = [],
  handleSelectNotificationsCategory,
}: NotificationsListProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!descriptionRef.current) return;

    const handleLinkClick = (event: MouseEvent) => {
      const link = (event.target as HTMLElement).closest(`[${DATA_UPDATES_VALUE_ATTRIBUTE}]`);
      if (!link) return;

      switch (link.textContent) {
        case NotificationLinkType.DataUpdates: {
          handleSelectNotificationsCategory(ProductUpdateType.DataUpdate);
          break;
        }
        case NotificationLinkType.PlatformUpdates: {
          handleSelectNotificationsCategory(ProductUpdateType.ProductUpdate);
          break;
        }
        default: {
          return;
        }
      }

      const attributeData = link.getAttribute(DATA_UPDATES_VALUE_ATTRIBUTE);
      const productUpdates: ProductUpdateState = attributeData ? JSON.parse(attributeData) : {};
      dispatch(notificationsActions.setScrollProductUpdateState(productUpdates));
    };

    descriptionRef.current.addEventListener('click', handleLinkClick);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => descriptionRef.current?.removeEventListener('click', handleLinkClick);
  }, [descriptionRef, handleSelectNotificationsCategory, dispatch]);

  const getNotification = useCallback(
    (
      {
        messageId,
        isRead,
        releaseDate,
        description,
      }: { messageId: string; isRead: boolean; releaseDate: ReactNode; description: string },
      index: number,
    ) => {
      const updatesValue = JSON.stringify({ messageId, date: releaseDate } as ProductUpdateState);
      const updatedDescription = description
        .replace(
          NotificationLinkType.DataUpdates,
          `<a data-updates-value='${updatesValue}'>${NotificationLinkType.DataUpdates}</a>`,
        )
        .replace(
          NotificationLinkType.PlatformUpdates,
          `<a data-updates-value='${updatesValue}'>${NotificationLinkType.PlatformUpdates}</a>`,
        );

      return (
        <div
          key={`${messageId}${index}`}
          className={cn(classes.notification, releaseDate, {
            [classes.descriptionBoldText]: !isRead,
          })}
        >
          <div className={classes.date}>
            {!isRead && <div className={cn(classes.unreadNotificationDot, classes.dotIndicator)}></div>}
            {releaseDate}
          </div>
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: updatedDescription }} />
        </div>
      );
    },
    [classes],
  );

  const renderNotifications = useMemo(
    () => <div ref={descriptionRef}>{notifications?.map(getNotification)}</div>,
    [notifications, getNotification],
  );

  return renderNotifications;
};
