import { ProdCurvesResponsePayload } from 'api/curves';
import i18next from 'i18next';

import {
  generateChartKeyByDataItem,
  getNumKeys,
} from 'app/components/containers/widgets/ProductionCurves/PredictProdCurvesWidget/converters';
import { PROD_CURVE_DATA_KEY } from 'app/components/containers/widgets/ProductionCurves/PredictProdCurvesWidget/types';
import { CurveType, P_Curve, ProductionTypeValue } from 'types/Curve';
import { CurveColumnValue, CurveToModelMap } from 'types/PlanPredictModel';

import { ExportingUnitNameByTaskForCumulative, ExportingUnitNameByTaskForRate } from './constants';
import { PredictExportDataKey, PredictExportDataRow } from './types';

function getNumKeysWithBorders(obj: {}, min: number | null, max: number | null) {
  return Object.keys(obj).filter((key) => {
    const numbValue = Number(key);

    return !isNaN(numbValue) && (min === null || numbValue >= min) && (max === null || numbValue <= max);
  });
}

export function getExportingProdCurvesData(
  loadedData: ProdCurvesResponsePayload[] | null,
  selectedKeys: PROD_CURVE_DATA_KEY[],
  minX: number | null,
  maxX: number | null,
  maxNotEmptyX: number,
): PredictExportDataRow[] | null {
  const result = [] as PredictExportDataRow[];
  if (loadedData) {
    const numKeys = getNumKeysWithBorders(
      loadedData[0],
      minX,
      maxX === null || maxNotEmptyX < maxX ? maxNotEmptyX : maxX,
    );

    Object.values(loadedData).forEach((item) => {
      if (isExportingItemCorrespondSelectedKeys(item, selectedKeys)) {
        const baseState = getExportingItemValueForWordKeys(item);

        numKeys.forEach((key) => {
          baseState[key] = item[key];
        });

        result.push(baseState);
      }
    });
  }

  return result.length ? result : null;
}

function isExportingItemCorrespondSelectedKeys(item: ProdCurvesResponsePayload, keys: PROD_CURVE_DATA_KEY[]): boolean {
  const compiledKey = generateChartKeyByDataItem(item);

  return keys.includes(compiledKey);
}

export const getUnitName = (productionType: ProductionTypeValue, curveType: CurveType) => {
  return productionType === ProductionTypeValue.CUM
    ? ExportingUnitNameByTaskForCumulative[curveType]
    : ExportingUnitNameByTaskForRate[curveType];
};

const getCurveName = (curve: CurveColumnValue, pCurve: P_Curve | null) => {
  return `${i18next.t(`PRODUCTION_PREDICTION.EXPORT.CURVE.${CurveToModelMap[curve].toUpperCase()}`)} ${
    pCurve && pCurve !== P_Curve.P50 ? i18next.t(`PRODUCTION_PREDICTION.EXPORT.CURVE.${pCurve}`) : ''
  }`.trim();
};

const getExportedValueByKey = (item: ProdCurvesResponsePayload, dataKey: PredictExportDataKey) => {
  switch (dataKey) {
    case PredictExportDataKey.BASIN:
      return item.Basin;
    case PredictExportDataKey.GEO_PROVINCE:
      return item.Geologic_Province;
    case PredictExportDataKey.UWI:
      return item.Uwi;
    case PredictExportDataKey.PRODUCTION_CURVE:
      return item.Task;
    case PredictExportDataKey.UNIT:
      return getUnitName(item.Type, item.Task);
    case PredictExportDataKey.TYPE:
      return item.Type;
    case PredictExportDataKey.CURVE:
      return getCurveName(item.Curve, item.P_Curve);
  }
};

function getExportingItemValueForWordKeys(data: ProdCurvesResponsePayload) {
  return Object.values(PredictExportDataKey).reduce((acc, exportDataKey) => {
    return { ...acc, [exportDataKey]: getExportedValueByKey(data, exportDataKey as PredictExportDataKey) };
  }, {} as PredictExportDataRow);
}

export function isExportingProdCurvesDataEmpty(exportingData: PredictExportDataRow[] | null): boolean {
  const result = true;

  if (exportingData) {
    const numKeys = getNumKeys(exportingData[0]);

    for (let i = 0; i < exportingData.length; i++) {
      for (let j = 0; j < numKeys.length; j++) {
        if (exportingData[i][j] !== null && numKeys[j] !== '0') {
          return false;
        }
      }
    }
  }

  return result;
}
