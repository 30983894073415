export enum ConfigTabsGroupId {
  PREDICT = 'PREDICT_CONFIG_TABS',
  PLAN = 'PLAN_CONFIG_TABS',
  ECONOMICS = 'ECONOMICS_CONFIG_TABS',
}

export enum EFilterPage {
  PREDICT_FILTER = 'PREDICT_FILTER',
  ECONOMICS_FILTER = 'ECONOMICS_FILTER',
}

export enum EConfigTabsDirectory {
  PREDICT = 'Predict',
  ECONOMICS = 'Economics',
  PLAN = 'Plan',
}
