import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import {
  DEFAULT_CANADA_MAP_CENTER,
  DEFAULT_CANADA_MAP_ZOOM,
  DEFAULT_USA_MAP_CENTER,
  DEFAULT_USA_MAP_ZOOM,
} from 'app/components/containers/widgets/MapWidget/constants';
import { initialState } from 'app/components/containers/widgets/MapWidget/slice';
import { selectUserCountries } from 'app/containers/PrivateRoute/selectors';
// import { selectThemeMode } from 'styles/theme/selectors';
import { RootState } from 'types/RootState';
import { UserCountry } from 'types/UserCountry';
import { MapWidgetId } from 'types/Widget';

const selectDomain = (state: RootState) => state?.mapWidgets || initialState;
const selectDomainByWidgetId = createSelector(
  [selectDomain, (state: RootState, mapWidgetId: MapWidgetId) => mapWidgetId],
  (state, mapWidgetId) => state[mapWidgetId],
);

export const selectMapTheme = createSelector([selectDomainByWidgetId], (state) => state.style);

const selectDefaultMapZoom = createSelector([selectUserCountries], (userCountries) =>
  userCountries.includes(UserCountry.UNITED_STATES) ? DEFAULT_USA_MAP_ZOOM : DEFAULT_CANADA_MAP_ZOOM,
);
const selectDefaultMapCenter = createSelector([selectUserCountries], (userCountries) =>
  userCountries.includes(UserCountry.UNITED_STATES) ? DEFAULT_USA_MAP_CENTER : DEFAULT_CANADA_MAP_CENTER,
);
export const selectMapCenter = createSelector(
  [selectDomainByWidgetId, selectDefaultMapCenter],
  (state, defaultMapCenter) => state.center ?? defaultMapCenter,
);
export const selectMapZoom = createSelector(
  [selectDomainByWidgetId, selectDefaultMapZoom],
  (state, defaultMapZoom) => state.zoom ?? defaultMapZoom,
);

export const selectMapFlyToCenter = createSelector([selectDomainByWidgetId], (state) => state.flyToCenter);

export const selectIsRenderLayersEnabled = createSelector(
  [selectDomainByWidgetId],
  (state) => state.renderLayersEnabled,
);

export const selectSelectedUwi = createSelector([selectDomainByWidgetId], (state) => state.selectedUwi);
export const selectIsFirstTimeSelectedWellSetting = createSelector(
  [selectDomainByWidgetId],
  (state) => state.isFirstTimeSelectedWellSetting,
);

export const selectIsSelectedUwiEmpty = createSelector([selectSelectedUwi], (selectedUwi) => !selectedUwi);

export const selectMapBounds = createSelector([selectDomainByWidgetId], (state) => state.bounds);
export const selectIsMapBoundsLoading = createSelector(
  [selectDomainByWidgetId],
  (state) => state.boundsStatus === RequestStatus.LOADING,
);
