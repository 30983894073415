import { CurveType, P_Curve } from 'types/Curve';
import { PredictionModelType } from 'types/PlanPredictModel';

import { CHART_P_CURVES } from '../constants';
import { PROD_CURVE_DATA_KEY } from './types';

export const getCurveTypesByDataKey = (dataKey: PROD_CURVE_DATA_KEY): [CurveType, PredictionModelType, P_Curve] => {
  const [curveType, modelType, pCurve] = dataKey.split('_');

  return [curveType as CurveType, modelType as PredictionModelType, pCurve as P_Curve];
};

export function isSpecialLineStyleNeeded(key: PROD_CURVE_DATA_KEY): boolean {
  const modelType = key.split('_')[1] as PredictionModelType;

  return modelType !== PredictionModelType.ACTUAL;
}

export function isPredictionModelInUse(curveType: CurveType, keys: PROD_CURVE_DATA_KEY[]): boolean {
  const neededSubstring = curveType + '_';
  const actualKey = neededSubstring + 'actual';
  let result = false;
  for (let i = 0; i < keys.length; i++) {
    if (keys[i].includes(neededSubstring) && keys[i] !== actualKey) {
      result = true;
      break;
    }
  }

  return result;
}

export const generateChartKeysByCurveType = (
  curveType: CurveType,
  modelKeyType: PredictionModelType,
  withGeneral = true,
  withErrors = false,
): PROD_CURVE_DATA_KEY[] => {
  return [
    ...(withGeneral ? [`${curveType}_${modelKeyType}` as PROD_CURVE_DATA_KEY] : []),
    ...(withErrors
      ? CHART_P_CURVES.map((pCurve) => `${curveType}_${modelKeyType}_${pCurve}` as PROD_CURVE_DATA_KEY)
      : []),
  ];
};
