import { TestLocators } from 'constants/Locators';

import { Container } from '@material-ui/core';
import { ReactElement } from 'react';

export interface DefaultLayoutProps {
  children: ReactElement;
  testId?: TestLocators;
}

export function DefaultLayout({ children, testId }: DefaultLayoutProps) {
  return (
    <main>
      <Container maxWidth={false} data-testid={testId}>
        {children}
      </Container>
    </main>
  );
}
