import { createSelector } from '@reduxjs/toolkit';

import { TPolygonData } from 'app/components/containers/widgets/MapWidget/types';
import { EFilterPage } from 'types/ConfigTabs';
import { FieldInfo } from 'types/FieldInfo';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { RootState } from 'types/RootState';

import { initialState } from './slice';
import { TCommonConfigTabFilterState } from './types';

export const selectCommonConfigTabFilterDomain = (state: RootState) => state?.commonConfigTabFilter || initialState;

export const selectPredictFieldValue = createSelector(
  [
    selectCommonConfigTabFilterDomain,
    (state: RootState, id: ImpactWellAndProdColumn, type: EFilterPage) => ({ id, type }),
  ],
  (state, { id, type }) => state[type][id] || '',
);

export const selectCommonConfigTabFilterValues = createSelector(
  [selectCommonConfigTabFilterDomain, (state: RootState, type: EFilterPage) => type],
  (state, type) => {
    return Object.entries(state[type])
      .filter((entry) => Boolean(entry[1]))
      .map((entry) => ({ name: entry[0], value: entry[1] } as FieldInfo));
  },
);
export const selectPredictFilterValues = createSelector([selectCommonConfigTabFilterDomain], (state) => {
  return Object.entries(state.PREDICT_FILTER)
    .filter((entry) => Boolean(entry[1]))
    .map((entry) => ({ name: entry[0], value: entry[1] } as FieldInfo));
});
export const selectEconomicsFilterValues = createSelector([selectCommonConfigTabFilterDomain], (state) => {
  return Object.entries(state.ECONOMICS_FILTER)
    .filter((entry) => Boolean(entry[1]))
    .map((entry) => ({ name: entry[0], value: entry[1] } as FieldInfo));
});
export const selectEconomicsMapFilterToggleValue = createSelector([selectCommonConfigTabFilterDomain], (state) => {
  return state.ECONOMICS_FILTER.mapFilterToggle as boolean;
});
export const selectEconomicsGeoPolygons = createSelector([selectCommonConfigTabFilterDomain], (state) => {
  return state.ECONOMICS_FILTER.geoPolygons as TPolygonData[] | null;
});

export const selectChosenFilterValuesWithFilterNameAsKey = createSelector(
  [selectCommonConfigTabFilterValues, (state: RootState, type: EFilterPage) => type],
  (values, type) => {
    const result: Omit<TCommonConfigTabFilterState, 'activeFilter'> = {
      [EFilterPage.PREDICT_FILTER]: {},
      [EFilterPage.ECONOMICS_FILTER]: {},
    };
    values.forEach((item) => {
      result[type][item.name] = item.value;
    });

    return result;
  },
);
