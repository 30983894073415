export enum WellStatus {
  WAITING_ON_PROD_RECORD = 'Waiting on Prod Record',
  PERMIT = 'Permit',
  PERMIT_EXPIRED = 'Permit Expired',
  NOT_PRODUCING = 'Not Producing',
  DUC = 'DUC',
  PRODUCING_WELL = 'Producing Well',
  TREATED = 'Treated',
  SPUD = 'Spud',
}
