import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

import Tick from '../Checkbox/tick.svg';
import Times from './unchecked.svg';

export const useStyles = makeStyles((theme: Theme) => {
  const { primary } = theme.palette;

  return createStyles({
    root: {
      width: 48,
      height: 25,
      padding: 0,
      '&:first-child:after': {
        position: 'absolute',
        top: 7,
        left: 30,
        content: '""',
        color: 'white',
        width: 10,
        height: 10,
        background: `url(${Times}) no-repeat top left`,
      },
    },
    switchBase: {
      padding: 0,
      '&$checked': {
        transform: 'translateX(24px) translateY(1px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: primary.main,
          opacity: 1,
          border: 'none',
        },
        '&:before': {
          position: 'absolute',
          top: 5,
          right: 28,
          content: '""',
          color: 'white',
          width: 13,
          height: 13,
          backgroundImage: `url(${Tick})`,
          backgroundSize: 'contain' /* <------ */,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        },
      },
      transform: 'translateX(1px) translateY(1px)',
      '&:hover $thumb': {
        boxShadow: 'none',
      },
      '&$focusVisible $thumb': {
        color: primary.main,
        border: `6px solid ${colors.textInverseDefault}`,
      },
    },
    thumb: {
      width: 23,
      height: 23,
      boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.25)',
      color: theme.palette.common.white,
      '&:hover': {
        boxShadow: 'none',
      },
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: colors.baseGrey30,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    uncheckedIcon: {},
  });
});
