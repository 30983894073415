import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
// Initialize languages
import 'locales/i18n';
import 'utils/axios.interceptors';

import { CssBaseline } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
// eslint-disable-next-line no-restricted-imports
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { ThemeProvider } from 'impact-react-components';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { router } from 'app';
import { AppErrorBoundary } from 'app/containers/AppErrorBoundary';
import { ErrorHandler } from 'app/containers/ErrorHandler';
import { persistor, store } from 'store';

export const ConnectedApp = () => (
  <AppErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <>
            <CssBaseline />
            <HelmetProvider>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <ErrorHandler>
                  <RouterProvider router={router} />
                </ErrorHandler>
              </LocalizationProvider>
            </HelmetProvider>
          </>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </AppErrorBoundary>
);

const MOUNT_NODE: HTMLElement = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

const render = () => {
  root.render(<ConnectedApp />);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./locales/i18n'], () => {
    root.unmount();
  });
}

render();
