import { TestLocators } from 'constants/Locators';

import { MenuItem, Typography } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CustomHeaderMenu } from 'app/components/common/CustomHeaderMenu';
import { selectIsTrialRole, selectUserCountries } from 'app/containers/PrivateRoute/selectors';
import { UserCountry } from 'types/UserCountry';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ShapefileRegion } from './constants';
import { shapefileListSaga } from './saga';
import { ShapefileModal } from './ShapefileModal';
import { actions, reducer, sliceKey } from './slice';
import { useStyles } from './styles';

export interface DownloadShapefileProps {
  anchorEl: HTMLElement | null;
  onClose(): void;
}

const isDisabledMenuItem = (isTrialRole: boolean, userCountries: UserCountry[], region: ShapefileRegion) => {
  if (isTrialRole) {
    return true;
  }
  switch (region) {
    case ShapefileRegion.US:
      return !userCountries.includes(UserCountry.UNITED_STATES);
    case ShapefileRegion.CANADA:
      return !userCountries.includes(UserCountry.CANADA);
  }
};

const getMenuItemLocator = (isMenuOpen: boolean, IsMenuItemDisabled: boolean) => {
  if (isMenuOpen) {
    return IsMenuItemDisabled ? TestLocators.Shapefile_Visible_Disabled : TestLocators.Shapefile_Visible_Enabled;
  }

  return IsMenuItemDisabled ? TestLocators.Shapefile_Hidden_Disabled : TestLocators.Shapefile_Hidden_Enabled;
};

export function DownloadShapefile({ anchorEl, onClose }: DownloadShapefileProps) {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: shapefileListSaga });
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userCountries = useSelector(selectUserCountries);
  const isTrialRole = useSelector(selectIsTrialRole);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (region: ShapefileRegion) => {
      dispatch(actions.loadShapefileList(region));
      setIsModalOpen(true);
      onClose();
    },
    [dispatch, onClose],
  );

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const menuItems = useMemo(
    () =>
      Object.values(ShapefileRegion).map((region: ShapefileRegion) => {
        const disabled = isDisabledMenuItem(isTrialRole, userCountries, region);

        return (
          <MenuItem key={region} disabled={disabled} className={classes.menuItem} onClick={() => handleClick(region)}>
            <Typography className={classes.menuText} variant="body2" data-testid={getMenuItemLocator(open, disabled)}>
              {t(`DOWNLOAD_SHAPEFILE.ITEMS.${region}`)}
            </Typography>
          </MenuItem>
        );
      }),
    [isTrialRole, userCountries, classes.menuItem, classes.menuText, open, t, handleClick],
  );

  return (
    <>
      <CustomHeaderMenu
        id="shapefiles-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        data-testid={TestLocators.Header_Shapefiles}
        paperTestId={open ? undefined : TestLocators.Shapefiles_Menu_Closed}
        popperPlacement="bottom-start"
      >
        {menuItems}
      </CustomHeaderMenu>
      <ShapefileModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  );
}
