import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { PriceFeedData, SocketDataState } from './types';

export const initialState: SocketDataState = {
  priceFeed: {
    WTI: 0,
    HenryHub: 0,
  },
};

const socketDataSlice = createSlice({
  name: 'socketData',
  initialState,
  reducers: {
    setPriceFeed(state, action: PayloadAction<PriceFeedData>) {
      state.priceFeed = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = socketDataSlice;
