import { PredictionModelsConfigElementType } from 'app/components/containers/layout/ConfigTabs/controls/PredictionModels/types';
import { translations } from 'locales/i18n';
import { PredictionModelType } from 'types/PlanPredictModel';
import { WellStatus } from 'types/Well';

export const PROD_CURVES_DEFAULT_MAX_MONTH_VALUE = 240;

export const CurveModelsAllowedByWellStatus = {
  [WellStatus.PERMIT]: [PredictionModelType.PERMITTED],
  [WellStatus.PERMIT_EXPIRED]: [PredictionModelType.PERMITTED],
  [WellStatus.SPUD]: [PredictionModelType.PERMITTED],
  [WellStatus.DUC]: [PredictionModelType.PERMITTED, PredictionModelType.DRILLED],
  [WellStatus.TREATED]: [PredictionModelType.PERMITTED, PredictionModelType.DRILLED, PredictionModelType.COMPLETION],
  [WellStatus.WAITING_ON_PROD_RECORD]: [
    PredictionModelType.PERMITTED,
    PredictionModelType.DRILLED,
    PredictionModelType.COMPLETION,
  ],
  [WellStatus.PRODUCING_WELL]: [
    PredictionModelType.PERMITTED,
    PredictionModelType.DRILLED,
    PredictionModelType.COMPLETION,
    PredictionModelType.PRODUCING,
  ],
  [WellStatus.NOT_PRODUCING]: [
    PredictionModelType.PERMITTED,
    PredictionModelType.DRILLED,
    PredictionModelType.COMPLETION,
    PredictionModelType.PRODUCING,
  ],
};

const translationPrefix = translations.PREDICTION_MODELS;
export const predictionModels: PredictionModelsConfigElementType[] = [
  {
    label: translationPrefix.ITEMS.PERMITTED,
    generalLabel: translationPrefix.PREDICTION_LABEL,
    id: PredictionModelType.PERMITTED,
    withErrors: true,
  },
  {
    label: translationPrefix.ITEMS.DRILLED,
    generalLabel: translationPrefix.PREDICTION_LABEL,
    id: PredictionModelType.DRILLED,
    withErrors: true,
  },
  {
    label: translationPrefix.ITEMS.COMPLETION,
    generalLabel: translationPrefix.PREDICTION_LABEL,
    id: PredictionModelType.COMPLETION,
    withErrors: true,
  },
  {
    label: translationPrefix.ITEMS.PRODUCING,
    generalLabel: translationPrefix.PREDICTION_LABEL,
    id: PredictionModelType.PRODUCING,
    withErrors: true,
  },
];
