import { PlanPredictCurvesRequestPayload, PlanPredictCurvesResponsePayload } from 'api/curves';

import { CurveType, P_Curve } from 'types/Curve';
import { PlanModelType } from 'types/PlanPredictModel';

import { CHART_P_CURVES, MONTH_NUMBER_DATA_KEY } from '../constants';
import { PLAN_CURVE_CHART_KEY, PLAN_CURVE_DATA_KEY, PlanProdChartItem, PlanProdDataItem } from './types';

export const getPlannedWellDataKey = (payload: PlanPredictCurvesRequestPayload) => JSON.stringify(payload);

export const generateDataKeyByPlanDataItem = (dataItem: PlanPredictCurvesResponsePayload): PLAN_CURVE_DATA_KEY => {
  return dataItem.P_Curve === P_Curve.P50
    ? `${dataItem.Task}_${PlanModelType.PLANNED}`
    : `${dataItem.Task}_${PlanModelType.PLANNED}_${dataItem.P_Curve}`;
};

const getDataItemDataByKey = (dataByType: PlanPredictCurvesResponsePayload, key: string) => ({
  [generateDataKeyByPlanDataItem(dataByType)]: dataByType[key],
});

export const normalizeChartData = (rawData: PlanPredictCurvesResponsePayload[]): PlanProdDataItem[] => {
  const result = rawData.reduce((acc, dataByType) => {
    const chartDataForType = Object.keys(dataByType).reduce((acc, dataByTypeKey) => {
      const monthNumber = Number(dataByTypeKey);

      return !Number.isNaN(monthNumber)
        ? [
            ...acc,
            {
              ...getDataItemDataByKey(dataByType, dataByTypeKey),
              [MONTH_NUMBER_DATA_KEY]: monthNumber,
            },
          ]
        : acc;
    }, [] as Partial<PlanProdDataItem>[]);

    return chartDataForType.map((chartValue, index) => ({
      ...acc[index],
      ...chartValue,
    }));
  }, [] as PlanProdDataItem[]);

  return result.sort((itemA, itemB) => itemA.monthNumber - itemB.monthNumber);
};

export const generatePlanChartItemByDataKey = (wellDataItem: PlanProdDataItem, wellId: number): PlanProdChartItem => {
  return Object.keys(wellDataItem).reduce((acc, wellDataKey) => {
    return wellDataKey === MONTH_NUMBER_DATA_KEY
      ? { ...acc, [MONTH_NUMBER_DATA_KEY]: wellDataItem[MONTH_NUMBER_DATA_KEY] }
      : {
          ...acc,
          [`${wellDataKey}_${wellId}`]: wellDataItem[wellDataKey as PLAN_CURVE_DATA_KEY],
        };
  }, {} as PlanProdChartItem);
};

export const generatePlanChartKeys = (
  curveType: CurveType,
  modelKeyType: PlanModelType,
  wellId: number | null,
  withGeneral = true,
  withErrors = false,
): PLAN_CURVE_CHART_KEY[] => {
  return [
    ...(withGeneral
      ? [`${curveType}_${modelKeyType}${wellId === null ? '' : `_${wellId}`}` as PLAN_CURVE_CHART_KEY]
      : []),
    ...(withErrors
      ? CHART_P_CURVES.map(
          (pCurve) =>
            `${curveType}_${modelKeyType}_${pCurve}${wellId === null ? '' : `_${wellId}`}` as PLAN_CURVE_CHART_KEY,
        )
      : []),
  ];
};

export const getProdTypeModelByDataKey = (dataKey: PLAN_CURVE_CHART_KEY) => {
  return dataKey.split('_')[0] as CurveType;
};

export const getPlanPredictModelByDataKey = (dataKey: PLAN_CURVE_CHART_KEY) => {
  return dataKey.split('_')[1] as PlanModelType;
};

export const getPCurveByDataKey = (dataKey: PLAN_CURVE_CHART_KEY) => {
  const pCurveValue = dataKey.split('_')[2];

  return Number.isNaN(Number(pCurveValue)) ? (pCurveValue as P_Curve) : undefined;
};

export const getWellIdByDataKey = (dataKey: PLAN_CURVE_CHART_KEY) => {
  const pCurveValue = getPCurveByDataKey(dataKey);

  return Number(dataKey.split('_')[pCurveValue ? 3 : 2]);
};

export const getStrokeWidth = (activeWellId: number | null, dataKey: PLAN_CURVE_CHART_KEY) => {
  return activeWellId === getWellIdByDataKey(dataKey) ? 2 : 1;
};

export const getCurveAttributesByDataKey = (dataKey: PLAN_CURVE_CHART_KEY) => {
  let result;
  const [prodType, modelType, pCurve, wellId] = dataKey.split('_');

  if (wellId === undefined) {
    const wellIdValue = Number(pCurve);

    result = Number.isNaN(wellIdValue)
      ? [prodType, modelType, pCurve, undefined]
      : [prodType, modelType, undefined, wellIdValue];
  } else {
    result = [prodType, modelType, pCurve, Number(wellId)];
  }

  return result as [CurveType, PlanModelType, P_Curve | undefined, number | undefined];
};
