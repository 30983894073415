import { Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormatNumberByLocaleOptions, formatByLocale } from 'helpers/formatters';

import { useStyles } from './styles';

export interface PriceProps {
  labelKey: string;
  value: number;
  formatOptions?: FormatNumberByLocaleOptions;
  tooltipText?: string;
}

export const Price = ({ labelKey, value, formatOptions, tooltipText = '' }: PriceProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tooltipOpenProp = tooltipText ? undefined : false;

  return (
    <Tooltip title={tooltipText} arrow open={tooltipOpenProp}>
      <Typography className={classes.price} variant="h6">
        {t(labelKey, {
          value: formatByLocale(value, formatOptions),
        })}
      </Typography>
    </Tooltip>
  );
};
