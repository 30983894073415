import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';
import { PlanPredictCurvesRequestPayload, PlanPredictCurvesWithIdRequestPayload } from 'api/curves';

import { selectCumulativeProductionValue } from 'app/components/containers/layout/ConfigTabs/PlannerControl/ProductionCurvesSettings/selectors';
import {
  selectCurrentWellId,
  selectReadyToPlanWellIds,
  selectVisibleWellsSettings,
  selectWellsSettings,
} from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/selectors';
import { getLatLngWellSurfaceHole } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/utils';
import { isObjectEmpty } from 'helpers/objects';
import { ProductionTypeValue } from 'types/Curve';
import { RootState } from 'types/RootState';

import { getPlanWellParametersInitialState } from './config';
import { NumberPlanWellParameter, StringPlanWellParameter } from './types';

const selectDomain = (state: RootState) => state?.planWellParameters || getPlanWellParametersInitialState();

export const selectIsWellParametersLoading = createSelector(
  [selectDomain],
  (state) => state.status === RequestStatus.LOADING,
);
export const selectIsWellParametersLoaded = createSelector(
  [selectDomain],
  (state) =>
    state.status === RequestStatus.SUCCESS &&
    (state.updateParameterOptionsStatus === RequestStatus.SUCCESS ||
      state.updateParameterOptionsStatus === RequestStatus.IDLE),
);

export const selectIsParameterOptionsUpdating = createSelector(
  [selectDomain],
  (state) => state.updateParameterOptionsStatus === RequestStatus.LOADING,
);

export const selectWellParamPlay = createSelector([selectDomain], (state) => state.currentPlay);

export const selectCurrentWellParameters = createSelector([selectDomain, selectCurrentWellId], (state, currentWellId) =>
  currentWellId !== null ? state.wells[currentWellId] : state.current,
);

export const selectWellParamCurrentValueString = createSelector(
  [selectCurrentWellParameters, (state: RootState, paramName: StringPlanWellParameter) => paramName],
  (currentWellParameters, paramName) => currentWellParameters[paramName],
);

export const selectWellParamCurrentValueNumber = createSelector(
  [selectCurrentWellParameters, (state: RootState, paramName: NumberPlanWellParameter) => paramName],
  (currentWellParameters, paramName) => currentWellParameters[paramName],
);

export const selectCurentPlayDesignation = createSelector(
  [selectCurrentWellParameters],
  (currentWellParameters) => currentWellParameters.playDesignation,
);

export const selectAllWellParamOptions = createSelector([selectDomain], (state) => state.options);

export const selectWellParamOptions = createSelector(
  [selectDomain, (state: RootState, paramName: StringPlanWellParameter) => paramName],
  (state, paramName) => state.options[paramName],
);
export const selectWellParamSliderOptions = createSelector(
  [selectDomain, (state: RootState, paramName: NumberPlanWellParameter) => paramName],
  (state, paramName) => state.options[paramName],
);

export const selectFilterWellsSettings = createSelector([selectDomain], (state) => {
  return state.wells;
});

export const selectAllPlannedWellParams = createSelector(
  [selectDomain, selectReadyToPlanWellIds, selectWellsSettings, selectWellParamPlay, selectCumulativeProductionValue],
  (state, readyToPlanWellIds, mapWellsSettings, wellParamPlay, isCumulativeProduction) => {
    if (wellParamPlay === null || isObjectEmpty(state.wells)) {
      return {};
    }

    return readyToPlanWellIds.reduce<Record<string, PlanPredictCurvesRequestPayload>>((acc, wellId) => {
      const wellSettings = mapWellsSettings.find((wellSettings) => wellSettings.id === wellId);
      if (!wellSettings) {
        return acc;
      }

      if (!state.wells[wellId]) {
        return acc;
      }

      return {
        ...acc,
        [wellId]: {
          type: isCumulativeProduction ? ProductionTypeValue.CUM : ProductionTypeValue.RATE,
          location: wellParamPlay,
          playDesignation: state.wells[wellId].playDesignation,
          reservoir: state.wells[wellId].reservoir,
          lateralLengthFt: state.wells[wellId].lateralLength,
          proppantPerFt: state.wells[wellId].proppantPerFt,
          proppantPerFluidRatio: state.wells[wellId].proppantPerFluidRatio,
          spacing: state.wells[wellId].nearestSameReservoirNeighborSpacing,
          latLonSurfaceHole: getLatLngWellSurfaceHole(wellSettings),
        },
      };
    }, {});
  },
);

export const selectAllVisiblePlannedWellParams = createSelector(
  [selectDomain, selectAllPlannedWellParams, selectVisibleWellsSettings],
  (state, allPlannedWellParams, visibleWellsSettings) =>
    visibleWellsSettings.reduce((acc, { id }) => {
      const well = allPlannedWellParams[id];

      if (well) {
        acc.push({ ...well, wellId: id });
      }

      return acc;
    }, [] as PlanPredictCurvesWithIdRequestPayload[]),
);
