import { TestLocators } from 'constants/Locators';

import { Container } from '@material-ui/core';
import { ReactElement } from 'react';

import { Header } from 'app/components/containers/layout/Header';

import { useStyles } from './styles';

export interface MainLayoutProps {
  children: ReactElement;
  testId?: TestLocators;
}

export function MainLayout({ children, testId }: MainLayoutProps) {
  const classes = useStyles();

  return (
    <>
      <Header />
      <main className={classes.mainContainer}>
        <Container maxWidth={false} data-testid={testId} disableGutters>
          {children}
        </Container>
      </main>
    </>
  );
}
