import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type, text } = theme.palette;
  const boxShadow = {
    [ThemeMode.LIGHT]: '0 2px 3px 1px rgba(0,0,0,0.2)',
    [ThemeMode.DARK]: '0 2px 5px 2px rgba(0,0,0,0.5)',
  };

  return createStyles({
    menuPadding: {
      padding: 0,
    },
    menuPaper: {
      background: type === ThemeMode.DARK ? colors.grey55 : colors.primaryWhite,
      boxShadow: boxShadow[type],
      border: `1px solid ${text.secondary}`,
      color: text.secondary,
      top: '50px!important',
    },
  });
});
