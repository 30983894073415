import { AppRoute } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { DefaultLayout } from 'app/components/common/DefaultLayout';
import { MainLayout } from 'app/components/containers/layout/MainLayout';
import { DocumentationPage } from 'app/components/pages/DocumentationPage/Loadable';
import { EconomicsPage } from 'app/components/pages/EconomicsPage/Loadable';
import { ErrorPage } from 'app/components/pages/ErrorPage';
import { ForbiddenPage } from 'app/components/pages/ForbiddenPage';
import { ImmersePage } from 'app/components/pages/ImmersePage/Loadable';
import { LandingPage } from 'app/components/pages/LandingPage/Loadable';
import { ProductionPredictionPage } from 'app/components/pages/ProductionPredictionPage/Loadable';
import { UnauthorizedPage } from 'app/components/pages/UnauthorizedPage';
import { WellPlannerPage } from 'app/components/pages/WellPlannerPage/Loadable';
import { history } from 'common/history';

import { DatabaseAccessPageProxyPage } from './components/pages/DatabaseAccessPage/Loadable';
import { OktaLoginPage } from './components/pages/OktaLoginPage/Loadable';
import { ERouteType, RouteManager } from './containers/RouteManager/RouteManager';
import { RequiredAuth } from './CustomSecureRoute';

const OKTA_ISSUER = 'https://secure.signinpreview.spglobal.com/oauth2/spglobal';
const OKTA_CLIENT_ID = '0oa22dlrf9ev4hqej0h8';
const OKTA_SCOPES = 'openid email profile offline_access';

const scopes = OKTA_SCOPES as string;
const scopesArray = scopes && scopes.split(/\s+/);

const oktaConfig = {
  issuer: OKTA_ISSUER as string,
  clientId: OKTA_CLIENT_ID as string,
  redirectUri: `${window.location.origin}`,
  scopes: scopesArray || [],
  pkce: true,
  disableHttpsCheck: true,
};

const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri?: string) => {
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

const oktaAuth = new OktaAuth(oktaConfig);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <>
        <Route
          path={AppRoute.ROOT}
          element={
            <RouteManager
              path={AppRoute.ROOT}
              component={LandingPage}
              layout={MainLayout}
              testId={TestLocators.Impact_Landing_Page}
              routeType={ERouteType.PRIVATE}
            />
          }
        />
        <Route
          path={AppRoute.IMMERSE}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.IMMERSE}
              component={ImmersePage}
              layout={DefaultLayout}
            />
          }
        />
        <Route
          path={AppRoute.PRODUCTION_PREDICTION}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.PRODUCTION_PREDICTION}
              component={ProductionPredictionPage}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={AppRoute.WELL_PLANNER}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.WELL_PLANNER}
              component={WellPlannerPage}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={AppRoute.ECONOMICS}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.ECONOMICS}
              component={EconomicsPage}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={AppRoute.DOCUMENTATION}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.DOCUMENTATION}
              component={DocumentationPage}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={`${AppRoute.DOCUMENTATION}/*`}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.DOCUMENTATION}
              component={DocumentationPage}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={AppRoute.DATABASE_ACCESS_PAGE}
          element={
            <RouteManager
              routeType={ERouteType.PRIVATE}
              path={AppRoute.DATABASE_ACCESS_PAGE}
              component={DatabaseAccessPageProxyPage}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={AppRoute.FORBIDDEN}
          element={
            <RouteManager
              routeType={ERouteType.COMMON}
              path={AppRoute.FORBIDDEN}
              component={ForbiddenPage}
              layout={DefaultLayout}
            />
          }
        />
        <Route
          path={AppRoute.UNAUTHORIZED}
          element={
            <RouteManager
              routeType={ERouteType.COMMON}
              path={AppRoute.UNAUTHORIZED}
              component={UnauthorizedPage}
              layout={DefaultLayout}
            />
          }
        />
        <Route
          path={AppRoute.ERROR}
          element={
            <RouteManager
              routeType={ERouteType.COMMON}
              path={AppRoute.ERROR}
              component={ErrorPage}
              layout={DefaultLayout}
            />
          }
        />

        <Route
          path={AppRoute.OKTA_LOGIN}
          element={
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <RequiredAuth />
            </Security>
          }
        />

        <Route path={AppRoute.OKTA_LOGIN_PAGE} element={<OktaLoginPage />} />

        <Route path="*" element={<Navigate to={AppRoute.ROOT} replace={true} />} />
      </>
    </Route>,
  ),
);
