import { TestLocators } from 'constants/Locators';

import { MenuItem, Typography } from '@material-ui/core';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { formatTestLocator } from 'helpers/formatters';
import { translations } from 'locales/i18n';

import { useStyles } from './styles';

export interface ContactProps {
  index: number;
}

export function Contact({ index }: ContactProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { REGION, PHONES, OFFICE_HOURS } = translations.HELP.CONTACTS[index];
  const testId = formatTestLocator(`${TestLocators.Header_Help_MenuItem}_Contact`, t(REGION));

  return (
    <MenuItem className={classes.contactItem}>
      <Typography className={classes.region} variant="h4">
        {t(REGION)}
      </Typography>
      <Typography
        className={cn(classes.info, classes.link)}
        component="a"
        href={`tel:${t(PHONES)}`}
        variant="body2"
        data-testid={testId}
      >
        {t(PHONES)}
      </Typography>
      <Typography className={classes.info} variant="body2">
        {t(OFFICE_HOURS)}
      </Typography>
    </MenuItem>
  );
}
