import { EqualityComparatorCreator, createCustomEqual } from 'fast-equals';

const isDeepEqualIgnoreOrderComparator: EqualityComparatorCreator<undefined> = (deepEqual) => (objectA, objectB) =>
  Array.isArray(objectA) && Array.isArray(objectB)
    ? deepEqual(objectA.slice().sort(), objectB.slice().sort())
    : deepEqual(objectA, objectB);

export const isDeepEqualIgnoreOrder = createCustomEqual(() => ({
  createIsNestedEqual: isDeepEqualIgnoreOrderComparator,
}));

export function cloneThroughJson<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export function isObjectEmpty(obj: Object) {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

export const removeKeyFromObject = <T extends object>(obj: Record<any, any>, removeKey: string): T => {
  return Object.keys(obj).reduce<Record<any, any>>((acc, key) => {
    if (key !== removeKey) {
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        acc[key] = removeKeyFromObject(obj[key], removeKey);
      } else {
        acc[key] = obj[key];
      }
    }

    return acc;
  }, {});
};
