import { MapLayer } from './types';

export const POINT_SIZE_MIN = 1;
export const POINT_SIZE_DEFAULT = 3;
export const POINT_SIZE_MAX = 30;
export const LINE_SIZE_DEFAULT = 2;
export const COLOR_BY_VALUE_LIMIT = 50;
export const WELL_PATH_MIN_MAP_ZOOM = 10;
export const MIDSTREAM_MIN_MAP_ZOOM = 8;
export const DEFAULT_ACREAGE_LAYER_OPACITY = 60;

export const VEGA_LAYER_IDS = [
  MapLayer.ISOPACH,
  MapLayer.WELLS,
  MapLayer.WELL_PATH,
  MapLayer.ACREAGE,
  MapLayer.MIDSTREAM,
  MapLayer.THERMAL_MATURITY,
  MapLayer.SUBSURFACE_STRUCTURE,
  MapLayer.SUBSURFACE_FAULTS,
  MapLayer.COMPANY_ACREAGE,
];

export const layersColoredByColorPalette = [
  MapLayer.SUBSURFACE_STRUCTURE,
  MapLayer.SUBSURFACE_FAULTS,
  MapLayer.ISOPACH,
];
