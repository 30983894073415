import { useTabContext } from '@material-ui/lab/TabContext';
import cn from 'clsx';
import { ReactNode } from 'react';

import { useStyles } from './styles';

export interface TabPanelProps {
  children: ReactNode;
  value: string;
}

export const TabPanel = ({ children, value: id }: TabPanelProps) => {
  const context = useTabContext();
  const classes = useStyles();

  if (context === null) {
    throw new TypeError('No Tab Context provided');
  }

  const tabId = context.value;

  return <div className={cn(classes.tabPanel, { [classes.tabPanelActive]: id === tabId })}>{children}</div>;
};
