import { TestLocators } from 'constants/Locators';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { useStyles } from './styles';

export interface AlertDialogWithAnyActionsProps {
  isOpen: boolean;
  title: string;
  messages: string[] | null;
  onClose(): void;
  testId?: TestLocators;
  dialogTestId?: TestLocators;
  buttonsContent: JSX.Element;
  optionalBodyContent?: string[];
}
export function AlertDialogWithAnyActions({
  isOpen,
  onClose,
  title,
  messages,
  testId,
  buttonsContent,
  optionalBodyContent,
  dialogTestId,
}: AlertDialogWithAnyActionsProps) {
  const classes = useStyles();

  return (
    <Dialog
      closeAfterTransition
      classes={{ paper: classes.dialogPaper }}
      onClose={onClose}
      open={isOpen}
      data-testid={dialogTestId}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <Typography className={classes.title} variant="h1" component="h2" data-testid={`${dialogTestId}_title`}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={!optionalBodyContent} className={classes.dialogContent}>
        <WarningIcon className={classes.icon} />
        <Typography component="div" data-testid={testId}>
          {messages?.map((message, i) => (
            <Typography component="p" className={classes.message} data-testid={`${dialogTestId}_message_${i + 1}`}>
              {message}
            </Typography>
          ))}
        </Typography>
      </DialogContent>
      {optionalBodyContent && (
        <DialogContent dividers className={classes.dialogContent}>
          <Typography component="div" className={classes.bodyContent} data-testid={testId}>
            {optionalBodyContent.reduce((prev, curr) => `${prev} ${curr},`)}
          </Typography>
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>{buttonsContent}</DialogActions>
    </Dialog>
  );
}
