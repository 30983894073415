import MuiSwitch, { SwitchProps } from '@material-ui/core/Switch';

import { useStyles } from './styles';

export function Switch(props: SwitchProps) {
  const classes = useStyles();

  return (
    <MuiSwitch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}
