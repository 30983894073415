import { formatDate } from 'helpers/formatters';
import { ProductUpdate } from 'types/ProductUpdate';

import { ParsedProductUpdate } from './types';

export const getNotificationsList = (notifications: ProductUpdate[]): ParsedProductUpdate[] =>
  notifications.map(({ messageId, htmlbody, releasedTime, isRead, productupdatetype }) => ({
    messageId,
    description: htmlbody,
    releaseDate: formatDate(releasedTime),
    isRead,
    productupdatetype,
  }));
