import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import { ConvertedToObjectType } from './types';

const defaultLng = 'en';

const translationsJson = {
  en: {
    translation: en,
  },
};

export type TranslationResource = typeof en;
export type LanguageKey = keyof TranslationResource;

export const translations: ConvertedToObjectType<TranslationResource> = {} as any;

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still have the intellisense support
 * along with type-safety
 */
const convertLanguageJsonToObject = (obj: Record<string, {}>, dict: Record<string, {}>, current?: string) => {
  Object.keys(obj).forEach((key) => {
    const currentLookupKey = current ? `${current}.${key}` : key;
    if (typeof obj[key] === 'object') {
      dict[key] = {};
      convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
    } else {
      dict[key] = currentLookupKey;
    }
  });
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: defaultLng,
      resources: translationsJson,
      fallbackLng: defaultLng,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      returnNull: false,
    },
    () => {
      convertLanguageJsonToObject(en, translations);
    },
  );
