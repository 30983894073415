export const downloadFileByUrl = (fileURL: string, filename?: string) => {
  const link = document.createElement('a');
  link.href = fileURL;
  link.setAttribute('download', filename ? filename : fileURL);
  link.setAttribute('target', '_blank');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function downloadClientGeneratedFile(filename: string, fileContent: string, type = 'text/plain') {
  downloadFileByUrl(`data:${type};charset=utf-8,` + encodeURIComponent(fileContent), filename);
}

export function downloadClientGeneratedCsv(filename: string, fileContent: string) {
  downloadClientGeneratedFile(filename, fileContent, 'text/csv');
}
