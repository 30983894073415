export enum ProductUpdateType {
  Notification = 'x Notification',
  DataUpdate = 'xData Update',
  ProductUpdate = 'xProduct Update',
}

export const ProductUpdateTypeGA = {
  [ProductUpdateType.Notification]: 'All Notifications',
  [ProductUpdateType.DataUpdate]: 'Data update',
  [ProductUpdateType.ProductUpdate]: 'Platform Updates',
};

export interface ProductUpdate {
  messageId: string;
  htmlbody: string;
  releasedTime: string;
  isRead: boolean;
  productupdatetype: ProductUpdateType;
}
