import { api } from 'constants/API';
import { AppRoute, ForceRedirectionUrl } from 'constants/AppRoute';
import { correlationHeaderName } from 'constants/Header';

import axios, { AxiosResponse } from 'axios';

import { history } from 'common/history';

import { getCallingUrl } from './routes';

export const getCurrentCorrelationId = () => window.sessionStorage.getItem(correlationHeaderName);

const saveCorrelationId = (headers: any) => {
  const correlationHeaderValue = headers[correlationHeaderName];
  const correlationId = getCurrentCorrelationId();

  if (correlationHeaderValue && correlationHeaderValue !== correlationId) {
    window.sessionStorage.setItem(correlationHeaderName, correlationHeaderValue);
  }
};

const handleUnauthorizedResponses = (response: AxiosResponse) => {
  if (response.status === 401 && response.config.url !== api.getUserInfo) {
    window.sessionStorage.setItem(ForceRedirectionUrl, getCallingUrl());
    history.push(AppRoute.UNAUTHORIZED);
  }
};

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  const correlationId = window.sessionStorage.getItem(correlationHeaderName);

  if (config.headers) {
    if (correlationId !== null) {
      config.headers[correlationHeaderName] = correlationId;
    }

    config.headers['Cache-Control'] = 'no-cache';
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response.headers) {
      saveCorrelationId(response.headers);
    }

    return response;
  },
  function (error) {
    if (error.response && error.response.headers) {
      saveCorrelationId(error.response.headers);
      handleUnauthorizedResponses(error.response);
    }

    return Promise.reject(error);
  },
);
