import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type, text } = theme.palette;
  const { fontWeightRegular } = theme.typography;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    menuItem: {
      minWidth: 170,
      height: 36,
    },
    menuText: {
      fontWeight: fontWeightRegular as number,
      textTransform: 'uppercase',
      color: text.primary,
      '&:hover': {
        color: isDarkTheme ? colors.baseGrey10 : colors.primary,
      },
    },
  });
});
