import { ColorByOptionsByLayerId } from 'app/components/containers/map/MapLayersSelect/config';
import { MapLayer } from 'app/components/containers/map/MapLayersSelect/types';
import { AcreageAndInventoryColumn } from 'types/AcreageAndInventoryColumn';
import { ColumnType } from 'types/ColumnType';
import { CompanyAcreageColumn } from 'types/CompanyAcreageColumn';
import { FaultsColumn } from 'types/FaultsColumns';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { IsopachColumn } from 'types/IsopachColumn';
import { MidstreamPipelinesColumn } from 'types/MidstreamPipelinesColumn';
import { PostgreDataType } from 'types/PostgreDataType';
import { StructureColumn } from 'types/StructureColumn';
import { TableName } from 'types/TableName';
import { WellPathColumn } from 'types/WellPathColumn';

export function getPostgreColumnTypeByColumnName(
  columnType: ColumnType,
  layerId: MapLayer,
): PostgreDataType | undefined {
  const result = ColorByOptionsByLayerId[layerId];
  switch (layerId) {
    case MapLayer.ACREAGE:
      return (result as Partial<Record<AcreageAndInventoryColumn, PostgreDataType>>)[
        columnType as AcreageAndInventoryColumn
      ];
    case MapLayer.MIDSTREAM:
      return (result as Partial<Record<MidstreamPipelinesColumn, PostgreDataType>>)[
        columnType as MidstreamPipelinesColumn
      ];
    case MapLayer.COMPANY_ACREAGE:
      return (result as Partial<Record<CompanyAcreageColumn, PostgreDataType>>)[columnType as CompanyAcreageColumn];
    case MapLayer.ISOPACH:
      return (result as Partial<Record<IsopachColumn, PostgreDataType>>)[columnType as IsopachColumn];
    case MapLayer.SUBSURFACE_STRUCTURE:
      return (result as Partial<Record<StructureColumn, PostgreDataType>>)[columnType as StructureColumn];
    case MapLayer.SUBSURFACE_FAULTS:
      return (result as Partial<Record<FaultsColumn, PostgreDataType>>)[columnType as FaultsColumn];
    case MapLayer.WELL_PATH:
      return (result as Partial<Record<WellPathColumn, PostgreDataType>>)[columnType as WellPathColumn];
    default:
      return (result as Partial<Record<ImpactWellAndProdColumn, PostgreDataType>>)[
        columnType as ImpactWellAndProdColumn
      ];
  }
}

export function getTableNameByLayerId(layerId: MapLayer) {
  switch (layerId) {
    case MapLayer.ACREAGE:
      return TableName.ACREAGE_AND_INVENTORY;
    case MapLayer.MIDSTREAM:
      return TableName.MIDSTREAM_PIPELINES;
    case MapLayer.ISOPACH:
      return TableName.SUBSURFACE_ISOPACH;
    case MapLayer.THERMAL_MATURITY:
      return TableName.SUBSURFACE_THERMAL_MATURITY;
    case MapLayer.SUBSURFACE_STRUCTURE:
      return TableName.SUBSURFACE_STRUCTURE;
    case MapLayer.SUBSURFACE_FAULTS:
      return TableName.SUBSURFACE_FAULTS;
    case MapLayer.COMPANY_ACREAGE:
      return TableName.COMPANY_ACREAGE;
    case MapLayer.WELL_PATH:
      return TableName.WELL_PATH;
    default:
      return TableName.IMPACT_WELL_AND_PROD;
  }
}
