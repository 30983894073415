import { createStyles, makeStyles } from '@material-ui/core/styles';

const indent = '16px';

export const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      padding: `0 ${indent}`,
      height: 36,
      '&:hover': {
        backgroundColor: 'inherit',
        cursor: 'default',
      },
    },
  }),
);
