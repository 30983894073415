import { PayloadAction } from '@reduxjs/toolkit';

import {
  AxisIntervalActionPayloadType,
  AxisIntervalBorderNameType,
  AxisNameType,
} from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { CurveSelectionActionPayloadType } from 'app/components/containers/layout/ConfigTabs/controls/CurveSelection/types';
import { PredictionModelsActionPayloadType } from 'app/components/containers/layout/ConfigTabs/controls/PredictionModels/types';
import { PlanProdCurvesSettingsState } from 'app/components/containers/layout/ConfigTabs/PlannerControl/ProductionCurvesSettings/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { getInitialState } from './config';

export const initialState = getInitialState();

const planProdCurvesSettingsSlice = createSlice({
  name: 'planProdCurvesSettings',
  initialState,
  reducers: {
    setPlanProdCurvesSettings(state, { payload }: PayloadAction<PlanProdCurvesSettingsState>) {
      return {
        ...state,
        ...payload,
      };
    },
    changeCurveSelectionItemValue(state, { payload }: PayloadAction<CurveSelectionActionPayloadType>) {
      state.curveSelection[payload.name] = payload.value;
    },
    changeCumulativeProductionValue(state, { payload: enabled }: PayloadAction<boolean>) {
      state.cumulativeProduction = enabled;
    },
    changeAggregateProductionValue(state, { payload: enabled }: PayloadAction<boolean>) {
      state.aggregateProduction = enabled;
    },
    changePredictionModelsItemGeneralValue(state, { payload }: PayloadAction<PredictionModelsActionPayloadType>) {
      state.predictionModels[payload.name]['general'] = payload.value;
    },
    changePredictionModelsItemErrorsValue(state, { payload }: PayloadAction<PredictionModelsActionPayloadType>) {
      state.predictionModels[payload.name]['errors'] = payload.value;
    },
    changeAxisIntervalValue(state, { payload }: PayloadAction<AxisIntervalActionPayloadType>) {
      state.axis[payload.axisName][payload.borderName] = payload.value;
    },
    resetAxisIntervalValues(state, { payload }: PayloadAction<{ axisName: AxisNameType }>) {
      state.axis[payload.axisName][AxisIntervalBorderNameType.MIN] = null;
      state.axis[payload.axisName][AxisIntervalBorderNameType.MAX] = null;
    },
    resetAllAxisIntervalsValues(state) {
      state.axis[AxisNameType.X][AxisIntervalBorderNameType.MIN] = null;
      state.axis[AxisNameType.X][AxisIntervalBorderNameType.MAX] = null;
      state.axis[AxisNameType.Y][AxisIntervalBorderNameType.MIN] = null;
      state.axis[AxisNameType.Y][AxisIntervalBorderNameType.MAX] = null;
    },
    changeLabelsValue(state, { payload: enabled }: PayloadAction<boolean>) {
      state.labels = enabled;
    },
    changeGridlines(state, { payload: enabled }: PayloadAction<boolean>) {
      state.gridlines = enabled;
    },
    changeTimeSelection(state, { payload: enabled }: PayloadAction<boolean>) {
      state.timeSelection = enabled;
    },
  },
});

export const { actions, reducer, name: sliceKey } = planProdCurvesSettingsSlice;
