import { createSelector } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { LegendColorBy, LegendId } from 'app/components/containers/widgets/MapWidget/MapLegend/types';
import { getWellSettingsById } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/hooks/useMapWellsSettings/utils';
import { RootState } from 'types/RootState';

import { getPlannerMapWellsSettingsInitialState } from './slice';

const selectPlannerMapWellsSettingsDomain = (state: RootState) =>
  state?.plannerMapWellsSettings || getPlannerMapWellsSettingsInitialState();

export const selectWellsCount = createSelector([selectPlannerMapWellsSettingsDomain], (state) => state.settings.length);
export const selectWellsSettings = createSelector([selectPlannerMapWellsSettingsDomain], (state) => state.settings);
export const selectVisibleWellsSettings = createSelector([selectPlannerMapWellsSettingsDomain], (state) =>
  state.settings.filter(({ hidden }) => !hidden),
);

export const selectWellNames = createSelector([selectWellsSettings], (wellSettings) => {
  return wellSettings.map(({ name }) => name);
});

export const selectCurrentWellId = createSelector(
  [selectPlannerMapWellsSettingsDomain],
  (state) => state.currentWellId,
);
export const selectRemovedWellId = createSelector(
  [selectPlannerMapWellsSettingsDomain],
  (state) => state.removedWellId,
);

export const selectWellSettingsById = createSelector(
  [selectWellsSettings, (_state: RootState, wellId: number) => wellId],
  (wellsSettings, wellId) => wellsSettings.find((wellSettings) => wellSettings.id === wellId),
);

export const selectSearchWells = createSelector(
  [selectWellsSettings, (_state: RootState, searchTerm: string) => searchTerm],
  (wellsSettings, searchTerm) =>
    searchTerm
      ? wellsSettings.filter((wellSettings) => wellSettings.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : wellsSettings,
);

export const selectWellNameById = createSelector([selectWellSettingsById], (wellSettings) => wellSettings?.name);

export const selectMovingWellId = createSelector([selectPlannerMapWellsSettingsDomain], (state) => state.movingWellId);
export const selectIsMovingWell = createSelector([selectMovingWellId], (wellId) => wellId !== null);
export const selectNextWellId = createSelector([selectPlannerMapWellsSettingsDomain], (state) => state.nextWellId);
export const selectCurrentWellSettings = createSelector(
  [selectPlannerMapWellsSettingsDomain, selectCurrentWellId],
  (state, currentWellId) => (currentWellId !== null ? getWellSettingsById(state.settings, currentWellId) : null),
);

export const selectCurrentWellSurfacePoint = createSelector([selectCurrentWellSettings], (currentWellSettings) => {
  return currentWellSettings?.activePointType === 'startPoint' && currentWellSettings?.tempLengthLimitPoint
    ? currentWellSettings?.tempLengthLimitPoint
    : currentWellSettings?.startPoint;
});

export const selectHoveredWellId = createSelector(
  [selectPlannerMapWellsSettingsDomain],
  (state) => state.hoveredWellId,
);

export const selectHoveredWellSettings = createSelector(
  [selectHoveredWellId, selectWellsSettings],
  (hoveredWellId, wells) => (hoveredWellId !== null ? getWellSettingsById(wells, hoveredWellId) : null),
);
export const selectUsedColorsIds = createSelector(
  [selectPlannerMapWellsSettingsDomain],
  (state) => state.usedColorsIds,
);

export const selectReadyToPlanWellIds = createSelector([selectWellsSettings], (wellSettings) => {
  return wellSettings.reduce<number[]>((acc, wellSetting) => {
    return wellSetting.startPoint && wellSetting.endPoint ? acc.concat(wellSetting.id) : acc;
  }, []);
});

export const selectIsWellReadyToPlan = createSelector(
  [selectReadyToPlanWellIds, (state: RootState, wellId: number) => wellId],
  (readyToPlanWellIds, wellId) => readyToPlanWellIds.includes(wellId),
);

export const selectVisibleWellIds = createSelector([selectVisibleWellsSettings], (visibleWellSettings) => {
  return visibleWellSettings.map(({ id }) => id);
});
export const selectHiddenWellIds = createSelector([selectWellsSettings], (wellSettings) => {
  return wellSettings.reduce<number[]>((acc, { id, hidden }) => (hidden ? acc.concat(id) : acc), []);
});

export const selectIsAllWellsAreReadyToPlan = createSelector(
  [selectReadyToPlanWellIds, selectWellsCount],
  (readyToPlanWellIds, allWellCount) => readyToPlanWellIds.length === allWellCount,
);

export const selectWellsLegendSettings = createSelector(
  [selectVisibleWellsSettings],
  (wellsSettings): { id: LegendId; colorByValue: LegendColorBy } | null => {
    const domain = wellsSettings.map((item) => item.name);
    const range = wellsSettings.map((item) => item.color);

    return domain.length && range.length
      ? {
          id: LegendId.PLANNED_WELLS,
          colorByValue: {
            domain,
            range,
            field: i18next.t('WIDGET.MAP.LEGEND.PLANNED_WELL_NAME'),
          },
        }
      : null;
  },
);
