import { DatesIntervalType, isDatesIntervalType } from 'types/DatesIntervalType';
import { FieldInfo } from 'types/FieldInfo';
import { GeoProvince } from 'types/GeoProvince';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { NumberIntervalType, isNumberIntervalType } from 'types/NumberIntervalType';

const onlyEEUUData = `AND Country in ('United States of America', 'UNITED STATES OF AMERICA', 'United States', 'UNITED STATES', 'USA')`;
// TODO: should we escape values with sqlstring like in nodejs part???
export const createSqlWhereConditionWithoutEscape = <TColumn>(
  filterValues: (FieldInfo<TColumn> | { name: TColumn; value: string[] })[],
  startWith?: 'WHERE' | 'AND',
) => {
  const sqlCondition = filterValues?.length
    ? filterValues
        .reduce(
          (acc, filterValue) =>
            `${acc} ${getEqualityPart<TColumn>(filterValue.name, filterValue.value, acc.trim() !== startWith)}`,
          startWith ? `${startWith} ` : '',
        )
        .trim()
    : '';

  return sqlCondition === startWith ? '' : sqlCondition;
};

const getEqualityPart = <TColumn>(
  name: TColumn,
  value: string | string[] | DatesIntervalType | NumberIntervalType,
  withAdd: boolean,
) => {
  const equality =
    isDatesIntervalType(value) || isNumberIntervalType(value)
      ? getIntervalEquality(name, value)
      : Array.isArray(value)
      ? value.length
        ? `(${value
            .map((orValue) => getConditionWithFilterByCountry(name as unknown as string, orValue))
            .join(' OR ')})`
        : ''
      : getConditionWithFilterByCountry(name as unknown as string, value);

  return equality && withAdd ? `AND ${equality}` : equality;
};

const getConditionWithFilterByCountry = (name: string, geo_province: string): string => {
  if (name === ImpactWellAndProdColumn.GEO_PROVINCE && geo_province === GeoProvince.WILLISTON_BASIN) {
    return `(${escapeId(name)} = ${escapeValue(geo_province)} ${onlyEEUUData})`;
  }

  return `${escapeId(name)} = ${escapeValue(geo_province)}`;
};

const getIntervalEquality = <TColumn>(name: TColumn, value: DatesIntervalType | NumberIntervalType) => {
  let startPart = '';
  let endPart = '';
  if (isDatesIntervalType(value)) {
    startPart = value.start ? `(${escapeId(name)} >= ${escapeValue(value.start)})` : '';
    endPart = value.end ? `(${escapeId(name)} <= ${escapeValue(value.end)})` : '';
  } else if (isNumberIntervalType(value)) {
    startPart = value.from ? `(${escapeId(name)} >= ${value.from})` : '';
    endPart = value.to ? `(${escapeId(name)} <= ${value.to})` : '';
  }

  return startPart && endPart ? `(${startPart} AND ${endPart})` : startPart ? startPart : endPart;
};

const escapeId = <TColumn>(name: TColumn) => {
  return name;
};

const escapeValue = (value: string) => {
  return `'${value}' `;
};
