import {
  AxisIntervalBorderNameType,
  AxisNameType,
} from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { PredictionModelsValue } from 'app/components/containers/layout/ConfigTabs/controls/PredictionModels/types';
import { CurveType } from 'types/Curve';
import { PlanModelType } from 'types/PlanPredictModel';

import { PlanProdCurvesSettingsState } from './types';

const AVAILABLE_PREDICTION_MODELS = [PlanModelType.PLANNED];

export function getInitialState(): PlanProdCurvesSettingsState {
  return {
    curveSelection: {
      [CurveType.LIQUID]: true,
      [CurveType.GAS]: true,
      [CurveType.WATER]: true,
    },
    cumulativeProduction: true,
    predictionModels: AVAILABLE_PREDICTION_MODELS.reduce(
      (acc, model) => ({
        ...acc,
        [model]: {
          general: true,
          errors: false,
        },
      }),
      {} as PredictionModelsValue,
    ),
    axis: {
      [AxisNameType.X]: {
        [AxisIntervalBorderNameType.MIN]: null,
        [AxisIntervalBorderNameType.MAX]: null,
      },
      [AxisNameType.Y]: {
        [AxisIntervalBorderNameType.MIN]: null,
        [AxisIntervalBorderNameType.MAX]: null,
      },
    },
    labels: true,
    gridlines: true,
    timeSelection: true,
    aggregateProduction: false,
  };
}
