import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

const DIALOG_PAPER_WIDTH = 750;
const DIALOG_PAPER_HEIGHT = '90vh';
const TABS_HEIGHT = 48;
const CLOSE_BUTTON_WIDTH = 64;

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    dialogPaper: {
      width: DIALOG_PAPER_WIDTH,
      height: DIALOG_PAPER_HEIGHT,
      maxWidth: 'inherit',
      background: isDarkTheme ? colors.grey56 : colors.primaryWhite,
    },
    dialogContent: {
      padding: '0 0 0 24px',

      '&:first-child': {
        paddingTop: 0,
      },
    },
    dialogActions: {
      padding: 12,
    },
    tabsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabsList: {
      '& .MuiTabs-indicator': {
        backgroundColor: colors.primary,
      },
    },
    notificationsList: {
      height: `calc(${DIALOG_PAPER_HEIGHT} - ${TABS_HEIGHT}px)`,
      paddingTop: '24px',
      overflowY: 'auto',
      paddingRight: '14px',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: TABS_HEIGHT,
      width: CLOSE_BUTTON_WIDTH,
    },
    markAll: {
      cursor: 'pointer',
      fontSize: 14,
      color: isDarkTheme ? colors.primaryWhite : colors.primaryBlue,
      textDecoration: 'none',
      fontWeight: 400,
      marginRight: `calc(${CLOSE_BUTTON_WIDTH}px + 24px)`,
    },
  });
});
