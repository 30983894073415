import { api } from 'constants/API';

import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getErrorMessage } from 'utils/error';

import { actions } from './slice';

function* getShapeFileList(action: PayloadAction<string>) {
  const request = () => axios.get<string[]>(`${api.getShapeFilesByCountry}/${action.payload}`);

  try {
    const result: AxiosResponse<string[]> = yield call(request);

    yield put(actions.shapefileListLoaded(result.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(actions.shapefileListError(errorMessage));
  }
}

export function* shapefileListSaga() {
  yield takeLatest(actions.loadShapefileList.type, getShapeFileList);
}
