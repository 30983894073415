export enum AxisNameType {
  X = 'x',
  Y = 'y',
}
export enum AxisIntervalBorderNameType {
  MIN = 'min',
  MAX = 'max',
}

export type AxisIntervalType = Record<AxisIntervalBorderNameType, number | null>;
export type AxisFormikIntervalType = Record<AxisIntervalBorderNameType, string>;
export type AxisIntervalsType = {
  [AxisNameType.X]: AxisIntervalType;
  [AxisNameType.Y]: AxisIntervalType;
};

export type AxisIntervalValidationResultType = Partial<Record<AxisIntervalBorderNameType, undefined | string>>;
export type ValidationInputType = Record<AxisIntervalBorderNameType, string>;
export type ValidateIntervalValuesCallbackType = (values: ValidationInputType) => AxisIntervalValidationResultType;

export type AxisIntervalActionPayloadType = {
  axisName: AxisNameType;
  borderName: AxisIntervalBorderNameType;
  value: null | number;
};
