import { RequestStatus } from 'constants/RequestStatus';

import { ProductUpdateType } from 'types/ProductUpdate';

export interface NotificationsState {
  status: RequestStatus;
  error: string | null;
  notifications: ParsedProductUpdate[];
  productUpdateType: ProductUpdateType | null;
  trackNotificationsStatus: RequestStatus;
  trackNotificationsStatusError: string | null;
  activeProductUpdateInWindow: ProductUpdateType;
  scrollProductUpdateState: ProductUpdateState | null;
}

export interface ProductUpdateState {
  date: string;
  messageId: string;
}

export interface ParsedProductUpdate {
  messageId: string;
  description: string;
  releaseDate: string;
  isRead: boolean;
  productupdatetype: ProductUpdateType;
}

export enum NotificationLinkType {
  DataUpdates = 'Data Updates',
  PlatformUpdates = 'Platform Updates',
}

export const DATA_UPDATES_VALUE_ATTRIBUTE = 'data-updates-value';
