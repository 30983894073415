import { isSomeEnum } from 'utils/types/enums-check';

export enum StructureColumn {
  DEPTH_FT = 'Depth_Ft',
  BASIN = 'Basin',
  COUNTRY = 'Country',
  FORMATION = 'Formation',
  LAYER_TYPE = 'Layer_Type',
  MAX_DEPTH_FT = 'Max_Depth_Ft',
  MIN_DEPTH_FT = 'Min_Depth_Ft',
  PLAY = 'Play',
  RESERVOIR = 'Reservoir',
  WKT = 'WKT',
}

export const isStructureColumn = isSomeEnum(StructureColumn);
