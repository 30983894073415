import { CURVE_COLOR } from 'constants/Curves';
import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { PROD_CURVES_DEFAULT_MAX_MONTH_VALUE } from 'app/components/containers/layout/ConfigTabs/PredictControl/ProductionCurvesSettings/constants';
import {
  selectActualProductionValue,
  selectAutoDeclineValues,
  selectCurveSelectionItemValue,
  selectCurveSelectionValues,
  selectPredictionModelsValues,
  selectXAxisMaxValue,
  selectXAxisMinValue,
  selectYAxisMinValue,
} from 'app/components/containers/layout/ConfigTabs/PredictControl/ProductionCurvesSettings/selectors';
import {
  getChartLineColorByProdKey,
  getChartXAxisMaxValue,
  getChartYAxisMaxValue,
  getVisibleChartData,
} from 'app/components/containers/widgets/ProductionCurves/utils';
import {
  getExportingProdCurvesData,
  isExportingProdCurvesDataEmpty,
} from 'app/components/pages/ProductionPredictionPage/PredictActionsPanel/WellInfoExportButton/converters';
import { CurveType } from 'types/Curve';
import { CurveColumnValue, PlanPredictModel, PredictionModelType } from 'types/PlanPredictModel';
import { RootState } from 'types/RootState';

import { initialState } from './slice';
import { PROD_CURVE_DATA_KEY } from './types';
import { generateChartKeysByCurveType, isPredictionModelInUse, isSpecialLineStyleNeeded } from './utils';

const selectPredictProdCurvesWidgetDomain = (state: RootState) => state?.predictProdCurvesWidget || initialState;

export const selectProdCurvesData = createSelector([selectPredictProdCurvesWidgetDomain], ({ data }) => data);
export const selectRawProdCurvesData = createSelector([selectPredictProdCurvesWidgetDomain], ({ rawData }) => rawData);
export const selectIsProdCurvesLoading = createSelector(
  [selectPredictProdCurvesWidgetDomain],
  ({ status }) => status === RequestStatus.LOADING,
);
export const selectPredictProdCurvesError = createSelector([selectPredictProdCurvesWidgetDomain], ({ error }) => error);

export const selectIsPredictProdCurvesHasForecast = createSelector([selectRawProdCurvesData], (rawData) => {
  return !!rawData?.some(
    (item) =>
      item.Curve === CurveColumnValue.FORECASTED &&
      Object.keys(item).some((itemKey) => item[itemKey] !== null && !Number.isNaN(Number(itemKey))),
  );
});

const selectIsActualLiquidEnabled = createSelector(
  [selectActualProductionValue, (state) => selectCurveSelectionItemValue(state, CurveType.LIQUID)],
  (actualProductionEnabled, liquidCurveEnabled) => actualProductionEnabled && liquidCurveEnabled,
);
const selectIsActualGasEnabled = createSelector(
  [selectActualProductionValue, (state) => selectCurveSelectionItemValue(state, CurveType.GAS)],
  (actualProductionEnabled, gasCurveEnabled) => actualProductionEnabled && gasCurveEnabled,
);
const selectIsActualWaterEnabled = createSelector(
  [selectActualProductionValue, (state) => selectCurveSelectionItemValue(state, CurveType.WATER)],
  (actualProductionEnabled, waterCurveEnabled) => actualProductionEnabled && waterCurveEnabled,
);

export const selectChosenProdCurvesDataKeys = createSelector(
  [selectActualProductionValue, selectCurveSelectionValues, selectPredictionModelsValues, selectAutoDeclineValues],
  (isActualProductionChosen, curveSelectionValues, predictionModelsValues, autoDeclinesValues) => {
    const modelsAndAutoDeclineValues = { ...predictionModelsValues, ...autoDeclinesValues };
    const modelsAndAutoDeclineKeys = Object.keys(modelsAndAutoDeclineValues);

    return Object.keys(curveSelectionValues)
      .filter((curveType) => curveSelectionValues[curveType as CurveType])
      .reduce<PROD_CURVE_DATA_KEY[]>((acc, selectedCurveType) => {
        const keysActual = isActualProductionChosen
          ? generateChartKeysByCurveType(selectedCurveType as CurveType, PredictionModelType.ACTUAL)
          : [];
        const keysByModels = modelsAndAutoDeclineKeys.reduce<PROD_CURVE_DATA_KEY[]>((acc, modelKey) => {
          const { general, errors } = modelsAndAutoDeclineValues[modelKey as PlanPredictModel];
          if (general || errors) {
            acc.push(
              ...generateChartKeysByCurveType(
                selectedCurveType as CurveType,
                modelKey as PredictionModelType,
                general,
                errors,
              ),
            );
          }

          return acc;
        }, []);

        return [...acc, ...keysActual, ...keysByModels];
      }, []);
  },
);

export const selectLinesProps = createSelector([selectChosenProdCurvesDataKeys], (selectedProdKeys) => {
  return selectedProdKeys.map((key: PROD_CURVE_DATA_KEY) => ({
    dataKey: key,
    stroke: getChartLineColorByProdKey(key),
    strokeDasharray: isSpecialLineStyleNeeded(key) ? '4' : undefined,
  }));
});

export const selectHasVisibleLines = createSelector([selectLinesProps], (lineProps) => lineProps.length > 0);

export const selectVisibleLegendItems = createSelector(
  [selectIsActualLiquidEnabled, selectIsActualGasEnabled, selectIsActualWaterEnabled, selectChosenProdCurvesDataKeys],
  (isActualLiquidEnabled, isActualGasEnabled, isActualWaterEnabled, selectedProdKeys) => {
    const isPredictedLiquidEnabled = isPredictionModelInUse(CurveType.LIQUID, selectedProdKeys);
    const isPredictedGasEnabled = isPredictionModelInUse(CurveType.GAS, selectedProdKeys);
    const isPredictedWaterEnabled = isPredictionModelInUse(CurveType.WATER, selectedProdKeys);

    return [
      isActualLiquidEnabled && { label: 'ACTUAL_LIQUID', isSolidLine: true, color: CURVE_COLOR.liquid },
      isPredictedLiquidEnabled && { label: 'PREDICTED_LIQUID', isSolidLine: false, color: CURVE_COLOR.liquid },
      isActualGasEnabled && { label: 'ACTUAL_GAS', isSolidLine: true, color: CURVE_COLOR.gas },
      isPredictedGasEnabled && { label: 'PREDICTED_GAS', isSolidLine: false, color: CURVE_COLOR.gas },
      isActualWaterEnabled && { label: 'ACTUAL_WATER', isSolidLine: true, color: CURVE_COLOR.water },
      isPredictedWaterEnabled && { label: 'PREDICTED_WATER', isSolidLine: false, color: CURVE_COLOR.water },
    ].filter(Boolean) as {
      label: string;
      isSolidLine: boolean;
      color: (typeof CURVE_COLOR)[keyof typeof CURVE_COLOR];
    }[];
  },
);

export const selectVisibleCurvesDataInfo = createSelector(
  [selectProdCurvesData, selectChosenProdCurvesDataKeys, selectXAxisMinValue, selectXAxisMaxValue, selectYAxisMinValue],
  (data, keys, minX, maxX, valueMin) => getVisibleChartData(data, keys, minX, maxX, valueMin),
);

export const selectVisibleCurvesData = createSelector(
  [selectVisibleCurvesDataInfo],
  (visibleCurvesDataInfo) => visibleCurvesDataInfo.visibleData,
);
export const selectLastValuableXValueForCurrentProdCurvesSettings = createSelector(
  [selectVisibleCurvesDataInfo],
  (visibleCurvesDataInfo) => visibleCurvesDataInfo.lastValuableXValue,
);

export const selectExportingProdCurvesData = createSelector(
  [
    selectRawProdCurvesData,
    selectChosenProdCurvesDataKeys,
    selectXAxisMinValue,
    selectXAxisMaxValue,
    selectLastValuableXValueForCurrentProdCurvesSettings,
  ],
  (loadedProdCurvesData, selectedKeys, minX, maxX, maxNotEmptyX) =>
    getExportingProdCurvesData(loadedProdCurvesData, selectedKeys, minX, maxX, maxNotEmptyX),
);

export const selectIsRawProdCurvesDataEmpty = createSelector(
  [selectRawProdCurvesData],
  (loadedProdCurvesData) => loadedProdCurvesData === null,
);

export const selectIsExportingProdCurvesDataEmpty = createSelector([selectExportingProdCurvesData], (exportingData) =>
  isExportingProdCurvesDataEmpty(exportingData),
);

export const selectMaxChartDataMonth = createSelector([selectProdCurvesData], (chartData) => {
  return chartData?.length ? getChartXAxisMaxValue(chartData) : PROD_CURVES_DEFAULT_MAX_MONTH_VALUE;
});

export const selectMaxChartDataValue = createSelector([selectProdCurvesData], (chartData) => {
  const maxValue = chartData ? getChartYAxisMaxValue(chartData) : PROD_CURVES_DEFAULT_MAX_MONTH_VALUE;

  return Math.ceil(maxValue);
});
