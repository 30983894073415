import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return createStyles({
    popper: {
      zIndex: 5,
      marginTop: '8px',
      marginRight: '10px',
    },
  });
});
