import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'types/RootState';

export const useParamSelector = <TArgs extends Array<any>, TValue>(
  parametrizedSelector: (state: RootState, ...params: TArgs) => TValue,
  ...selectorParams: TArgs
) => {
  return useSelector((state: RootState) => parametrizedSelector(state, ...selectorParams), shallowEqual);
};
