import { TestLocators } from 'constants/Locators';

import { useIsDarkMode } from 'hooks/useIsDarkMode';

import { ReactComponent as JupyterDarkIcon } from './jupyter-dark.svg';
import { ReactComponent as JupyterLightIcon } from './jupyter-light.svg';
import { useStyles } from './styles';

export const Jupyter = () => {
  const classes = useStyles();
  const isDarkMode = useIsDarkMode();

  return (
    <a
      className={classes.link}
      href={`${process.env.REACT_APP_IMMERSE_URL}/jupyter/hub/login?newnotebook=true`}
      title="Open Jupyter"
      target="blank"
      rel="noopener noreferrer"
      data-testid={TestLocators.Header_Jupiter}
    >
      {isDarkMode ? <JupyterLightIcon /> : <JupyterDarkIcon />}
    </a>
  );
};
