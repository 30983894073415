import { MapColorBy, MapLayer } from 'app/components/containers/map/MapLayersSelect/types';
import { ColumnType } from 'types/ColumnType';
import { isSomeEnum } from 'utils/types/enums-check';

export enum LegendId {
  PLANNED_WELLS = 'PLANNED_WELLS',
}

export interface LegendColorBy {
  field: ColumnType | string;
  domain: string[] | number[] | null;
  range: string[] | null;
}

export const isLegendId = isSomeEnum(LegendId);
export interface LegendListItem {
  id: MapLayer | LegendId;
  colorByValue: LegendColorBy | MapColorBy;
}
