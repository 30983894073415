import { isSomeEnum } from 'utils/types/enums-check';

export enum WellPathColumn {
  Basin = 'Basin',
  WKT = 'WKT',
  OPERATOR = 'Operator',
  Isopach = 'Isopach',
}

export const isWellPathColumn = isSomeEnum(WellPathColumn);
