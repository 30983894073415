import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { getWellSettingsArrayIndex } from './hooks/useMapWellsSettings/utils';
import { PlannerWellSettingsState, WellPointSettings, WellSettings } from './types';

// we need exactly function (not object) because of reusing this state not only in redux (which will froze state object)
export const getPlannerMapWellsSettingsInitialState = (): PlannerWellSettingsState => ({
  currentWellId: null,
  hoveredWellId: null,
  movingWellId: null,
  removedWellId: null,
  nextWellId: 1,
  usedColorsIds: [],
  settings: [],
});

const mapWellsSettingsSlice = createSlice({
  name: 'plannerMapWellsSettings',
  initialState: getPlannerMapWellsSettingsInitialState(),
  reducers: {
    addWell(state, { payload }: PayloadAction<WellSettings>) {
      state.settings.push(payload);
    },
    updateWell(state, { payload }: PayloadAction<{ wellId: number; data: WellSettings }>) {
      const index = getWellSettingsArrayIndex(state.settings, payload.wellId);
      state.settings[index] = payload.data;
    },
    updateWellStartPoint(state, { payload }: PayloadAction<{ wellId: number; newStartPoint: WellPointSettings }>) {
      const index = getWellSettingsArrayIndex(state.settings, payload.wellId);
      state.settings[index].startPoint = payload.newStartPoint;
    },
    setCurrentWellId(state, { payload }: PayloadAction<number | null>) {
      state.currentWellId = payload;
    },
    setMovingWellId(state, { payload }: PayloadAction<number | null>) {
      state.movingWellId = payload;
    },
    setHoveredWellId(state, { payload }: PayloadAction<number | null>) {
      state.hoveredWellId = payload;
    },
    renameWell(state, { payload }: PayloadAction<{ wellId: number; newWellName: string }>) {
      const index = getWellSettingsArrayIndex(state.settings, payload.wellId);
      state.settings[index].name = payload.newWellName;
    },
    changeHiddenWell(state, { payload }: PayloadAction<{ wellId: number; hidden: boolean }>) {
      const index = getWellSettingsArrayIndex(state.settings, payload.wellId);
      state.settings[index].hidden = payload.hidden;
    },
    changeHiddenWells(state, { payload: { hidden } }: PayloadAction<{ hidden: boolean }>) {
      state.settings = state.settings.map((wellSettings) => ({ ...wellSettings, hidden }));
    },
    setRemovedWellId(state, { payload }: PayloadAction<number | null>) {
      state.removedWellId = payload;
    },
    removeWell(state, { payload }: PayloadAction<{ wellId: number }>) {
      const index = getWellSettingsArrayIndex(state.settings, payload.wellId);
      state.settings.splice(index, 1);
    },
    removeAllWells(state) {
      state.settings = [];
    },
    setNextWellId(state, { payload }: PayloadAction<number>) {
      state.nextWellId = payload;
    },
    setUsedColorsIds(state, { payload }: PayloadAction<number[]>) {
      state.usedColorsIds = payload;
    },
    setEntireState(state, { payload }: PayloadAction<PlannerWellSettingsState>) {
      return payload;
    },
    resetToInitState() {
      return getPlannerMapWellsSettingsInitialState();
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logWellCreation(_state, _action: PayloadAction<number>) {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logRemoveWell(_state, _payload: PayloadAction<{ wellId: number }>) {},
  },
});

export const { actions, reducer, name: sliceKey } = mapWellsSettingsSlice;
