export enum SUBJECT_TYPE {
  PRICE_FEED = 'priceFeed',
  USER_INFO = 'userInfo',
  CORRELATION_ID = 'correlationId',
}

export enum IMMERSE_SUBJECT_TYPE {
  GET_INIT_INFO = 'getInitInfo',
  OPEN_PAGE = 'open-page',
  CHANGE_THEME = 'change-theme',
  LOG = 'log',
}

class ImmerseNotifier {
  send(frameWindow: Window, subject: SUBJECT_TYPE, value?: object) {
    frameWindow.postMessage(
      {
        source: 'immerse-wrapper',
        subject: subject,
        value: value,
      },
      '*',
    );
  }
}

export const immerseNotifier = new ImmerseNotifier();
