import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      width: 'calc(100% - 24px)',
      margin: 0,
      padding: 0,
      display: 'flex',
      position: 'absolute',
      top: 48,
      left: 24,
      visibility: 'hidden',
    },
    tabPanelActive: {
      visibility: 'visible',
    },
  }),
);
