import { LngLatBounds } from 'mapbox-gl';

import { getPostgreColumnTypeByColumnName } from 'app/components/containers/map/MapLayersSelect/MapLayerSelectItem/MapLayerSettings/MapLayerColorBy/utils';
import { MapColorBy, MapLayer, MapLayerSettings } from 'app/components/containers/map/MapLayersSelect/types';
import { conv4326To900913 } from 'app/components/containers/widgets/MapWidget/utils';
import { SampleRatioParams } from 'app/components/containers/widgets/MapWidget/VegaLayer/types';
import { AcreageAndInventoryColumn } from 'types/AcreageAndInventoryColumn';
import { ColumnType } from 'types/ColumnType';
import { FaultsColumn } from 'types/FaultsColumns';
import { FieldInfo } from 'types/FieldInfo';
import { IsopachColumn } from 'types/IsopachColumn';
import { MidstreamPipelinesColumn } from 'types/MidstreamPipelinesColumn';
import { StructureColumn } from 'types/StructureColumn';
import { TableName } from 'types/TableName';
import { ThermalMaturityColumn } from 'types/ThermalMaturityColumn';
import { Vega } from 'types/Vega';
import { createSqlWhereConditionWithoutEscape } from 'utils/sql';

import {
  ACREAGE_LAYER_COLOR_LIST,
  FAULTS_LAYER_COLOR_LIST,
  ISOPACH_CATEGORY_LAYER_COLOR_LIST,
  MIDSTREAM_CATEGORY_LAYER_COLOR_LIST,
  STRUCTURE_LAYER_COLOR_LIST,
  THERMAL_MATURITY_LAYER_COLOR_LIST,
  VEGA_LAYERS_ORDER,
  omniSciAllowedFiltersColumnNames,
} from './config';
import { VegaFilterValue, VegaMinMaxCoords } from './types';

export const sortVegaLayers = (aLayer: MapLayerSettings, bLayer: MapLayerSettings) =>
  VEGA_LAYERS_ORDER.indexOf(aLayer.id) - VEGA_LAYERS_ORDER.indexOf(bLayer.id);

export const createFilterCondition = <TColumn>(filterValues: (FieldInfo<TColumn> | VegaFilterValue<TColumn>)[]) => {
  return createSqlWhereConditionWithoutEscape(filterValues, 'AND');
};

const resolveObjectPath = (object: Record<string, any>, path: string) => path.split('.').reduce((o, p) => o[p], object);

const getUniqueItemsByKey = (array: Record<string, any>[], key: string) => {
  return Array.from(new Map(array.map((item) => [resolveObjectPath(item, key), item])).values());
};

export const normalizeColorByValue = (colorByValue: MapColorBy | null) => {
  return colorByValue && !!colorByValue?.domain?.length ? colorByValue : undefined;
};

export const mergeVegas = (vegas: Vega[]): Vega => ({
  ...vegas[0],
  data: getUniqueItemsByKey(vegas.flatMap((vega) => vega.data).filter(Boolean), 'name'),
  scales: getUniqueItemsByKey(vegas.flatMap((vega) => vega.scales).filter(Boolean), 'name'),
  projections: getUniqueItemsByKey(vegas.flatMap((vega) => vega.projections).filter(Boolean), 'name'),
  marks: getUniqueItemsByKey(vegas.flatMap((vega) => vega.marks).filter(Boolean), 'from.data'),
});

export function getWellsVegaSpecParams(
  wellsLayer: MapLayerSettings,
  width: number,
  height: number,
  minMaxCoords: VegaMinMaxCoords,
  filterValues: VegaFilterValue[],
) {
  return getCommonVegaSpecParams(wellsLayer, width, height, minMaxCoords, filterValues);
}

export function getMinMaxCoords(mapBounds: LngLatBounds): VegaMinMaxCoords {
  const [xMin, yMin] = conv4326To900913([mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat]);
  const [xMax, yMax] = conv4326To900913([mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat]);

  return { xMin, yMin, xMax, yMax };
}

export function getWellPathVegaSpecParams(
  wellPathLayer: MapLayerSettings,
  width: number,
  height: number,
  minMaxCoords: VegaMinMaxCoords,
  filterValues: VegaFilterValue[],
  mapBounds: LngLatBounds,
) {
  return {
    ...getCommonVegaSpecParams(wellPathLayer, width, height, minMaxCoords, filterValues),
    minLong: mapBounds.getSouthWest().lng,
    maxLong: mapBounds.getNorthEast().lng,
    minLat: mapBounds.getNorthEast().lat,
    maxLat: mapBounds.getSouthWest().lat,
  };
}

export function getVegaSpecParams(
  wellPathLayer: MapLayerSettings,
  width: number,
  height: number,
  minMaxCoords: VegaMinMaxCoords,
  filterValues: VegaFilterValue[],
  mapBounds: LngLatBounds,
) {
  return getWellPathVegaSpecParams(wellPathLayer, width, height, minMaxCoords, filterValues, mapBounds);
}

export function getCommonVegaSpecParams(
  layerSettings: MapLayerSettings,
  width: number,
  height: number,
  minMaxCoords: VegaMinMaxCoords,
  filterValues: VegaFilterValue[],
) {
  const { xMin, yMin, xMax, yMax } = minMaxCoords;

  return {
    width: width,
    height: height,
    minXBounds: xMin,
    maxXBounds: xMax,
    minYBounds: yMin,
    maxYBounds: yMax,
    size: layerSettings.size,
    opacity: layerSettings.opacity / 100,
    colorBy: normalizeColorByValue(layerSettings.colorByValue),
    colorByColumnType: layerSettings.colorByValue?.field
      ? getPostgreColumnTypeByColumnName(layerSettings.colorByValue.field, layerSettings.id)
      : undefined,
    filterValues: filterValues,
  };
}

export const getOmniSciAllowedFilters = <TColumn extends FieldInfo | VegaFilterValue>(
  mapFilters: TColumn[],
  additionalColumnNames: ColumnType[] = [],
) => mapFilters.filter((item) => [...omniSciAllowedFiltersColumnNames, ...additionalColumnNames].includes(item.name));

function isAcreageEstClassColumnForAcreageLayer(layerId: MapLayer, column: ColumnType) {
  return layerId === MapLayer.ACREAGE && column === AcreageAndInventoryColumn.ACREAGE_EST_CLASS;
}

function isCategoryColumnForMidstreamLayer(layerId: MapLayer, column: ColumnType) {
  return layerId === MapLayer.MIDSTREAM && column === MidstreamPipelinesColumn.CATEGORY;
}

function isCategoryColumnForIsopachLayer(layerId: MapLayer, column: ColumnType) {
  return layerId === MapLayer.ISOPACH && column === IsopachColumn.DEPTH_FT;
}

function isCategoryColumnForThermalMaturityLayer(layerId: MapLayer, column: ColumnType) {
  return layerId === MapLayer.THERMAL_MATURITY && column === ThermalMaturityColumn.PLAY_ZONE_TYPE;
}
function isCategoryColumnForStructureLayer(layerId: MapLayer, column: ColumnType) {
  return layerId === MapLayer.SUBSURFACE_STRUCTURE && column === StructureColumn.DEPTH_FT;
}
function isCategoryColumnForFaultsLayer(layerId: MapLayer, column: ColumnType) {
  return layerId === MapLayer.SUBSURFACE_FAULTS && column === FaultsColumn.FORMATION;
}

export const getPreDefinedColorListByLayerAndField = (layerId: MapLayer, column: ColumnType) => {
  if (isAcreageEstClassColumnForAcreageLayer(layerId, column)) {
    return ACREAGE_LAYER_COLOR_LIST;
  }

  if (isCategoryColumnForMidstreamLayer(layerId, column)) {
    return MIDSTREAM_CATEGORY_LAYER_COLOR_LIST;
  }

  if (isCategoryColumnForIsopachLayer(layerId, column)) {
    return ISOPACH_CATEGORY_LAYER_COLOR_LIST;
  }
  if (isCategoryColumnForThermalMaturityLayer(layerId, column)) {
    return THERMAL_MATURITY_LAYER_COLOR_LIST;
  }
  if (isCategoryColumnForStructureLayer(layerId, column)) {
    return STRUCTURE_LAYER_COLOR_LIST;
  }
  if (isCategoryColumnForFaultsLayer(layerId, column)) {
    return FAULTS_LAYER_COLOR_LIST;
  }

  return undefined;
};

export const getSampleRatioParams = (table: TableName, mapBounds: LngLatBounds): SampleRatioParams => ({
  table: table,
  mapBounds: {
    minLng: mapBounds.getSouthWest().lng,
    maxLng: mapBounds.getNorthEast().lng,
    minLat: mapBounds.getNorthEast().lat,
    maxLat: mapBounds.getSouthWest().lat,
  },
});
