import { PropsWithChildren, useEffect } from 'react';

export interface ButtonDownListenerProps extends PropsWithChildren<{}> {
  handler: (event: KeyboardEvent) => void;
}

export function ButtonDownListener({ children, handler }: ButtonDownListenerProps) {
  useEffect(() => {
    document.addEventListener<'keydown'>('keydown', handler, false);

    return () => {
      document.removeEventListener<'keydown'>('keydown', handler, false);
    };
  }, [handler]);

  return <>{children}</>;
}
