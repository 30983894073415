import { createStyles, makeStyles } from '@material-ui/core/styles';

const indent = '16px';

export const useStyles = makeStyles((theme) => {
  const { primary } = theme.palette;
  const { fontWeightRegular } = theme.typography;

  return createStyles({
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 36,
      padding: `0 ${indent}`,
      fontWeight: fontWeightRegular as number,
    },
    copy: {
      color: primary.main,
      cursor: 'pointer',
      marginLeft: indent,
    },
    linkItem: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  });
});
