import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';
import { ProdCurvesRequestPayload, ProdCurvesResponsePayload } from 'api/curves';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { PredictProdCurveItem, PredictProdCurvesWidgetState } from './types';

export const initialState: PredictProdCurvesWidgetState = {
  status: RequestStatus.IDLE,
  error: null,
  data: null,
  rawData: null,
};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'predictProdCurvesWidget',
  initialState,
  reducers: {
    loadProdCurves(state, _action: PayloadAction<ProdCurvesRequestPayload>) {
      state.status = RequestStatus.LOADING;
      state.error = null;
    },
    prodCurvesLoaded(
      state,
      { payload }: PayloadAction<{ data: PredictProdCurveItem[] | null; rawData: ProdCurvesResponsePayload[] | null }>,
    ) {
      state.status = RequestStatus.SUCCESS;
      state.data = payload.data;
      state.rawData = payload.rawData;
    },
    resetProdCurvesData() {
      return { ...initialState };
    },
    prodCurvesError(state, action: PayloadAction<string>) {
      state.status = RequestStatus.FAILURE;
      state.error = action.payload;
    },
    clearProdCurvesError(state) {
      state.error = null;
      state.status = RequestStatus.IDLE;
    },
  },
});
