import { useEffect } from 'react';

import { trackVirtualPageview as pageView } from 'common/analytics';

export const TrackVirtualPageView = () => {
  useEffect(() => {
    pageView();
  }, []);

  return <></>;
};
