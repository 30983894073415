import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledElement } from 'app/components/common/LabeledElement';
import { Switch } from 'app/components/common/Switch';

export interface LabeledToggleProps {
  label: string;
  testId?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export function LabeledToggle({ testId, checked, onChange, label }: LabeledToggleProps) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange],
  );

  return (
    <LabeledElement label={t(label)} testId={testId}>
      <Switch checked={checked} onChange={handleChange} />
    </LabeledElement>
  );
}
