import { MenuItem } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';

import { HeaderMenu } from 'app/components/containers/layout/Header/HeaderMenu';

import { useStyles } from './styles';

export interface PriceFeedMenuProps {
  anchorEl: PopoverProps['anchorEl'];
  onClose: PopoverProps['onClose'];
  prices: JSX.Element[];
}

export function PriceFeedMenu({ anchorEl, onClose, prices }: PriceFeedMenuProps) {
  const classes = useStyles();

  return (
    <HeaderMenu id="price-feed-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {prices.map((price, index) => (
        <MenuItem key={index} className={classes.menuItem} disableRipple>
          {price}
        </MenuItem>
      ))}
    </HeaderMenu>
  );
}
