import { createSelector } from '@reduxjs/toolkit';

import {
  AxisIntervalBorderNameType,
  AxisNameType,
} from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { CurveType, ProductionTypeValue } from 'types/Curve';
import { PredictionModelType } from 'types/PlanPredictModel';
import { RootState } from 'types/RootState';

import { initialState } from './slice';

const selectPredictProdCurvesSettingsDomain = (state: RootState) => state?.predictProdCurvesSettings || initialState;

export const selectCurveSelectionValues = createSelector(
  [selectPredictProdCurvesSettingsDomain],
  (state) => state.curveSelection,
);

export const selectCurveSelectionItemValue = createSelector(
  [selectCurveSelectionValues, (state: RootState, id: CurveType) => id],
  (curveSelectionValues, id) => curveSelectionValues[id],
);

export const selectActualProductionValue = createSelector(
  [selectPredictProdCurvesSettingsDomain],
  (state) => state.actualProduction,
);

export const selectCumulativeProductionValue = createSelector(
  [selectPredictProdCurvesSettingsDomain],
  (state) => state.cumulativeProduction,
);

export const selectProductionTypeValue = createSelector([selectCumulativeProductionValue], (isCumulative) =>
  isCumulative ? ProductionTypeValue.CUM : ProductionTypeValue.RATE,
);

export const selectIsErrorsCurvesChoosingDisabled = createSelector(
  [selectProductionTypeValue],
  (prodType) => prodType === ProductionTypeValue.RATE,
);

export const selectPredictionModelsValues = createSelector(
  [selectPredictProdCurvesSettingsDomain],
  (state) => state.predictionModels,
);

export const selectAutoDeclineValues = createSelector(
  [selectPredictProdCurvesSettingsDomain],
  (state) => state.autoDecline,
);

export const selectPredictionModelsGeneralValue = createSelector(
  [selectPredictionModelsValues, (state: RootState, id: PredictionModelType) => id],
  (predictionModelsValues, id): boolean => {
    return predictionModelsValues[id]['general'] || false;
  },
);

export const selectPredictionModelsErrorsValue = createSelector(
  [selectPredictionModelsValues, (state: RootState, id: PredictionModelType) => id],
  (predictionModelsValues, id): boolean => predictionModelsValues[id]['errors'] || false,
);

export const selectAxisIntervalBorderValue = createSelector(
  [
    selectPredictProdCurvesSettingsDomain,
    (state: RootState, axisName: AxisNameType) => axisName,
    (state: RootState, axisName: AxisNameType, borderName: AxisIntervalBorderNameType) => borderName,
  ],
  (state, axisName, borderName) => state.axis[axisName][borderName],
);

export const selectXAxisMinValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.X, AxisIntervalBorderNameType.MIN)],
  (min) => min,
);
export const selectXAxisMaxValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.X, AxisIntervalBorderNameType.MAX)],
  (max) => max,
);

export const selectYAxisMinValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.Y, AxisIntervalBorderNameType.MIN)],
  (min) => min,
);
export const selectYAxisMaxValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.Y, AxisIntervalBorderNameType.MAX)],
  (max) => max,
);

export const selectIsAnyAxisBorderSet = createSelector(
  [selectXAxisMinValue, selectXAxisMaxValue, selectYAxisMinValue, selectYAxisMaxValue],
  (xMin, xMax, yMin, yMax) => xMin !== null || xMax !== null || yMin !== null || yMax !== null,
);

export const selectLabelsValue = createSelector([selectPredictProdCurvesSettingsDomain], (state) => state.labels);

export const selectGridlinesValue = createSelector([selectPredictProdCurvesSettingsDomain], (state) => state.gridlines);
