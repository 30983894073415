import { datetimeMomentFormat } from 'constants/Dates';
import { TestLocators } from 'constants/Locators';

import moment, { ISO_8601, Moment } from 'moment';

export interface FormatNumberByLocaleOptions extends Intl.NumberFormatOptions {
  lang?: string;
}

export const formatByLocale = (value: string | number, options?: FormatNumberByLocaleOptions): string => {
  const valueNumberType = typeof value === 'string' ? Number(value) : value;
  const { lang = 'en', ...numberFormatOptions } = options ?? {
    maximumFractionDigits: 20, // show all digits by default
  };

  return new Intl.NumberFormat(lang, numberFormatOptions as Intl.NumberFormatOptions).format(valueNumberType);
};

export const formatDate = (datetime: string | Moment) => {
  return typeof datetime === 'string'
    ? moment.utc(datetime, ISO_8601).format(datetimeMomentFormat)
    : datetime.format(datetimeMomentFormat);
};

export const formatTestLocator = (testLocator: TestLocators | string, label: string) =>
  `${testLocator}_${label.replace(/[,.:;/\s]+/g, '_')}`;
