import { CURVE_COLOR } from 'constants/Curves';

import { AxisDomain } from 'recharts/types/util/types';
import { CurveType } from 'types/Curve';

import { GAS_STREAMS_VALUES_DIVIDER, MONTH_NUMBER_DATA_KEY } from './constants';
import { CurveItem } from './types';

export const getXAxisXDomain = (
  minValue: number | null,
  maxValue: number | null,
  isChartDataEmpty: boolean,
): AxisDomain => {
  const minBorder = minValue ?? 'dataMin';
  let maxBorder = maxValue ?? 'dataMax';

  if (maxValue === null && isChartDataEmpty) {
    maxBorder = 100;
  }

  return [minBorder, maxBorder];
};
export const getYAxisDomain = (
  minValue: number | null,
  maxValue: number | null,
  isChartDataEmpty: boolean,
): AxisDomain => {
  const minBorder = minValue === null ? 0 : minValue;
  let maxBorder = maxValue === null ? 'dataMax' : maxValue;

  if (maxValue === null && isChartDataEmpty) {
    maxBorder = 100;
  }

  return [minBorder, maxBorder];
};

export const getChartLineColorByProdKey = (dataKey: string) => {
  const type = dataKey.split('_')[0] as CurveType;

  return CURVE_COLOR[type];
};

export const getChartXAxisMaxValue = <T extends string>(chartData: CurveItem<T>[]) => {
  return chartData.length ? Math.max(...chartData.map((chartItem) => chartItem.monthNumber)) : 0;
};

export const getChartYAxisMaxValue = <T extends string>(chartData: CurveItem<T>[]) => {
  return chartData.reduce((max, chartItem) => {
    const maxChartItemValue = Object.keys(chartItem).reduce((maxValue, chartItemKey) => {
      if (chartItemKey !== MONTH_NUMBER_DATA_KEY) {
        const value = chartItem[chartItemKey as keyof CurveItem<T>] ?? 0;

        return value > maxValue ? value : maxValue;
      }

      return maxValue;
    }, 0);

    return maxChartItemValue > max ? maxChartItemValue : max;
  }, 0);
};

export function getDataWithGasStreamsDividedBy6<T extends { Task: CurveType } & { [key: string]: number | null }>(
  rawData: T[],
): T[] {
  rawData.forEach((item) => {
    if (item.Task === CurveType.GAS) {
      for (const prop in item) {
        const value = item[prop];
        if (prop !== 'wellId' && value !== null && typeof value == 'number') {
          item[prop] = (value / GAS_STREAMS_VALUES_DIVIDER) as T[typeof prop];
        }
      }
    }
  });

  return rawData;
}

function checkProdCurvesDataItemHasNotNullData<TItem extends { [MONTH_NUMBER_DATA_KEY]: number }>(
  item: TItem,
): boolean {
  let result = false;
  const keys = Object.keys(item);
  let key = null;
  for (let i = 0; i < keys.length; i++) {
    key = keys[i] as keyof TItem;
    if (key !== MONTH_NUMBER_DATA_KEY && item[key] !== null) {
      result = true;
      break;
    }
  }

  return result;
}

function getMaxLineValue<TItem extends { [MONTH_NUMBER_DATA_KEY]: number }>(item: TItem): null | number {
  let resultMax = null;
  const keys = Object.keys(item);
  let key = null;
  let currentValue = 0 as any;
  for (let i = 0; i < keys.length; i++) {
    key = keys[i] as keyof TItem;
    currentValue = item[key];
    if (currentValue !== null) {
      if (key !== MONTH_NUMBER_DATA_KEY && (currentValue > resultMax || resultMax === null)) {
        resultMax = currentValue;
      }
    }
  }

  return resultMax;
}

function getRightXBorder(maxX: number | null, maxXbyY: number | null, lastValuableIndex: number): number {
  const computedMax = maxX !== null ? maxX + 1 : maxXbyY !== null ? maxXbyY + 1 : null;

  return computedMax !== null && lastValuableIndex > computedMax ? computedMax : lastValuableIndex;
}

function getLeftXBorder(minX: number | null, minXbyY: number | null): number {
  return minX !== null ? minX : minXbyY !== null ? minXbyY : 0;
}

export function getVisibleChartData<TItem extends { [MONTH_NUMBER_DATA_KEY]: number }>(
  loadedChartData: TItem[] | null,
  keys: (keyof TItem)[],
  minX: number | null,
  maxX: number | null,
  valueMin: number | null,
): {
  visibleData: TItem[] | null;
  lastValuableXValue: number;
} {
  let xMinByY = null as null | number;
  let xMaxByY = null as null | number;
  let result: TItem[] = [];

  loadedChartData?.forEach((value) => {
    const item = {} as TItem;
    item.monthNumber = value.monthNumber;
    keys.forEach((key) => {
      item[key] = value[key];
    });
    result.push(item);
  });

  let lastValuableIndex = 0;
  if (result.length > 0) {
    const start = minX !== null ? minX : 0;
    const end = maxX !== null ? maxX : result.length - 1;
    for (let index = start; index <= end; index++) {
      const value = result[index];
      if (checkProdCurvesDataItemHasNotNullData(value) && index > lastValuableIndex) {
        lastValuableIndex = index;
      }

      const max = getMaxLineValue(value);
      if (max !== null) {
        if (xMinByY === null && valueMin !== null && max >= valueMin && value.monthNumber !== null) {
          xMinByY = value.monthNumber;
        }

        if (valueMin !== null && max >= valueMin && value.monthNumber !== null) {
          xMaxByY = value.monthNumber;
        }
      }
    }
    const borderByLastValuableIndex = lastValuableIndex > 0 ? lastValuableIndex + 1 : lastValuableIndex;

    const leftBorder = getLeftXBorder(minX, xMinByY);
    const rightBorder = getRightXBorder(maxX, xMaxByY, borderByLastValuableIndex);

    result = result.slice(leftBorder, rightBorder);
  }

  return {
    visibleData: result.length ? result : null,
    lastValuableXValue: lastValuableIndex,
  };
}
