import { api } from 'constants/API';

import axios, { CancelTokenSource } from 'axios';

import { ProductUpdate } from 'types/ProductUpdate';

export const fetchProductUpdatesByCategory = ({ token: cancelToken }: CancelTokenSource) =>
  axios.get<ProductUpdate[]>(`${api.getProductUpdatesByCategory}`, {
    cancelToken,
  });

export const trackAllProductUpdatesAsViewed = (cancelToken: CancelTokenSource) =>
  axios.patch(api.trackAllProductUpdatesAsViewed, { cancelToken });
