import { TestLocators } from 'constants/Locators';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AutocompleteSelect } from 'app/components/common/AutocompleteSelect';
import { selectWellOrientation } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/selectors';
import {
  AVAILABLE_WELL_ORIENTATION_OPTIONS,
  actions as wellDesignCapexActions,
} from 'app/components/containers/layout/ConfigTabs/EconomicsControl/WellDesignCapex/slice';
import { planPredictEconomicsControlPanelChange } from 'common/analytics';
import { translations } from 'locales/i18n';
import { MapWidgetId } from 'types/Widget';

import { useStyles } from './styles';

export function WellOrientation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const wellOrientation = useSelector(selectWellOrientation);
  const label = translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.WELL_ORIENTATION.TITLE;

  const handleChangeWellOrientation = useCallback(
    (value: string) => {
      planPredictEconomicsControlPanelChange(
        t(label),
        value,
        t(translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.TITLE),
        MapWidgetId.ECONOMICS_MAP_WIDGET,
      );

      dispatch(wellDesignCapexActions.changeWellOrientation({ wellOrientation: value }));
    },
    [dispatch, label, t],
  );

  return (
    <div className={classes.label} data-testid={`${TestLocators.WellDesignCapex_Calculate_WellOrientation}_Wrapper`}>
      <AutocompleteSelect
        value={wellOrientation}
        onChange={handleChangeWellOrientation}
        options={AVAILABLE_WELL_ORIENTATION_OPTIONS}
        disableClearable
        readOnly
        selectOnFocus={false}
        label={t(label)}
        testId={TestLocators.WellDesignCapex_Calculate_WellOrientation}
      />
    </div>
  );
}
