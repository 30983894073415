import { ThemeMode } from 'impact-react-components';

import { useTheme } from '@material-ui/core/styles';

export function useIsDarkMode() {
  const {
    palette: { type },
  } = useTheme();

  return type === ThemeMode.DARK;
}
