/* eslint-disable react/jsx-no-literals */
import { PropsWithChildren, useCallback } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';

export function AppErrorBoundary({ children }: PropsWithChildren<{}>) {
  const logError = useCallback(
    (
      error: Error,
      info: {
        componentStack: string;
      },
    ) => {
      log.error({
        event: LogEvent.UNHANDLED_ERROR,
        source: LogSource.UNKNOWN,
        errorName: error?.name,
        errorMessage: error?.message,
        errorStack: error?.stack,
        componentStack: info?.componentStack,
      });
    },
    [],
  );

  const fallbackRender = useCallback(({ error }: FallbackProps) => {
    if (error.name === 'ChunkLoadError') {
      // Reload page if we catch ChunkLoadError error. The application was updated and need to load a new version of HTML
      document.location.reload();

      return null;
    } else {
      return (
        <>
          <p>Something went wrong...</p>
          <button onClick={() => document.location.reload()}>Reload page</button>
          <pre>{error.stack}</pre>
        </>
      );
    }
  }, []);

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
      {children}
    </ErrorBoundary>
  );
}
