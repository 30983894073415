export enum DefaultRole {
  ADMIN = 'administrator',
  // `administrator` -> has access to both, NIMS and CSM as admin
  ADMIN_CSM = 'admin_csm',
  // `administrator` -> has access to CSM as admin
  ADMIN_NIMS = 'admin_nims',
  // `administrator` -> has access to NIMS as admin
  BASIC_ACCESS_DB_NIMS = 'basic_access_db_nims',
  BASIC_ACCESS_DB_CSM = 'basic_access_db_csm',
  IMPACT_TRIAL = 'impact_trial',
  EMISSIONS_CANADA_ONLY = 'emissions_canada_only',
  EMISSIONS_NA = 'emissions_na',
  EMISSIONS_US_ONLY = 'emissions_us_only',
  IMPACT_CANADA_ONLY = 'impact_canada_only',
  IMPACT_US_ONLY = 'impact_us_only',
  LAND_UNIT_ALL = 'land_unit_all',
  MIDSTREAM_ALL = 'midstream_all',
  MIDSTREAM_CANADA_ONLY = 'midstream_canada_only',
  MIDSTREAM_US_ONLY = 'midstream_us_only',
  OMNISCI_JUPYTER = 'omnisci_jupyter',
  RESTRICTED_SHARING = 'restricted_sharing', // HEAVY.AI role: "Share/Unshare - Share or unshare selected dashboards with specific users or roles. If a user has been assigned the restricted_sharing role, sharing dashboards is unavailable."
  CREATE_DASHBOARD = 'create_dashboard',
  IMMERSE_EXPORT_DISABLED = 'immerse_export_disabled',
  SQL_EDITOR = 'sql_editor',
}

export enum MarketDataRole {
  MARKET_DATA_ADC = 'market_data_adc',
  MARKET_DATA_ADH = 'market_data_adh',
  MARKET_DATA_AHD = 'market_data_ahd',
  MARKET_DATA_AHE = 'market_data_ahe',
  MARKET_DATA_AHF = 'market_data_ahf',
  MARKET_DATA_AHL = 'market_data_ahl',
  MARKET_DATA_AHM = 'market_data_ahm',
  MARKET_DATA_AHO = 'market_data_aho',
  MARKET_DATA_ALD = 'market_data_ald',
  MARKET_DATA_ALE = 'market_data_ale',
  MARKET_DATA_ALF = 'market_data_alf',
  MARKET_DATA_ALL = 'market_data_all',
  MARKET_DATA_ALM = 'market_data_alm',
  MARKET_DATA_ALO = 'market_data_alo',
  MARKET_DATA_AMC = 'market_data_amc',
  MARKET_DATA_AMF = 'market_data_amf',
  MARKET_DATA_CMR = 'market_data_cmr',
  MARKET_DATA_DA = 'market_data_da',
  MARKET_DATA_DAC = 'market_data_dac',
  MARKET_DATA_DAG = 'market_data_dag',
  MARKET_DATA_DI = 'market_data_di',
  MARKET_DATA_DLF = 'market_data_dlf',
  MARKET_DATA_DR = 'market_data_dr',
  MARKET_DATA_DRC = 'market_data_drc',
  MARKET_DATA_DU = 'market_data_du',
  MARKET_DATA_DUC = 'market_data_duc',
  MARKET_DATA_EDC = 'market_data_edc',
  MARKET_DATA_EMC = 'market_data_emc',
  MARKET_DATA_FDA = 'market_data_fda',
  MARKET_DATA_FDC = 'market_data_fdc',
  MARKET_DATA_FDE = 'market_data_fde',
  MARKET_DATA_FDH = 'market_data_fdh',
  MARKET_DATA_FDU = 'market_data_fdu',
  MARKET_DATA_FMC = 'market_data_fmc',
  MARKET_DATA_GDC = 'market_data_gdc',
  MARKET_DATA_GF = 'market_data_gf',
  MARKET_DATA_GMC = 'market_data_gmc',
  MARKET_DATA_ILO = 'market_data_ilo',
  MARKET_DATA_INO = 'market_data_ino',
  MARKET_DATA_ISO = 'market_data_iso',
  MARKET_DATA_JDC = 'market_data_jdc',
  MARKET_DATA_JDH = 'market_data_jdh',
  MARKET_DATA_JMC = 'market_data_jmc',
  MARKET_DATA_MDC = 'market_data_mdc',
  MARKET_DATA_MMC = 'market_data_mmc',
  MARKET_DATA_MPA = 'market_data_mpa',
  MARKET_DATA_MPR = 'market_data_mpr',
  MARKET_DATA_MPU = 'market_data_mpu',
  MARKET_DATA_MQN = 'market_data_mqn',
  MARKET_DATA_ODC = 'market_data_odc',
  MARKET_DATA_ODH = 'market_data_odh',
  MARKET_DATA_OMC = 'market_data_omc',
  MARKET_DATA_PDC = 'market_data_pdc',
  MARKET_DATA_PE = 'market_data_pe',
  MARKET_DATA_PFA = 'market_data_pfa',
  MARKET_DATA_PFR = 'market_data_pfr',
  MARKET_DATA_PFU = 'market_data_pfu',
  MARKET_DATA_PMC = 'market_data_pmc',
  MARKET_DATA_QDA = 'market_data_qda',
  MARKET_DATA_QDG = 'market_data_qdg',
  MARKET_DATA_QDR = 'market_data_qdr',
  MARKET_DATA_QDU = 'market_data_qdu',
  MARKET_DATA_QDX = 'market_data_qdx',
  MARKET_DATA_QMX = 'market_data_qmx',
  MARKET_DATA_RDC = 'market_data_rdc',
  MARKET_DATA_RHD = 'market_data_rhd',
  MARKET_DATA_RHE = 'market_data_rhe',
  MARKET_DATA_RHF = 'market_data_rhf',
  MARKET_DATA_RHL = 'market_data_rhl',
  MARKET_DATA_RHM = 'market_data_rhm',
  MARKET_DATA_RHO = 'market_data_rho',
  MARKET_DATA_RMC = 'market_data_rmc',
  MARKET_DATA_RSD = 'market_data_rsd',
  MARKET_DATA_RSE = 'market_data_rse',
  MARKET_DATA_RSF = 'market_data_rsf',
  MARKET_DATA_RSL = 'market_data_rsl',
  MARKET_DATA_RSM = 'market_data_rsm',
  MARKET_DATA_RSO = 'market_data_rso',
  MARKET_DATA_SDC = 'market_data_sdc',
  MARKET_DATA_SDH = 'market_data_sdh',
  MARKET_DATA_SMC = 'market_data_smc',
  MARKET_DATA_UDC = 'market_data_udc',
  MARKET_DATA_ULD = 'market_data_uld',
  MARKET_DATA_ULE = 'market_data_ule',
  MARKET_DATA_ULF = 'market_data_ulf',
  MARKET_DATA_ULL = 'market_data_ull',
  MARKET_DATA_ULM = 'market_data_ulm',
  MARKET_DATA_ULO = 'market_data_ulo',
  MARKET_DATA_UMC = 'market_data_umc',
  MARKET_DATA_USD = 'market_data_usd',
  MARKET_DATA_USE = 'market_data_use',
  MARKET_DATA_USF = 'market_data_usf',
  MARKET_DATA_USL = 'market_data_usl',
  MARKET_DATA_USM = 'market_data_usm',
  MARKET_DATA_USO = 'market_data_uso',
  MARKET_DATA_WDC = 'market_data_wdc',
  MARKET_DATA_WDH = 'market_data_wdh',
  MARKET_DATA_WMC = 'market_data_wmc',
}

export enum HistMarketDataRole {
  HIST_MARKET_DATA_ADC = 'hist_market_data_adc',
  HIST_MARKET_DATA_ADH = 'hist_market_data_adh',
  HIST_MARKET_DATA_AHD = 'hist_market_data_ahd',
  HIST_MARKET_DATA_AHE = 'hist_market_data_ahe',
  HIST_MARKET_DATA_AHF = 'hist_market_data_ahf',
  HIST_MARKET_DATA_AHL = 'hist_market_data_ahl',
  HIST_MARKET_DATA_AHM = 'hist_market_data_ahm',
  HIST_MARKET_DATA_AHO = 'hist_market_data_aho',
  HIST_MARKET_DATA_ALD = 'hist_market_data_ald',
  HIST_MARKET_DATA_ALE = 'hist_market_data_ale',
  HIST_MARKET_DATA_ALF = 'hist_market_data_alf',
  HIST_MARKET_DATA_ALL = 'hist_market_data_all',
  HIST_MARKET_DATA_ALM = 'hist_market_data_alm',
  HIST_MARKET_DATA_ALO = 'hist_market_data_alo',
  HIST_MARKET_DATA_AMC = 'hist_market_data_amc',
  HIST_MARKET_DATA_AMF = 'hist_market_data_amf',
  HIST_MARKET_DATA_CMR = 'hist_market_data_cmr',
  HIST_MARKET_DATA_DA = 'hist_market_data_da',
  HIST_MARKET_DATA_DAC = 'hist_market_data_dac',
  HIST_MARKET_DATA_DAG = 'hist_market_data_dag',
  HIST_MARKET_DATA_DI = 'hist_market_data_di',
  HIST_MARKET_DATA_DLF = 'hist_market_data_dlf',
  HIST_MARKET_DATA_DR = 'hist_market_data_dr',
  HIST_MARKET_DATA_DRC = 'hist_market_data_drc',
  HIST_MARKET_DATA_DU = 'hist_market_data_du',
  HIST_MARKET_DATA_DUC = 'hist_market_data_duc',
  HIST_MARKET_DATA_EDC = 'hist_market_data_edc',
  HIST_MARKET_DATA_EMC = 'hist_market_data_emc',
  HIST_MARKET_DATA_FDA = 'hist_market_data_fda',
  HIST_MARKET_DATA_FDC = 'hist_market_data_fdc',
  HIST_MARKET_DATA_FDE = 'hist_market_data_fde',
  HIST_MARKET_DATA_FDH = 'hist_market_data_fdh',
  HIST_MARKET_DATA_FDU = 'hist_market_data_fdu',
  HIST_MARKET_DATA_FMC = 'hist_market_data_fmc',
  HIST_MARKET_DATA_GDC = 'hist_market_data_gdc',
  HIST_MARKET_DATA_GF = 'hist_market_data_gf',
  HIST_MARKET_DATA_GMC = 'hist_market_data_gmc',
  HIST_MARKET_DATA_ILO = 'hist_market_data_ilo',
  HIST_MARKET_DATA_INO = 'hist_market_data_ino',
  HIST_MARKET_DATA_ISO = 'hist_market_data_iso',
  HIST_MARKET_DATA_JDC = 'hist_market_data_jdc',
  HIST_MARKET_DATA_JDH = 'hist_market_data_jdh',
  HIST_MARKET_DATA_JMC = 'hist_market_data_jmc',
  HIST_MARKET_DATA_MDC = 'hist_market_data_mdc',
  HIST_MARKET_DATA_MMC = 'hist_market_data_mmc',
  HIST_MARKET_DATA_MPA = 'hist_market_data_mpa',
  HIST_MARKET_DATA_MPR = 'hist_market_data_mpr',
  HIST_MARKET_DATA_MPU = 'hist_market_data_mpu',
  HIST_MARKET_DATA_MQN = 'hist_market_data_mqn',
  HIST_MARKET_DATA_ODC = 'hist_market_data_odc',
  HIST_MARKET_DATA_ODH = 'hist_market_data_odh',
  HIST_MARKET_DATA_OMC = 'hist_market_data_omc',
  HIST_MARKET_DATA_PDC = 'hist_market_data_pdc',
  HIST_MARKET_DATA_PE = 'hist_market_data_pe',
  HIST_MARKET_DATA_PFA = 'hist_market_data_pfa',
  HIST_MARKET_DATA_PFR = 'hist_market_data_pfr',
  HIST_MARKET_DATA_PFU = 'hist_market_data_pfu',
  HIST_MARKET_DATA_PMC = 'hist_market_data_pmc',
  HIST_MARKET_DATA_QDA = 'hist_market_data_qda',
  HIST_MARKET_DATA_QDG = 'hist_market_data_qdg',
  HIST_MARKET_DATA_QDR = 'hist_market_data_qdr',
  HIST_MARKET_DATA_QDU = 'hist_market_data_qdu',
  HIST_MARKET_DATA_QDX = 'hist_market_data_qdx',
  HIST_MARKET_DATA_QMX = 'hist_market_data_qmx',
  HIST_MARKET_DATA_RDC = 'hist_market_data_rdc',
  HIST_MARKET_DATA_RHD = 'hist_market_data_rhd',
  HIST_MARKET_DATA_RHE = 'hist_market_data_rhe',
  HIST_MARKET_DATA_RHF = 'hist_market_data_rhf',
  HIST_MARKET_DATA_RHL = 'hist_market_data_rhl',
  HIST_MARKET_DATA_RHM = 'hist_market_data_rhm',
  HIST_MARKET_DATA_RHO = 'hist_market_data_rho',
  HIST_MARKET_DATA_RMC = 'hist_market_data_rmc',
  HIST_MARKET_DATA_RSD = 'hist_market_data_rsd',
  HIST_MARKET_DATA_RSE = 'hist_market_data_rse',
  HIST_MARKET_DATA_RSF = 'hist_market_data_rsf',
  HIST_MARKET_DATA_RSL = 'hist_market_data_rsl',
  HIST_MARKET_DATA_RSM = 'hist_market_data_rsm',
  HIST_MARKET_DATA_RSO = 'hist_market_data_rso',
  HIST_MARKET_DATA_SDC = 'hist_market_data_sdc',
  HIST_MARKET_DATA_SDH = 'hist_market_data_sdh',
  HIST_MARKET_DATA_SMC = 'hist_market_data_smc',
  HIST_MARKET_DATA_UDC = 'hist_market_data_udc',
  HIST_MARKET_DATA_ULD = 'hist_market_data_uld',
  HIST_MARKET_DATA_ULE = 'hist_market_data_ule',
  HIST_MARKET_DATA_ULF = 'hist_market_data_ulf',
  HIST_MARKET_DATA_ULL = 'hist_market_data_ull',
  HIST_MARKET_DATA_ULM = 'hist_market_data_ulm',
  HIST_MARKET_DATA_ULO = 'hist_market_data_ulo',
  HIST_MARKET_DATA_UMC = 'hist_market_data_umc',
  HIST_MARKET_DATA_USD = 'hist_market_data_usd',
  HIST_MARKET_DATA_USE = 'hist_market_data_use',
  HIST_MARKET_DATA_USF = 'hist_market_data_usf',
  HIST_MARKET_DATA_USL = 'hist_market_data_usl',
  HIST_MARKET_DATA_USM = 'hist_market_data_usm',
  HIST_MARKET_DATA_USO = 'hist_market_data_uso',
  HIST_MARKET_DATA_WDC = 'hist_market_data_wdc',
  HIST_MARKET_DATA_WDH = 'hist_market_data_wdh',
  HIST_MARKET_DATA_WMC = 'hist_market_data_wmc',
}

export enum AdditionalRole {
  FWD_CURVES_WITH_HISTORIC_USER = 'fwd_curves_with_historic_user',
  FWD_CURVES_WITH_CURRENT_USER = 'fwd_curves_with_current_user',
  FWD_CURVES_ALL_EXISTING_ROLES = 'fwd_curves_all_existing_roles',
  BASE_UPSTREAM_BUNDLE_ALL = 'base_upstream_bundle_all',
  VIEW_ALL_DASHBOARDS_ROLE = 'view_all_dashboards',
}

export enum InjectionRole {
  ALBERTA = 'alberta',
  ROCKY_MOUNTAIN = 'rocky_mountain',
  NORTHEASTERN_US = 'northeastern_us',
  KANSAS_TEXAS_10_OKLA_PANHANDLE = 'kansas_texas_10_okla_panhandle',
  PERMIAN_BASIN = 'permian_basin',
  WEST_COAST_ALASKA = 'west_coast_alaska',
  MANITOBA = 'manitoba',
  SASKATCHEWAN = 'saskatchewan',
  BRITISH_COLUMBIA = 'british_columbia',
  NORTHERN_LOUISIANA_ARKANSAS = 'northern_louisiana_arkansas',
  TEXAS_1_4 = 'texas_1_4',
  GULF_OF_MEXICO = 'gulf_of_mexico',
  TEXAS_5_6_9_7_B = 'texas_5_6_9_7_b',
  SOUTHEASTERN_US = 'southeastern_us',
  OKLAHOMA = 'oklahoma',
  SOUTHERN_LOUISIANA = 'southern_louisiana',
  NORTHWEST_TERRITORIES = 'northwest_territories',
  NOVA_SCOTIA = 'nova_scotia',
  NEWFOUNDLAND_AND_LABRADOR = 'newfoundland_and_labrador',
  YUKON = 'yukon',
  QUEBEC = 'quebec',
}

export enum CSMUpstreamRole {
  UPSTREAM_CONTENT_CAN = 'upstream_content_can',
  UPSTREAM_CONTENT_US = 'upstream_content_us',
  UPSTREAM_CONTENT_INTERNATIONAL = 'upstream_content_all',
}

export const UserRole = {
  ...DefaultRole,
  ...MarketDataRole,
  ...HistMarketDataRole,
  ...AdditionalRole,
  ...InjectionRole,
  ...CSMUpstreamRole,
};

export type TUserRole =
  | DefaultRole
  | MarketDataRole
  | HistMarketDataRole
  | AdditionalRole
  | InjectionRole
  | CSMUpstreamRole;
