import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { ProductUpdateType } from 'types/ProductUpdate';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { NotificationsState, ParsedProductUpdate, ProductUpdateState } from './types';

export const initialState: NotificationsState = {
  notifications: [],
  status: RequestStatus.IDLE,
  productUpdateType: null,
  error: null,
  trackNotificationsStatus: RequestStatus.IDLE,
  trackNotificationsStatusError: null,
  activeProductUpdateInWindow: ProductUpdateType.Notification,
  scrollProductUpdateState: null,
};

const notificationsListSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    loadNotifications(state, { payload }: PayloadAction<{ productUpdateType: ProductUpdateType }>) {
      state.status = RequestStatus.LOADING;
      state.productUpdateType = payload.productUpdateType;
      state.error = null;
    },
    notificationsLoaded(state, action: PayloadAction<ParsedProductUpdate[]>) {
      state.notifications = action.payload;
      state.status = RequestStatus.SUCCESS;
    },
    notificationsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.status = RequestStatus.FAILURE;
    },
    trackAllNotificationsAsViewed(state) {
      state.trackNotificationsStatus = RequestStatus.LOADING;
      state.trackNotificationsStatusError = null;
    },
    trackedAllNotificationsAsViewed(state) {
      state.trackNotificationsStatus = RequestStatus.SUCCESS;
    },
    trackAllNotificationsAsViewedError(state, action: PayloadAction<string>) {
      state.trackNotificationsStatus = RequestStatus.FAILURE;
      state.trackNotificationsStatusError = action.payload;
    },
    setActiveProductUpdateInWindow(state, action: PayloadAction<ProductUpdateType>) {
      state.activeProductUpdateInWindow = action.payload;
    },
    setScrollProductUpdateState(state, action: PayloadAction<ProductUpdateState | null>) {
      state.scrollProductUpdateState = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = notificationsListSlice;
