export enum GeoProvince {
  ALBERTA_BASIN = 'ALBERTA BASIN',
  ANADARKO_BASIN = 'ANADARKO BASIN',
  APPALACHIAN = 'APPALACHIAN BASIN',
  ARKLA_BASIN = 'ARKLA BASIN',
  BOW_ISLAND_ARCH = 'BOW ISLAND ARCH',
  DENVER_BASIN = 'DENVER BASIN',
  EAST_TEXAS_BASIN = 'EAST TEXAS BASIN',
  GULF_COAST_BASIN = 'GULF COAST BASIN',
  PERMIAN_BASIN = 'PERMIAN BASIN',
  POWDER_RIVER_BASIN = 'POWDER RIVER BASIN',
  WILLISTON_BASIN = 'WILLISTON BASIN',
}
