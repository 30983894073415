import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';
import { MapWidgetId } from 'types/Widget';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.mapWidgetsVegaLayers || initialState;
const selectDomainByWidgetId = createSelector(
  [selectDomain, (state: RootState, mapWidgetId: MapWidgetId) => mapWidgetId],
  (state, mapWidgetId) => state[mapWidgetId],
);

export const selectVegaMapLayer = createSelector([selectDomainByWidgetId], (state) => state.layer);
export const selectVegaMapTable = createSelector([selectDomainByWidgetId], (state) => state.table);
export const selectVegaLayer = createSelector([selectDomainByWidgetId], (state) => state.mapLayer);
export const selectVegaMapLayerStatus = createSelector([selectDomainByWidgetId], (state) => state.status);
export const selectIsVegaMapLayerLoading = createSelector(
  [selectVegaMapLayerStatus],
  (status) => status === RequestStatus.LOADING,
);
export const selectVegaMapLayerError = createSelector([selectDomainByWidgetId], (state) => state.error);
