export const ERROR_WELL_LINE_COLOR = '#e62a15';
export const wellsColors = [
  '#ff8c00',
  '#ffff00',
  '#00ff00',
  '#800000',
  '#008000',
  '#000080',
  '#00ffff',
  '#0000ff',
  '#ff00ff',
  '#eee8aa',
  '#dda0dd',
  '#8b4513',
  '#ff981a',
  '#ffff1a',
  '#1aff1a',
  '#a60000',
  '#00a600',
  '#0000a6',
  '#1affff',
  '#1a1aff',
  '#ff1aff',
  '#f0eab3',
  '#e0a9e0',
  '#aa5417',
];
