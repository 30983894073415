import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const { fontWeightRegular } = theme.typography;

  return createStyles({
    contactItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      cursor: 'default',
      '&:hover': {
        background: 'none',
      },
    },
    info: {
      color: `${type === ThemeMode.DARK ? colors.baseGrey10 : colors.baseGrey80}!important`,
      fontWeight: fontWeightRegular as number,
    },
    link: {
      textDecoration: 'none!important',
      '&:hover': {
        color: `${type === ThemeMode.DARK ? colors.baseGrey10 : colors.primary}!important`,
      },
    },
    region: {
      color: type === ThemeMode.DARK ? colors.baseGrey20 : colors.baseGrey80,
    },
  });
});
