import turfBearing from '@turf/bearing';
import turfDestination from '@turf/destination';
import { Feature } from 'geojson';
import i18next from 'i18next';
import { CircleLayer, GeoJSONSource, GeoJSONSourceRaw, LineLayer, LngLat, Map } from 'mapbox-gl';

import {
  featureCollection as turfFeatureCollection,
  lineString as turfLineString,
  point as turfPoint,
} from '@turf/helpers';
import { ERROR_WELL_LINE_COLOR } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/constants';
import {
  PlannerWellSettingsState,
  WellPointSettings,
  WellPointType,
  WellSettings,
} from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/types';
import { translations } from 'locales/i18n';

export function createDefaultWellSettingsItem(wellId: number, firstPointLngLat: LngLat, color: string): WellSettings {
  return {
    id: wellId,
    name: i18next.t(translations.WIDGET.MAP.WELL.PLANNED_WELL_NAME, { wellId }),
    color,
    hidden: false,
    startPoint: { lat: firstPointLngLat.lat, lng: firstPointLngLat.lng },
    endPoint: null,
    activePointType: null,
    tempLengthLimitPoint: null,
  };
}

export const getWellSourceId = (wellId: number) => `well_${wellId}`;
export const getWellPointsLayerId = (wellId: number) => `well_${wellId}_points`;
export const getWellLineLayerId = (wellId: number) => `well_${wellId}_line`;

export const createWellPointsLayer = (wellId: number): CircleLayer => ({
  id: getWellPointsLayerId(wellId),
  type: 'circle',
  source: getWellSourceId(wellId),
  layout: { visibility: 'visible' },
  paint: {
    'circle-radius': 4,
    'circle-color': ['get', 'color'],
    'circle-opacity': ['get', 'circle-opacity'],
  },
  filter: ['==', '$type', 'Point'],
});

export const createWellLineLayer = (wellId: number): LineLayer => ({
  id: getWellLineLayerId(wellId),
  type: 'line',
  source: getWellSourceId(wellId),
  layout: {
    visibility: 'visible',
    'line-cap': 'round',
    'line-join': 'round',
    'line-sort-key': -2,
  },
  paint: {
    'line-color': ['get', 'color'],
    'line-width': 4,
    'line-opacity': ['get', 'line-opacity'],
  },
  filter: ['==', '$type', 'LineString'],
});

export function getNewWellBoundaryPointsData(
  data: WellSettings,
  newStartLngLat: LngLat,
  newEndLngLat: LngLat,
): WellSettings {
  return {
    ...data,
    startPoint: { lat: newStartLngLat.lat, lng: newStartLngLat.lng },
    endPoint: { lat: newEndLngLat.lat, lng: newEndLngLat.lng },
  };
}

export function getWellSource(
  wellId: number,
  wellData: WellSettings,
  wellsState: PlannerWellSettingsState,
  activePointType?: WellPointType,
): GeoJSONSourceRaw {
  const featureCollection = [] as Feature[];
  const isActive = wellId === wellsState.currentWellId;
  const pointColor = wellData.color;
  let lineColor = pointColor;
  const opacity = isActive ? 1 : 0.5;
  let initPointType = null;

  if (activePointType) {
    initPointType = getInitPointType(activePointType);
  }

  if (wellData.endPoint) {
    let distanceError = false;
    if (wellData.tempLengthLimitPoint) {
      featureCollection.push(
        createPointFeature(wellId, wellData.tempLengthLimitPoint, 'tempLengthLimitPoint', pointColor, opacity),
      );
      distanceError = true;
    }
    lineColor = distanceError ? ERROR_WELL_LINE_COLOR : pointColor;
    const secondPointColor = initPointType === 'endPoint' ? pointColor : lineColor;
    featureCollection.push(createPointFeature(wellId, wellData.endPoint, 'endPoint', secondPointColor, opacity));
    featureCollection.push(createLineFeature(wellId, wellData, lineColor, opacity));
  }

  if (wellData.startPoint) {
    const startPointColor = initPointType === 'startPoint' ? pointColor : lineColor;
    featureCollection.push(createPointFeature(wellId, wellData.startPoint, 'startPoint', startPointColor, opacity));
  }

  const newSource: GeoJSONSourceRaw = {
    type: 'geojson',
    data: turfFeatureCollection(featureCollection),
  };

  return newSource;
}

export const getSecondLatLngByDistance = (
  firstLatLng: WellPointSettings,
  secondLatLng: WellPointSettings,
  distance: number,
) => {
  const fromPoint = turfPoint([firstLatLng.lng, firstLatLng.lat]);
  const toPoint = turfPoint([secondLatLng.lng, secondLatLng.lat]);
  const bearing = turfBearing(fromPoint, toPoint);
  const destination = turfDestination(fromPoint, distance, bearing, { units: 'feet' });

  return [destination.geometry.coordinates[0], destination.geometry.coordinates[1]];
};

function createPointFeature(
  wellId: number,
  data: WellPointSettings,
  pointType: WellPointType,
  color: string,
  circleOpacity: number,
): Feature {
  return turfPoint([data.lng, data.lat], {
    wellId,
    pointType,
    color,
    'circle-opacity': circleOpacity,
  });
}

function createLineFeature(wellId: number, wellData: WellSettings, color: string, lineOpacity: number): Feature {
  let result = {} as Feature;

  if (wellData.endPoint) {
    result = turfLineString(
      [
        [wellData.startPoint.lng, wellData.startPoint.lat],
        [wellData.endPoint.lng, wellData.endPoint.lat],
      ],
      { wellId, color, 'line-opacity': lineOpacity },
    );
  }

  return result;
}

function getWellSourceData(
  wellId: number,
  wellData: WellSettings,
  wellsState: PlannerWellSettingsState,
  activePointType?: WellPointType,
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  return getWellSource(wellId, wellData, wellsState, activePointType)
    .data as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
}

export function updateMapSource(
  map: Map,
  wellId: number,
  newWellData: WellSettings,
  wellsState: PlannerWellSettingsState,
  activePointType?: WellPointType,
) {
  const source = map.getSource(getWellSourceId(wellId)) as GeoJSONSource;
  source.setData(getWellSourceData(wellId, newWellData, wellsState, activePointType));
}

function getInitPointType(type: WellPointType): WellPointType | null {
  let result = null;
  switch (type) {
    case 'startPoint':
      result = 'endPoint';
      break;
    case 'endPoint':
      result = 'startPoint';
      break;
  }

  return result as WellPointType;
}

export function getWellSettingsById(wellsSettings: WellSettings[], wellId: number) {
  return wellsSettings.find((element) => element.id === wellId) as WellSettings;
}

export function getWellSettingsArrayIndex(wellsSettings: WellSettings[], wellId: number) {
  return wellsSettings.findIndex((element) => element.id === wellId);
}

export function getWellSettingsWithMaxOrMinWellId(
  wellsSettings: WellSettings[],
  condition: 'max' | 'min',
): WellSettings | undefined {
  return wellsSettings.reduce((prevWellsSetting, currentWellsSetting) => {
    if (condition === 'max') {
      return prevWellsSetting.id > currentWellsSetting.id ? prevWellsSetting : currentWellsSetting;
    }

    return prevWellsSetting.id < currentWellsSetting.id ? prevWellsSetting : currentWellsSetting;
  }, wellsSettings[0]);
}

export function getMaxWellId(wellsSettings: WellSettings[]) {
  return wellsSettings.reduce(
    (prevMax: number | null, wellData) => (prevMax === null || wellData.id > prevMax ? wellData.id : prevMax),
    null,
  );
}
