export enum LogSource {
  UNKNOWN = 'Unknown',
  LOGIN_PAGE = 'Login page',
  LANDING_PAGE = 'Landing Page',
  LANDING_PAGE_CLOSE_BUTTON = 'Landing Page Close Button',
  LANDING_PAGE_SCENERIO_SELECTED = 'Landing Page Scenario Selected',
  LANDING_PAGE_GO_TO_PREDICT = 'Landing Page Go To Predict',
  LANDING_PAGE_ALL_SCENARIOS = 'Landing Page Show All Scenarios',
  PREDICT_PAGE_TAB = 'Predict page tab',
  PLAN_PAGE_TAB = 'Plan page tab',
  ECONOMICS_PAGE_TAB = 'Economics page tab',
  IMPACT_CURVES_SETTINGS = 'Impact Curves Settings',
  PRODUCTION_CURVES_SETTINGS = 'Production Curves Settings',
  PLAN_FILTER = 'Plan Filter',
  PREDICT_FILTER = 'Predict Filter',
  WELL_PARAMETERS = 'Well Parameters',
  PREDICTION_MAP_LAYER = 'Prediction Map Layer',
  PLAN_PRODUCTION_CURVES = 'Plan Production Curves',
  SCENARIO_ACTIONS = 'Scenario Actions',
  WELL_INFO_EXPORT = 'Well Info Export',
  PREDICT_PAGE_MAP_WIDGET = 'Predict Page Map Widget',
  MAP_WELLS = 'Map Wells',
  SHAPEFILE_DOWNLOAD = 'Shapefile Download',
  HELP_MENU = 'Help menu',
}
