import { TestLocators } from 'constants/Locators';

import { InputLabel } from '@material-ui/core';
import { ReactNode } from 'react';

import { useStyles } from './styles';

export interface LabeledElementProps {
  label: string;
  children: ReactNode;
  testId?: TestLocators | string;
}

export function LabeledElement({ label, testId, children }: LabeledElementProps) {
  const classes = useStyles();

  return (
    <div data-testid={testId}>
      <InputLabel>{label}</InputLabel>
      <div className={classes.itemContainer}>{children}</div>
    </div>
  );
}
