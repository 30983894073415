import { fetchProductUpdatesByCategory, trackAllProductUpdatesAsViewed } from 'api/updates';
import { fetchUserInfo } from 'api/user';
import axios, { AxiosResponse } from 'axios';
import { call, cancelled, put, takeLatest } from 'redux-saga/effects';

import { actions as privateRouteActions } from 'app/containers/PrivateRoute/slice';
import { ProductUpdate } from 'types/ProductUpdate';
import { UserInfo } from 'types/UserInfo';
import { getErrorMessage } from 'utils/error';

import { actions } from './slice';
import { getNotificationsList } from './utils';

function* getNotifications() {
  const cancelToken = axios.CancelToken.source();

  try {
    const { data: notifications }: AxiosResponse<ProductUpdate[]> = yield call(
      fetchProductUpdatesByCategory,
      cancelToken,
    );

    yield put(actions.notificationsLoaded(getNotificationsList(notifications)));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(actions.notificationsError(errorMessage));
  }
}

function* trackAllNotificationsAsViewed() {
  const cancelToken = axios.CancelToken.source();
  try {
    yield call(trackAllProductUpdatesAsViewed, cancelToken);
    yield put(actions.trackedAllNotificationsAsViewed());

    const entitlements: AxiosResponse<UserInfo> = yield call(fetchUserInfo);
    yield put(privateRouteActions.userInfoLoaded(entitlements.data));

    const { data: notifications }: AxiosResponse<ProductUpdate[]> = yield call(
      fetchProductUpdatesByCategory,
      cancelToken,
    );
    yield put(actions.notificationsLoaded(getNotificationsList(notifications)));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(actions.trackAllNotificationsAsViewedError(errorMessage));
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield call(cancelToken.cancel);
    }
  }
}

export function* notificationsSaga() {
  yield takeLatest(actions.loadNotifications.type, getNotifications);
  yield takeLatest(actions.trackAllNotificationsAsViewed.type, trackAllNotificationsAsViewed);
}
