export enum CurveColumnValue {
  ACTUAL = 'Actual',
  PRODUCING = 'Producing',
  COMPLETION = 'Completion',
  DRILLED = 'Drilled',
  PERMITTED = 'Permitted',
  FORECASTED = 'Forecasted',
}

export enum PredictionModelType {
  ACTUAL = 'actual',
  PRODUCING = 'producing',
  COMPLETION = 'completion',
  DRILLED = 'drilled',
  PERMITTED = 'permitted',
  FORECAST = 'forecast',
}

export const CurveToModelMap: Record<CurveColumnValue, PredictionModelType> = {
  [CurveColumnValue.ACTUAL]: PredictionModelType.ACTUAL,
  [CurveColumnValue.PRODUCING]: PredictionModelType.PRODUCING,
  [CurveColumnValue.COMPLETION]: PredictionModelType.COMPLETION,
  [CurveColumnValue.DRILLED]: PredictionModelType.DRILLED,
  [CurveColumnValue.PERMITTED]: PredictionModelType.PERMITTED,
  [CurveColumnValue.FORECASTED]: PredictionModelType.FORECAST,
};

export enum PlanModelType {
  PLANNED = 'planned',
}

export type PlanPredictModel = PredictionModelType | PlanModelType;
