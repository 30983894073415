import { ScenarioSettingsToLoadOnMap } from 'app/components/containers/layout/ConfigTabs/Scenario/types';
import { getWellBoundaryPointsFeetDistance } from 'utils/map';

import { wellsColors } from './constants';
import { PlannerWellSettingsState, WellSettings } from './types';

export function buildMapStateFromLoadingWellsData(settings: ScenarioSettingsToLoadOnMap): PlannerWellSettingsState {
  const itemsValue = settings.items ?? [];

  return {
    currentWellId: settings.activeWellId,
    hoveredWellId: null,
    movingWellId: null,
    removedWellId: null,
    nextWellId: 1,
    usedColorsIds: getUsedColorsIds(itemsValue),
    settings: itemsValue,
  };
}

function getUsedColorsIds(settings: WellSettings[]): number[] {
  const colorsIds: number[] = [];
  settings.forEach((element) => {
    const index = wellsColors.findIndex((color) => color === element.color);
    if (index !== -1) {
      colorsIds.push(index);
    }
  });

  return colorsIds;
}

export const getLatLngWellSurfaceHole = (wellSettings: WellSettings) => {
  if (wellSettings.tempLengthLimitPoint && wellSettings.activePointType === 'startPoint') {
    return [wellSettings.tempLengthLimitPoint.lat, wellSettings.tempLengthLimitPoint.lng];
  }

  return [wellSettings.startPoint.lat, wellSettings.startPoint.lng];
};

export const getValidWellPointsFeetDistance = (wellSettings: WellSettings) => {
  if (!wellSettings.endPoint) {
    return 0;
  }

  if (!wellSettings.tempLengthLimitPoint) {
    return getWellBoundaryPointsFeetDistance(wellSettings.startPoint, wellSettings.endPoint);
  }

  return getWellBoundaryPointsFeetDistance(
    wellSettings.activePointType === 'startPoint' ? wellSettings.tempLengthLimitPoint : wellSettings.startPoint,
    wellSettings.activePointType === 'endPoint' ? wellSettings.tempLengthLimitPoint : wellSettings.endPoint,
  );
};

function colorWithBounds(color: number) {
  return Math.max(Math.min(color, 0xff), 0);
}

export function adjustColor(color: string, offset = 5) {
  const [hash, ...hexColor] = color;

  if (hash !== '#' || hexColor.length !== 6) {
    throw new Error(`Invalid color ${color}`);
  }

  const numColor = parseInt(hexColor.join(''), 16);
  const red = colorWithBounds((numColor >> 16) + offset);
  const green = colorWithBounds(((numColor >> 8) & 0xff) + offset);
  const blue = colorWithBounds((numColor & 0xff) + offset);
  const outHexValue = (red << 16) | (green << 8) | blue;

  return `#${outHexValue.toString(16).padStart(6, '0')}`;
}

function getColorsWithOffset(colors: string[], offset: number) {
  return colors.map((color) => adjustColor(color, offset));
}

export function getNextColor(availableColors: string[], usedColors: string[], offset = 5): string {
  const firstAvailable = availableColors.find((color) => !usedColors.includes(color));

  return firstAvailable ?? getNextColor(getColorsWithOffset(availableColors, offset), usedColors, offset);
}

export function getNextWellColor(settings: WellSettings[]) {
  const usedColors = [...new Set(settings.map(({ color }) => color))];

  return getNextColor(wellsColors, usedColors);
}
