import { createSelector } from '@reduxjs/toolkit';

import {
  AxisIntervalBorderNameType,
  AxisNameType,
} from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { selectPlanProdCurvesSettingsDomain } from 'app/components/containers/layout/ConfigTabs/PlannerControl/ProductionCurvesSettings/selectors';
import { RootState } from 'types/RootState';

export const selectAxisIntervalBorderValue = createSelector(
  [
    selectPlanProdCurvesSettingsDomain,
    (state: RootState, axisName: AxisNameType) => axisName,
    (state: RootState, axisName: AxisNameType, borderName: AxisIntervalBorderNameType) => borderName,
  ],
  (state, axisName, borderName) => state.axis[axisName][borderName],
);

export const selectXAxisMinValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.X, AxisIntervalBorderNameType.MIN)],
  (min) => min,
);
export const selectXAxisMaxValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.X, AxisIntervalBorderNameType.MAX)],
  (max) => max,
);

export const selectYAxisMinValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.Y, AxisIntervalBorderNameType.MIN)],
  (min) => min,
);
export const selectYAxisMaxValue = createSelector(
  [(state: RootState) => selectAxisIntervalBorderValue(state, AxisNameType.Y, AxisIntervalBorderNameType.MAX)],
  (max) => max,
);

export const selectIsAnyAxisBorderSet = createSelector(
  [selectXAxisMinValue, selectXAxisMaxValue, selectYAxisMinValue, selectYAxisMaxValue],
  (xMin, xMax, yMin, yMax) => xMin !== null || xMax !== null || yMin !== null || yMax !== null,
);

export const selectLabelsValue = createSelector([selectPlanProdCurvesSettingsDomain], (state) => state.labels);
export const selectGridlinesValue = createSelector([selectPlanProdCurvesSettingsDomain], (state) => state.gridlines);
export const selectTimeSelectionValue = createSelector(
  [selectPlanProdCurvesSettingsDomain],
  (state) => state.timeSelection,
);
