import { RequestStatus } from 'constants/RequestStatus';

import { PlanWellParameters, PlanWellParametersState } from './types';

const wellParametersCurrentInitState: PlanWellParameters = {
  lateralLength: null,
  reservoir: null,
  playDesignation: null,
  proppantPerFt: null,
  proppantPerFluidRatio: null,
  nearestSameReservoirNeighborSpacing: null,
};

export const getPlanWellParametersInitialState = (): PlanWellParametersState => ({
  status: RequestStatus.IDLE,
  error: null,
  updateParameterOptionsStatus: RequestStatus.IDLE,
  updateParameterOptionsError: null,
  currentPlay: null,
  options: {
    playDesignation: [],
    reservoir: [],
    lateralLength: null,
    proppantPerFt: null,
    proppantPerFluidRatio: null,
    nearestSameReservoirNeighborSpacing: null,
  },
  current: wellParametersCurrentInitState,
  default: wellParametersCurrentInitState,
  wells: {},
});
