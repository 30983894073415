import { withStyles } from '@material-ui/core/styles';

export const overrideStyles = withStyles(() => {
  return {
    root: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:last-child)': {
        marginBottom: 16,
      },
    },
  };
});
