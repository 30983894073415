import { CURVE_COLOR } from 'constants/Curves';
import { RequestStatus } from 'constants/RequestStatus';

import { createSelector } from '@reduxjs/toolkit';
import { PlanPredictCurvesRequestPayload, PlanPredictCurvesResponsePayload } from 'api/curves';

import {
  selectXAxisMaxValue,
  selectXAxisMinValue,
  selectYAxisMinValue,
} from 'app/components/containers/layout/ConfigTabs/PlannerControl/ProductionCurvesOptions/selectors';
import {
  selectAggregateProductionValue,
  selectCurveSelectionItemValue,
  selectCurveSelectionValues,
  selectPredictionModelsValues,
} from 'app/components/containers/layout/ConfigTabs/PlannerControl/ProductionCurvesSettings/selectors';
import { selectAllPlannedWellParams } from 'app/components/containers/layout/ConfigTabs/PlannerFilter/WellParametersSection/selectors';
import { MONTH_NUMBER_DATA_KEY } from 'app/components/containers/widgets/ProductionCurves/constants';
import {
  getChartLineColorByProdKey,
  getChartXAxisMaxValue,
  getChartYAxisMaxValue,
  getVisibleChartData,
} from 'app/components/containers/widgets/ProductionCurves/utils';
import {
  selectReadyToPlanWellIds,
  selectVisibleWellIds,
} from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/selectors';
import { CurveType } from 'types/Curve';
import { PlanModelType } from 'types/PlanPredictModel';
import { RootState } from 'types/RootState';

import { initialState } from './slice';
import { PLAN_CURVE_CHART_KEY, PLAN_CURVE_DATA_KEY, PlanProdChartItem, PlanProdCurvesWidgetState } from './types';
import {
  generatePlanChartItemByDataKey,
  generatePlanChartKeys,
  getPCurveByDataKey,
  getPlannedWellDataKey,
} from './utils';

const selectPlanProdCurvesWidgetDomain = (state: RootState) => state?.planProdCurvesWidget || initialState;

const selectVisiblePlanProdCurvesWidgetDomain = createSelector(
  [selectPlanProdCurvesWidgetDomain, selectVisibleWellIds],
  (widgetState, visibleWellIds) => {
    return Object.keys(widgetState).reduce<PlanProdCurvesWidgetState>((acc, wellIdString) => {
      const wellId = Number(wellIdString);
      if (visibleWellIds.includes(wellId)) {
        acc[wellId] = widgetState[wellId];
      }

      return acc;
    }, {});
  },
);

export const selectIsAggregateProductionAvailable = createSelector(
  [selectVisiblePlanProdCurvesWidgetDomain],
  (widgetState) =>
    Object.keys(widgetState).filter(
      (wellId) => widgetState[wellId as unknown as number].status === RequestStatus.SUCCESS,
    ).length > 1,
);

export const selectRequestPayloadsToLoadPredictedCurves = createSelector(
  [selectPlanProdCurvesWidgetDomain, selectReadyToPlanWellIds, selectAllPlannedWellParams],
  (widgetState, plannedWellIds, plannedWellParams) => {
    return plannedWellIds
      .filter((id) => {
        return widgetState[id]
          ? getPlannedWellDataKey(plannedWellParams[id]) !== widgetState[id].dataKey
          : Boolean(plannedWellParams[id]);
      })
      .map((id) => {
        return {
          wellId: id,
          ...plannedWellParams[id],
        } as PlanPredictCurvesRequestPayload & { wellId: number };
      });
  },
);

export const selectIsAggregateProductionEnabled = createSelector(
  [selectIsAggregateProductionAvailable, selectAggregateProductionValue],
  (isAggregateProductionAvailable, isAggregateProductionChecked) =>
    isAggregateProductionAvailable && isAggregateProductionChecked,
);

const selectIndividualChartData = createSelector([selectVisiblePlanProdCurvesWidgetDomain], (widgetState) => {
  return Object.keys(widgetState).reduce((acc, wellId) => {
    const wellChartData = widgetState[wellId as unknown as number].data?.map((wellDataItem) =>
      generatePlanChartItemByDataKey(wellDataItem, wellId as unknown as number),
    );

    return wellChartData
      ? wellChartData.map((chartData, index) => ({
          ...chartData,
          ...acc[index],
        }))
      : acc;
  }, [] as PlanProdChartItem[]);
});

const selectAggregatedChartData = createSelector([selectVisiblePlanProdCurvesWidgetDomain], (widgetState) => {
  return Object.keys(widgetState).reduce((acc, wellId) => {
    widgetState[wellId as unknown as number].data?.forEach((wellDataItem, index) => {
      Object.keys(wellDataItem).forEach((wellDataItemKey) => {
        const value = wellDataItem[wellDataItemKey as PLAN_CURVE_DATA_KEY];
        if (wellDataItemKey !== MONTH_NUMBER_DATA_KEY) {
          acc[index] = {
            ...acc[index],
            [wellDataItemKey]:
              value === null
                ? acc[index]?.[wellDataItemKey as PLAN_CURVE_DATA_KEY] ?? value
                : (acc[index]?.[wellDataItemKey as PLAN_CURVE_DATA_KEY] ?? 0) + value,
          };
        } else {
          acc[index] = {
            ...acc[index],
            [wellDataItemKey as PLAN_CURVE_DATA_KEY]: value,
          };
        }
      });

      return wellDataItem;
    });

    return acc;
  }, [] as PlanProdChartItem[]);
});

export const selectChartData = createSelector(
  [selectIsAggregateProductionEnabled, selectIndividualChartData, selectAggregatedChartData],
  (isAggregateProductionEnabled, individualChartData, aggregatedChartData) =>
    isAggregateProductionEnabled ? aggregatedChartData : individualChartData,
);

export const selectIsChartDataLoading = createSelector([selectPlanProdCurvesWidgetDomain], (widgetState) => {
  return Object.keys(widgetState).some(
    (wellId) => widgetState[wellId as unknown as number].status === RequestStatus.LOADING,
  );
});

export const selectIsChartDataLoaded = createSelector([selectPlanProdCurvesWidgetDomain], (widgetState) => {
  const wellIds = Object.keys(widgetState);

  return (
    wellIds.every((wellId) => widgetState[wellId as unknown as number].status !== RequestStatus.LOADING) &&
    wellIds.length > 0
  );
});

export const selectPlanProdCurvesError = createSelector([selectPlanProdCurvesWidgetDomain], (widgetState) => {
  const widgetIdWithError = Object.keys(widgetState).find((wellId) => widgetState[wellId as unknown as number].error);

  return widgetIdWithError ? widgetState[widgetIdWithError as unknown as number].error : null;
});

export const selectVisibleLegendItems = createSelector(
  [
    (state: RootState) => selectCurveSelectionItemValue(state, CurveType.LIQUID),
    (state: RootState) => selectCurveSelectionItemValue(state, CurveType.GAS),
    (state: RootState) => selectCurveSelectionItemValue(state, CurveType.WATER),
  ],
  (liquidCurveEnabled, gasCurveEnabled, waterCurveEnabled) =>
    [
      liquidCurveEnabled && { label: 'PREDICTED_LIQUID', isSolidLine: true, color: CURVE_COLOR.liquid },
      gasCurveEnabled && { label: 'PREDICTED_GAS', isSolidLine: true, color: CURVE_COLOR.gas },
      waterCurveEnabled && { label: 'PREDICTED_WATER', isSolidLine: true, color: CURVE_COLOR.water },
    ].filter(Boolean) as {
      label: string;
      isSolidLine: boolean;
      color: (typeof CURVE_COLOR)[keyof typeof CURVE_COLOR];
    }[],
);

const selectSelectedProdCurvesDataKeysWithWellIds = createSelector(
  [selectCurveSelectionValues, selectPredictionModelsValues, selectReadyToPlanWellIds],
  (curveSelectionValues, predictionModelsValues, wellIds) => {
    const selectedCurves = Object.keys(curveSelectionValues).filter(
      (curveType) => curveSelectionValues[curveType as CurveType],
    ) as CurveType[];
    const selectedModels = Object.keys(predictionModelsValues) as PlanModelType[];

    const curvesDataKeys = wellIds.reduce((acc, wellId) => {
      return [
        ...acc,
        ...selectedCurves.reduce((acc, curve) => {
          return [
            ...acc,
            ...selectedModels.reduce((acc, model) => {
              const { general, errors } = predictionModelsValues[model];

              return [...acc, ...generatePlanChartKeys(curve, model, wellId, general, errors)];
            }, [] as PLAN_CURVE_CHART_KEY[]),
          ];
        }, [] as PLAN_CURVE_CHART_KEY[]),
      ];
    }, [] as PLAN_CURVE_CHART_KEY[]);

    return curvesDataKeys;
  },
);

export const selectSelectedProdCurvesDataKeys = createSelector(
  [selectCurveSelectionValues, selectPredictionModelsValues],
  (curveSelectionValues, predictionModelsValues) => {
    const selectedCurves = Object.keys(curveSelectionValues).filter(
      (curveType) => curveSelectionValues[curveType as CurveType],
    ) as CurveType[];
    const selectedModels = Object.keys(predictionModelsValues) as PlanModelType[];

    const curvesDataKeys = selectedCurves.reduce((acc, curve) => {
      return [
        ...acc,
        ...selectedModels.reduce((acc, model) => {
          const { general, errors } = predictionModelsValues[model];

          return [...acc, ...generatePlanChartKeys(curve, model, null, general, errors)];
        }, [] as PLAN_CURVE_CHART_KEY[]),
      ];
    }, [] as PLAN_CURVE_CHART_KEY[]);

    return curvesDataKeys;
  },
);

const selectSelectedAggregatedProdCurvesDataKeys = createSelector(
  [selectCurveSelectionValues, selectPredictionModelsValues],
  (curveSelectionValues, predictionModelsValues) => {
    const selectedCurves = Object.keys(curveSelectionValues).filter(
      (curveType) => curveSelectionValues[curveType as CurveType],
    ) as CurveType[];
    const selectedModels = Object.keys(predictionModelsValues) as PlanModelType[];

    const curvesDataKeys = selectedCurves.reduce((acc, curve) => {
      return [
        ...acc,
        ...selectedModels.reduce((acc, model) => {
          const { general, errors } = predictionModelsValues[model];

          return [...acc, ...generatePlanChartKeys(curve, model, null, general, errors)];
        }, [] as PLAN_CURVE_CHART_KEY[]),
      ];
    }, [] as PLAN_CURVE_CHART_KEY[]);

    return curvesDataKeys;
  },
);

export const selectLinesProps = createSelector(
  [
    selectIsAggregateProductionEnabled,
    selectSelectedProdCurvesDataKeysWithWellIds,
    selectSelectedAggregatedProdCurvesDataKeys,
  ],
  (isAggregateProductionEnabled, selectedProdKeys, selectedAggregatedProdKeys) => {
    return (isAggregateProductionEnabled ? selectedAggregatedProdKeys : selectedProdKeys).map(
      (key: PLAN_CURVE_CHART_KEY) => ({
        dataKey: key,
        stroke: getChartLineColorByProdKey(key),
        strokeDasharray: getPCurveByDataKey(key) ? '4' : undefined,
      }),
    );
  },
);

export const selectVisibleChartData = createSelector(
  [
    selectChartData,
    selectIsAggregateProductionEnabled,
    selectSelectedProdCurvesDataKeysWithWellIds,
    selectSelectedAggregatedProdCurvesDataKeys,
    selectXAxisMinValue,
    selectXAxisMaxValue,
    selectYAxisMinValue,
  ],
  (data, isAggregateProductionEnabled, selectedProdKeys, selectedAggregatedProdKeys, minX, maxX, valueMin) => {
    return getVisibleChartData(
      data,
      isAggregateProductionEnabled ? selectedAggregatedProdKeys : selectedProdKeys,
      minX,
      maxX,
      valueMin,
    ).visibleData;
  },
);

export const selectIsChartDataEmpty = createSelector([selectChartData], (chartData) => {
  return !chartData.length;
});
export const selectIsVisibleCurvesEmpty = createSelector([selectLinesProps], (lineProps) => {
  return !lineProps.length;
});

export const selectMaxChartDataMonth = createSelector([selectChartData], (chartData) => {
  return getChartXAxisMaxValue(chartData);
});

export const selectMaxChartDataValue = createSelector([selectChartData], (chartData) => {
  const maxValue = getChartYAxisMaxValue(chartData);

  return Math.ceil(maxValue);
});

export const selectPlanProdCurvesRawData = createSelector([selectVisiblePlanProdCurvesWidgetDomain], (curvesData) => {
  const rawData: Record<string, PlanPredictCurvesResponsePayload[]> = {};

  for (const [wellId, value] of Object.entries(curvesData)) {
    if (value.status === RequestStatus.SUCCESS) {
      rawData[wellId] = value.rawData;
    }
  }

  return rawData;
});
