import { PayloadAction } from '@reduxjs/toolkit';

import {
  AxisIntervalActionPayloadType,
  AxisIntervalBorderNameType,
  AxisNameType,
} from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { CurveSelectionActionPayloadType } from 'app/components/containers/layout/ConfigTabs/controls/CurveSelection/types';
import { PredictionModelsActionPayloadType } from 'app/components/containers/layout/ConfigTabs/controls/PredictionModels/types';
import { PredictionModelType } from 'types/PlanPredictModel';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { getInitialState } from './config';

export const initialState = getInitialState();

const predictProdCurvesSettingsSlice = createSlice({
  name: 'predictProdCurvesSettings',
  initialState,
  reducers: {
    changeCurveSelectionItemValue(state, { payload }: PayloadAction<CurveSelectionActionPayloadType>) {
      state.curveSelection[payload.name] = payload.value;
    },
    changeActualProductionValue(state, { payload: enabled }: PayloadAction<boolean>) {
      state.actualProduction = enabled;
    },
    changeCumulativeProductionValue(state, { payload: enabled }: PayloadAction<boolean>) {
      state.cumulativeProduction = enabled;
    },
    changePredictionModelsItemGeneralValue(state, { payload }: PayloadAction<PredictionModelsActionPayloadType>) {
      state.predictionModels[payload.name]['general'] = payload.value;
    },
    changePredictionModelsItemErrorsValue(state, { payload }: PayloadAction<PredictionModelsActionPayloadType>) {
      state.predictionModels[payload.name]['errors'] = payload.value;
    },
    changeAutoDeclineItemGeneralValue(state, { payload }: PayloadAction<PredictionModelsActionPayloadType>) {
      state.autoDecline[payload.name]['general'] = payload.value;
    },
    turnOffErrorsSelection(state) {
      const modelsSettings = state.predictionModels;
      Object.keys(modelsSettings).map((modelKey) => (modelsSettings[modelKey as PredictionModelType].errors = false));
    },
    turnOffNotAllowedSelections(
      state,
      { payload: { allowedModels } }: PayloadAction<{ allowedModels: PredictionModelType[] }>,
    ) {
      const modelsSettings = state.predictionModels;
      Object.keys(modelsSettings).forEach((modelKey) => {
        if (!allowedModels.includes(modelKey as PredictionModelType)) {
          modelsSettings[modelKey as PredictionModelType].general = false;
          modelsSettings[modelKey as PredictionModelType].errors = false;
        }
      });
    },
    changeAxisIntervalValue(state, { payload }: PayloadAction<AxisIntervalActionPayloadType>) {
      state.axis[payload.axisName][payload.borderName] = payload.value;
    },
    resetAxisIntervalValues(state, { payload }: PayloadAction<{ axisName: AxisNameType }>) {
      state.axis[payload.axisName][AxisIntervalBorderNameType.MIN] = null;
      state.axis[payload.axisName][AxisIntervalBorderNameType.MAX] = null;
    },
    resetAllAxisIntervalsValues(state) {
      state.axis[AxisNameType.X][AxisIntervalBorderNameType.MIN] = null;
      state.axis[AxisNameType.X][AxisIntervalBorderNameType.MAX] = null;
      state.axis[AxisNameType.Y][AxisIntervalBorderNameType.MIN] = null;
      state.axis[AxisNameType.Y][AxisIntervalBorderNameType.MAX] = null;
    },
    changeLabelsValue(state, { payload: enabled }: PayloadAction<boolean>) {
      state.labels = enabled;
    },
    changeGridlines(state, { payload: enabled }: PayloadAction<boolean>) {
      state.gridlines = enabled;
    },
  },
});

export const { actions, reducer, name: sliceKey } = predictProdCurvesSettingsSlice;
