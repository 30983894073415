import { createSelector } from '@reduxjs/toolkit';

import { selectVisibleWellsSettings } from 'app/components/pages/WellPlannerPage/PlanMapWidget/WellControl/selectors';
import { CurveType } from 'types/Curve';
import { RootState } from 'types/RootState';

import { initialState } from './slice';

export const selectPlanProdCurvesSettingsDomain = (state: RootState) => state?.planProdCurvesSettings || initialState;

export const selectCurveSelectionValues = createSelector(
  [selectPlanProdCurvesSettingsDomain],
  (state) => state.curveSelection,
);

export const selectCurveSelectionItemValue = createSelector(
  [selectCurveSelectionValues, (state: RootState, id: CurveType) => id],
  (curveSelectionValues, id) => curveSelectionValues[id],
);

export const selectCumulativeProductionValue = createSelector(
  [selectPlanProdCurvesSettingsDomain],
  (state) => state.cumulativeProduction,
);

const AGGREGATE_WELLS_LIMIT = 10;

export const selectIsAggregateWellsReached = createSelector(
  [selectVisibleWellsSettings],
  (visibleWellsSettings) => visibleWellsSettings.length > AGGREGATE_WELLS_LIMIT,
);

export const selectAggregateProductionValue = createSelector(
  [selectPlanProdCurvesSettingsDomain, selectIsAggregateWellsReached],
  (state, isAggregateWellsReached) => state.aggregateProduction || isAggregateWellsReached,
);

export const selectPredictionModelsValues = createSelector(
  [selectPlanProdCurvesSettingsDomain],
  (state) => state.predictionModels,
);
