import { RequestStatus } from 'constants/RequestStatus';

import { ColumnType } from 'types/ColumnType';
import { PostgreDataType } from 'types/PostgreDataType';
import { MapWidgetId } from 'types/Widget';
import { isSomeEnum } from 'utils/types/enums-check';

export enum MapLayer {
  WELLS = 'WELLS',
  WELL_PATH = 'WELL_PATH',
  PREDICTION = 'PREDICTION',
  ACREAGE = 'ACREAGE',
  MIDSTREAM = 'MIDSTREAM',
  ISOPACH = 'ISOPACH',
  THERMAL_MATURITY = 'THERMAL_MATURITY',
  SUBSURFACE_STRUCTURE = 'SUBSURFACE_STRUCTURE',
  SUBSURFACE_FAULTS = 'SUBSURFACE_FAULTS',
  COMPANY_ACREAGE = 'COMPANY_ACREAGE',
}

export const isMapLayer = isSomeEnum(MapLayer);

export enum MapLayerGroup {
  WELL_AND_PRODUCTION = 'WELL_AND_PRODUCTION',
}

export interface MapLayerConfig {
  groupId: MapLayerGroup;
  layerIds: MapLayer[];
}

export interface Column {
  name: ColumnType;
  type: PostgreDataType;
}

export interface MapColorBy {
  field: ColumnType;
  domain: string[] | number[] | null;
  range: string[] | null;
}

export interface MapLayerSettings {
  id: MapLayer;
  opacity: number;
  colorByValue: MapColorBy | null;
  size: number;
}

export interface MapLayerSettingsState {
  layers: MapLayerSettings[];
  removedLayer: string | null;
  domainStatus: RequestStatus;
  domainError: unknown;
}

export type MapWidgetsLayerSettingsState = Record<MapWidgetId, MapLayerSettingsState>;

export type FiledTypeSettings = Partial<Record<ColumnType, PostgreDataType>>;

export type TPlayDesignationFilter = {
  name: string | null;
  value: string | null;
};
