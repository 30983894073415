import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { warning } = theme.palette;

  return createStyles({
    confirmButton: {
      color: colors.grey60,
      backgroundColor: warning.main,
      '&:hover': {
        backgroundColor: warning.dark,
      },
    },
    button: {
      padding: 8,
      fontSize: 14,
    },
  });
});
