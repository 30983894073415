import { TestLocators } from 'constants/Locators';

import { Button } from '@material-ui/core';
import cn from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

import { AlertDialogWithAnyActions } from '../AlertDialogWithAnyActions';
import { useStyles } from './styles';

export interface AlertDialogProps {
  isOpen: boolean;
  title: string;
  messages: string[] | null;
  onClose(): void;
  onConfirm?(): void;
  testId?: TestLocators;
  buttonTestId?: TestLocators;
  confirmButtonText?: string;
  cancelButtonText?: string;
  dialogTestId?: TestLocators;
  optionalBodyContent?: string[];
}
export function AlertDialog({
  isOpen,
  onClose,
  title,
  messages = [],
  testId,
  buttonTestId,
  confirmButtonText,
  cancelButtonText,
  dialogTestId,
  onConfirm,
  optionalBodyContent,
}: AlertDialogProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const confirmButtonTextValue = confirmButtonText ?? t(translations.COMMON.OK);
  const handleConfirm = onConfirm ?? onClose;
  const useConfirmAndCancelButtons = Boolean(cancelButtonText);

  const buttons = useMemo(() => {
    return (
      <>
        <Button
          autoFocus
          onClick={handleConfirm}
          variant="contained"
          size="small"
          className={cn(classes.button, classes.confirmButton)}
          data-testid={buttonTestId}
        >
          {confirmButtonTextValue}
        </Button>
        {useConfirmAndCancelButtons && (
          <Button
            autoFocus
            onClick={onClose}
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
          >
            {cancelButtonText}
          </Button>
        )}
      </>
    );
  }, [
    useConfirmAndCancelButtons,
    onClose,
    buttonTestId,
    classes.button,
    classes.confirmButton,
    cancelButtonText,
    handleConfirm,
    confirmButtonTextValue,
  ]);

  return (
    <AlertDialogWithAnyActions
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      messages={messages}
      buttonsContent={buttons}
      testId={testId}
      dialogTestId={dialogTestId}
      optionalBodyContent={optionalBodyContent}
    />
  );
}
