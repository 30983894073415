import { isSomeEnum } from 'utils/types/enums-check';

export enum FaultsColumn {
  BASIN = 'Basin',
  COUNTRY = 'Country',
  FORMATION = 'Formation',
  LAYER_TYPE = 'Layer_Type',
  WKT = 'WKT',
}

export const isFaultsColumn = isSomeEnum(FaultsColumn);
