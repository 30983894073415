import mapboxGl, { Map, MapboxOptions, Point } from 'mapbox-gl';

import { PIXEL_RATIO } from './constants';
import { MapLngLat } from './types';

export const createMap = (mapboxOptions: MapboxOptions) => {
  return new mapboxGl.Map({
    accessToken: process.env.REACT_APP_MAP_ACCESS_TOKEN,
    attributionControl: false,
    dragRotate: false,
    touchZoomRotate: false,
    logoPosition: 'bottom-right',
    renderWorldCopies: false,
    maxBounds: [
      [-169.15158761447327, 24.025214356341674],
      [-45.927757365045075, 83.95423512118245],
    ],
    minZoom: 1.25,
    ...mapboxOptions,
  })
    .addControl(
      new mapboxGl.AttributionControl({
        compact: true,
      }),
    )
    .addControl(new mapboxGl.NavigationControl({ showCompass: false }), 'bottom-right');
};

export const createMapMarker = ({ className }: { className: string }) => {
  const markerEl = document.createElement('div');
  markerEl.className = className;

  return new mapboxGl.Marker({
    element: markerEl,
  });
};

export const createMapTooltip = () => {
  return new mapboxGl.Popup({ offset: 12, closeButton: false, maxWidth: '400px' });
};

export function conv4326To900913(coordinates: MapLngLat) {
  const transCoordinates = [0.0, 0.0];
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  transCoordinates[0] = coordinates[0] * 111319.49077777777778;
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  transCoordinates[1] = Math.log(Math.tan((90.0 + coordinates[1]) * 0.00872664625997)) * 6378136.99911215736947;

  return transCoordinates;
}

// Find the index of the first symbol layer in the map style to show city names and other labels
export const getFirstSymbolLayer = (map: Map) => {
  const layers = map.getStyle().layers;

  return layers?.find((layer) => layer.type === 'symbol')?.id;
};

export const getMousePoint = (mapHeight: number, point: Point): MapLngLat => {
  return [Math.round(point.x * PIXEL_RATIO), Math.round((mapHeight - point.y) * PIXEL_RATIO)];
};
