import { TestLocators } from 'constants/Locators';

import { Menu, MenuProps, PaperProps } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { useStyles } from './styles';

export interface HeaderMenuProps extends PropsWithChildren<MenuProps> {
  paperTestId?: TestLocators;
}

export function HeaderMenu({ children, anchorEl, onClose, paperTestId, ...props }: HeaderMenuProps) {
  const classes = useStyles();

  return (
    <Menu
      {...props}
      classes={{ paper: classes.menuPaper }}
      anchorEl={anchorEl}
      keepMounted
      onClose={onClose}
      MenuListProps={{
        classes: { padding: classes.menuPadding },
      }}
      PaperProps={
        {
          'data-testid': paperTestId,
        } as Partial<PaperProps>
      }
      autoFocus={false}
    >
      {children}
    </Menu>
  );
}
