import { useParamSelector } from 'hooks/useParamSelector';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AlertDialog } from 'app/components/common/AlertDialog';
import { selectImpactCurvesError } from 'app/components/containers/widgets/ImpactCurves/selectors';
import { actions as impactCurvesActions } from 'app/components/containers/widgets/ImpactCurves/slice';
import { selectPredictMapLayerError } from 'app/components/containers/widgets/MapWidget/PredictionLayer/selectors';
import { actions as predictionLayerActions } from 'app/components/containers/widgets/MapWidget/PredictionLayer/slice';
import { selectVegaMapLayerError } from 'app/components/containers/widgets/MapWidget/VegaLayer/selectors';
import { actions as vegaLayerActions } from 'app/components/containers/widgets/MapWidget/VegaLayer/slice';
import { selectPlanProdCurvesError } from 'app/components/containers/widgets/ProductionCurves/PlanProdCurvesWidget/selectors';
import { actions as planCurvesActions } from 'app/components/containers/widgets/ProductionCurves/PlanProdCurvesWidget/slice';
import { selectPredictProdCurvesError } from 'app/components/containers/widgets/ProductionCurves/PredictProdCurvesWidget/selectors';
import { actions as prodCurvesActions } from 'app/components/containers/widgets/ProductionCurves/PredictProdCurvesWidget/slice';
import { selectWellInfoError } from 'app/components/containers/widgets/WellInfoWidget/selectors';
import { actions as wellInfoActions } from 'app/components/containers/widgets/WellInfoWidget/slice';
import { translations } from 'locales/i18n';
import { MapWidgetId } from 'types/Widget';

export interface ErrorHandlerProps {
  children: ReactElement;
}

enum ErrorSource {
  vegaMapLayer,
  wellInfo,
  predictProdCurves,
  planProdCurves,
  impactCurves,
  predictMapLayer,
}

const ERROR_TITLES: Record<ErrorSource, string> = {
  [ErrorSource.vegaMapLayer]: 'WIDGET.MAP.ERROR.VEGA_TITLE',
  [ErrorSource.predictMapLayer]: 'WIDGET.MAP.ERROR.PREDICT_TITLE',
  [ErrorSource.wellInfo]: 'WIDGET.WELL_INFO.ERROR.TITLE',
  [ErrorSource.predictProdCurves]: 'WIDGET.PRODUCTION_CURVES.ERROR.TITLE',
  [ErrorSource.planProdCurves]: 'WIDGET.PRODUCTION_CURVES.ERROR.TITLE',
  [ErrorSource.impactCurves]: 'IMPACT_CURVES.TITLE',
};

export function ErrorHandler({ children }: ErrorHandlerProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<{ source: ErrorSource; errorMessage: string } | null>(null);
  const mapPredictVegaLayerError = useParamSelector(selectVegaMapLayerError, MapWidgetId.PREDICT_MAP_WIDGET);
  const mapPlanVegaLayerError = useParamSelector(selectVegaMapLayerError, MapWidgetId.PLAN_MAP_WIDGET);
  const vegaLayerError = mapPredictVegaLayerError || mapPlanVegaLayerError;
  const predictMapLayerError = useParamSelector(selectPredictMapLayerError, MapWidgetId.PLAN_MAP_WIDGET);
  const wellInfoError = useSelector(selectWellInfoError);
  const predictProdCurvesError = useSelector(selectPredictProdCurvesError);
  const planProdCurvesError = useSelector(selectPlanProdCurvesError);
  const impactCurvesError = useSelector(selectImpactCurvesError);

  const handleAlertClose = useCallback(() => {
    if (error !== null) {
      switch (error.source) {
        case ErrorSource.vegaMapLayer:
          dispatch(vegaLayerActions.clearLayerError(MapWidgetId.PREDICT_MAP_WIDGET));
          dispatch(vegaLayerActions.clearLayerError(MapWidgetId.PLAN_MAP_WIDGET));
          break;
        case ErrorSource.wellInfo:
          dispatch(wellInfoActions.clearWellInfoError());
          break;
        case ErrorSource.predictProdCurves:
          dispatch(prodCurvesActions.clearProdCurvesError());
          break;
        case ErrorSource.planProdCurves:
          dispatch(planCurvesActions.clearProdCurvesError());
          break;
        case ErrorSource.impactCurves:
          dispatch(impactCurvesActions.clearImpactCurvesError());
          break;
        case ErrorSource.predictMapLayer:
          dispatch(predictionLayerActions.clearGeoDataError(MapWidgetId.PLAN_MAP_WIDGET));
          break;
      }
    }

    setError(null);
  }, [dispatch, error]);

  useEffect(() => {
    if (vegaLayerError) {
      setError({
        source: ErrorSource.vegaMapLayer,
        errorMessage: vegaLayerError,
      });
    } else if (wellInfoError) {
      setError({
        source: ErrorSource.wellInfo,
        errorMessage: wellInfoError,
      });
    } else if (predictProdCurvesError) {
      setError({
        source: ErrorSource.predictProdCurves,
        errorMessage: predictProdCurvesError,
      });
    } else if (planProdCurvesError) {
      setError({
        source: ErrorSource.planProdCurves,
        errorMessage: planProdCurvesError,
      });
    } else if (impactCurvesError) {
      setError({
        source: ErrorSource.impactCurves,
        errorMessage: impactCurvesError,
      });
    } else if (predictMapLayerError) {
      setError({
        source: ErrorSource.predictMapLayer,
        errorMessage: predictMapLayerError,
      });
    }
  }, [
    vegaLayerError,
    predictProdCurvesError,
    wellInfoError,
    impactCurvesError,
    planProdCurvesError,
    predictMapLayerError,
  ]);

  return (
    <>
      {children}
      <AlertDialog
        isOpen={error !== null}
        onClose={handleAlertClose}
        title={error ? t(ERROR_TITLES[error.source]) : t(translations.COMMON.UNKNOWN_ERROR)}
        messages={error ? [error.errorMessage] : []}
      />
    </>
  );
}
