export const host = window.location.hostname.split('.');

export enum EDomain {
  IHS = 'ihsenergy',
  SP = 'spglobal',
  FULL_IHS_DOMAIN = '.ihsenergy.com',
  FULL_SP_DOMAIN = '.spglobal.com',
}

export const domainName = () => {
  if (host.includes(EDomain.IHS)) return EDomain.FULL_IHS_DOMAIN;
  else return EDomain.FULL_SP_DOMAIN;
};
