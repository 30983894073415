import {
  AxisIntervalBorderNameType,
  AxisNameType,
} from 'app/components/containers/layout/ConfigTabs/controls/AxisMinMaxInterval/types';
import { PredictionModelsValue } from 'app/components/containers/layout/ConfigTabs/controls/PredictionModels/types';
import { CurveType } from 'types/Curve';
import { PredictionModelType } from 'types/PlanPredictModel';

import { PredictProdCurvesSettingsState } from './types';

const AVAILABLE_PREDICTION_MODELS = [
  PredictionModelType.PERMITTED,
  PredictionModelType.DRILLED,
  PredictionModelType.COMPLETION,
  PredictionModelType.PRODUCING,
];

export function getInitialState(): PredictProdCurvesSettingsState {
  return {
    curveSelection: {
      [CurveType.LIQUID]: true,
      [CurveType.GAS]: true,
      [CurveType.WATER]: true,
    },
    actualProduction: true,
    cumulativeProduction: true,
    predictionModels: AVAILABLE_PREDICTION_MODELS.reduce(
      (acc, model) => ({
        ...acc,
        [model]: {
          general: false,
          errors: false,
        },
      }),
      {} as PredictionModelsValue,
    ),
    autoDecline: {
      [PredictionModelType.FORECAST]: {
        general: false,
      },
    } as PredictionModelsValue,
    axis: {
      [AxisNameType.X]: {
        [AxisIntervalBorderNameType.MIN]: null,
        [AxisIntervalBorderNameType.MAX]: null,
      },
      [AxisNameType.Y]: {
        [AxisIntervalBorderNameType.MIN]: null,
        [AxisIntervalBorderNameType.MAX]: null,
      },
    },
    labels: true,
    gridlines: true,
  };
}
