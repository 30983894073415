export enum LogEvent {
  UNHANDLED_ERROR = 'unhandled_error',
  LOGIN = 'login',
  CLICK = 'click',
  DOWNLOAD = 'download',
  LOGOUT = 'logout',
}

export enum MapWellsEvent {
  ADD_WELL = 'add_well',
  REMOVE_WELL = 'remove_well',
}

export enum WellParametersEvent {
  CHANGE_BASIN = 'change_basin',
  CHANGE_LATERAL_LENGTH = 'change_well_lateral_length',
  CHANGE_PLAY_DESIGNATION = 'change_play_designation',
  CHANGE_PLAY = 'change_play',
  CHANGE_RESERVOIR = 'change_reservoir',
  CHANGE_PROPPANT_PER_FT = 'change_proppant_per_ft',
  CHANGE_PROPPANT_PER_FLUID_RATIO = 'change_proppant_per_fluid_ratio',
  CHANGE_NEAREST_SAME_RESERVOIR_NEIGHBOR_SPACING = 'change_nearest_same_reservoir_neighbor_spacing',
}

export enum ProductionCurvesSettingsEvent {
  SWITCH_CUMULATIVE_PRODUCTION = 'switch_cumulative_production',
  SWITCH_PREDICTION_MODEL = 'switch_prediction_model',
  SWITCH_PREDICTION_ERRORS_MODEL = 'switch_prediction_errors_model',
  SWITCH_AGGREGATE_PRODUCTION = 'switch_aggregate_production',
  SWITCH_CURVE_SELECTION = 'switch_curve_selection',
}

export enum PlanProductionCurvesEvent {
  LOAD_PLAN_PRODUCTION_CURVES = 'load_plan_production_curves',
}
export enum PredictionMapLayerEvent {
  LOAD_PREDICTION_MAP_LAYER = 'load_prediction_map_layer',
}

export enum ImpactCurvesSettingsEvent {
  CHANGE_TYPE = 'change_impact_curve_type',
  CHANGE_DURATION = 'change_impact_duration',
  SWITCH_PARAMETER_VALUE = 'switch_parameter_value',
}

export enum ScenarioLogEvent {
  CREATE = 'scenario_create',
  DELETE = 'scenario_delete',
  SHARE = 'scenario_share',
  COPY = 'scenario_copy',
  RENAME = 'scenario_rename',
  DOWNLOAD = 'scenario_download',
}

export enum PredictPageFilterEvent {
  FILTER_CHANGED = 'predict_filter_changed',
}

export enum PredictHeaderPanelActionEvent {
  DOWNLOAD = 'well_info_export',
}
export enum PredictMapWidgetActionEvent {
  UWI_SELECTED = 'uwi_selected',
}
