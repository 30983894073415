import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';
import { PlanPredictCurvesWithIdRequestPayload } from 'api/curves';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { PlanProdCurvesLoadedType, PlanProdCurvesWellState, PlanProdCurvesWidgetState } from './types';

const defaultPlanProdCurvesWellState: PlanProdCurvesWellState = {
  status: RequestStatus.LOADING,
  error: null,
  dataKey: null,
  data: null,
  rawData: null,
};

export const initialState: PlanProdCurvesWidgetState = {};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'planProdCurvesWidget',
  initialState,
  reducers: {
    loadPlansProdCurves(state, { payload }: PayloadAction<PlanPredictCurvesWithIdRequestPayload[]>) {
      payload.forEach(({ wellId, ...parameters }) => {
        if (state[wellId]) {
          state[wellId].status = defaultPlanProdCurvesWellState.status;
          state[wellId].error = defaultPlanProdCurvesWellState.error;
        } else {
          state[wellId] = { ...defaultPlanProdCurvesWellState };
        }
        state[wellId].dataKey = JSON.stringify(parameters);
      });
    },
    planProdCurvesLoaded(
      state,
      { payload: { wellsPayload, rawData, chartData } }: PayloadAction<PlanProdCurvesLoadedType>,
    ) {
      wellsPayload.forEach(({ wellId }) => {
        state[wellId].status = RequestStatus.SUCCESS;
        state[wellId].data = chartData;
        state[wellId].rawData = rawData.filter((item) => item.wellId === wellId);
      });
    },
    plansProdCurvesError(
      state,
      {
        payload: { wellsPayload, error },
      }: PayloadAction<{ wellsPayload: PlanPredictCurvesWithIdRequestPayload[]; error: string }>,
    ) {
      wellsPayload.forEach(({ wellId }) => {
        state[wellId].status = RequestStatus.FAILURE;
        state[wellId].error = error;
      });
    },
    clearProdCurvesError(state) {
      Object.keys(state).forEach((wellId) => {
        state[Number(wellId)].status = RequestStatus.IDLE;
        state[Number(wellId)].error = null;
      });
    },
    removePlanProdCurves(state, { payload: wellId }: PayloadAction<number>) {
      delete state[wellId];
    },
    removeAllPlanProdCurves(_state) {
      return { ...initialState };
    },
  },
});
