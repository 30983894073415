import { isSomeEnum } from 'utils/types/enums-check';

export enum ThermalMaturityColumn {
  BASIN = 'Basin',
  COUNTRY = 'Country',
  LAYER_TYPYE = 'Layer_Type',
  PLAY = 'Play',
  PLAY_DESC = 'Play_Desc',
  PLAY_ZONE_TYPE = 'Play_Zone_Type',
  RESERVOIR = 'Reservoir',
  WKT = 'WKT',
}

export const isThermalMaturityColumn = isSomeEnum(ThermalMaturityColumn);
