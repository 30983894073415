import { TPolygonData } from 'app/components/containers/widgets/MapWidget/types';
import { EFilterPage } from 'types/ConfigTabs';
import { DatesIntervalType } from 'types/DatesIntervalType';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { NumberIntervalType } from 'types/NumberIntervalType';

import { MapFilterSwitch } from './FilterSections/MapSection';

export type TCommonConfigTabFilterState = {
  [EFilterPage.PREDICT_FILTER]: {
    [key in ImpactWellAndProdColumn]?: string | DatesIntervalType | NumberIntervalType;
  };
  [EFilterPage.ECONOMICS_FILTER]: {
    [key in
      | ImpactWellAndProdColumn
      | ECommonFilterNonColumnValues.MAP_FILTER_TOGGLE
      | ECommonFilterNonColumnValues.GEO_POLYGONS]?:
      | boolean
      | TPolygonData[]
      | null
      | string
      | DatesIntervalType
      | NumberIntervalType;
  };
};

export enum ECommonFilterNonColumnValues {
  MAP_FILTER_TOGGLE = 'mapFilterToggle',
  GEO_POLYGONS = 'geoPolygons',
}

export const EXCLUDED_FILTER_VALUES: string[] = [
  ECommonFilterNonColumnValues.GEO_POLYGONS,
  ECommonFilterNonColumnValues.MAP_FILTER_TOGGLE,
];

export const mapFilterToggleObject = {
  filterType: EFilterPage.PREDICT_FILTER,
  id: String(ECommonFilterNonColumnValues.MAP_FILTER_TOGGLE) as ImpactWellAndProdColumn,
  label: 'CONFIG_PANEL.COMMON.FILTERS.MAP.TOGGLE.TITLE',
  placeholder: 'CONFIG_PANEL.COMMON_PLACEHOLDER',
  component: MapFilterSwitch,
};
