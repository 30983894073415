import { isSomeEnum } from 'utils/types/enums-check';

export enum IsopachColumn {
  DEPTH_FT = 'Depth_Ft',
  BASIN = 'Basin',
  PLAY = 'Play',
  WKT = 'WKT',
}

export const isIsopachColumn = isSomeEnum(IsopachColumn);
