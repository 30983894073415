import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';

export const TAB_LABELS = [
  'HEADER',
  'DRILLING_AND_COMPLETIONS',
  'PRODUCTION',
  'ECONOMICS',
  'FORECASTING',
  'POST_PRODUCTION',
  'QUALITY_CATEGORIZATION',
  'WELL_SPACING',
  'ACREAGE_AND_INVENTORY',
];

export const GROPED_TAB_COLUMNS = [
  [
    // Header Group
    ImpactWellAndProdColumn.UWI,
    ImpactWellAndProdColumn.UWI_DIGIT,
    ImpactWellAndProdColumn.BASIN,
    ImpactWellAndProdColumn.COUNTRY,
    ImpactWellAndProdColumn.COUNTY,
    ImpactWellAndProdColumn.DATE_PERMIT_GRANTED,
    ImpactWellAndProdColumn.DATE_VINTAGE,
    ImpactWellAndProdColumn.FIELD,
    ImpactWellAndProdColumn.FORMATION,
    ImpactWellAndProdColumn.LATITUDE_TOE,
    ImpactWellAndProdColumn.LATITUDE_SH,
    ImpactWellAndProdColumn.LEASE_NAME,
    ImpactWellAndProdColumn.LIFESTAGE_AGE_CURRENT_PROD_WELL,
    ImpactWellAndProdColumn.LIFESTAGE_PROD_LIFE_INACTIVE_WELL,
    ImpactWellAndProdColumn.LIFESTAGE_SPUD_TO_PROD,
    ImpactWellAndProdColumn.LOCATION_RANGE,
    ImpactWellAndProdColumn.LOCATION_SECTION,
    ImpactWellAndProdColumn.LOCATION_SECTION_NAME_WITH_PLAY,
    ImpactWellAndProdColumn.LOCATION_TOWNSHIP,
    ImpactWellAndProdColumn.LONGITUDE_TOE,
    ImpactWellAndProdColumn.LONGITUDE_SH,
    ImpactWellAndProdColumn.OPERATOR,
    ImpactWellAndProdColumn.OPERATOR_TICKER,
    ImpactWellAndProdColumn.OPERATOR_TYPE,
    ImpactWellAndProdColumn.ORIGINAL_OPERATOR,
    ImpactWellAndProdColumn.PERMIT_PROD_TYPE,
    ImpactWellAndProdColumn.PERMIT_RESERVOIR,
    ImpactWellAndProdColumn.PLAY_SUBREGION,
    ImpactWellAndProdColumn.PLAY_DESIGNATION,
    ImpactWellAndProdColumn.PLAY_TYPE,
    ImpactWellAndProdColumn.PROD_ENTITY_ID,
    ImpactWellAndProdColumn.PROD_ENTITY_TYPE,
    ImpactWellAndProdColumn.RESERVOIR,
    ImpactWellAndProdColumn.SEQUENCE_WELL_IN_PLAY,
    ImpactWellAndProdColumn.SEQUENCE_WELL_IN_SECTION,
    ImpactWellAndProdColumn.STATE_PROVINCE,
    ImpactWellAndProdColumn.STATUS_CURRENT_PROD,
    ImpactWellAndProdColumn.STATUS_FINAL,
    ImpactWellAndProdColumn.STATUS_PEAK,
    ImpactWellAndProdColumn.STATUS_WELL,
    ImpactWellAndProdColumn.TREATMENT_COMPANY,
    ImpactWellAndProdColumn.WELL_NAME,
    ImpactWellAndProdColumn.WELL_NUMBER,
    ImpactWellAndProdColumn.WELL_ORIENTATION,
  ],
  [
    // Drilling and Completions Group
    ImpactWellAndProdColumn.ACID_VOLUME,
    ImpactWellAndProdColumn.CEMENT_VOLUME,
    ImpactWellAndProdColumn.CERAMIC_VOLUME,
    ImpactWellAndProdColumn.DATE_COMPLETION,
    ImpactWellAndProdColumn.DATE_SPUD,
    ImpactWellAndProdColumn.DRILL_DAYS,
    ImpactWellAndProdColumn.FLUID_PER_FT,
    ImpactWellAndProdColumn.FLUID_VOLUME,
    ImpactWellAndProdColumn.FOOTAGE_LATERAL_LENGTH,
    ImpactWellAndProdColumn.FOOTAGE_LATERAL_LENGTH_BIN,
    ImpactWellAndProdColumn.FOOTAGE_MD,
    ImpactWellAndProdColumn.FOOTAGE_NEAREST_NEIGHBOR,
    ImpactWellAndProdColumn.FOOTAGE_PERF_INTERVAL,
    ImpactWellAndProdColumn.FOOTAGE_TVD,
    ImpactWellAndProdColumn.FRAC_STAGES,
    ImpactWellAndProdColumn.FRAC_TYPE,
    ImpactWellAndProdColumn.FRAC_SUPERFRAC,
    ImpactWellAndProdColumn.GEL_VOLUME,
    ImpactWellAndProdColumn.PAD_DRILLING_INDICATOR,
    ImpactWellAndProdColumn.PERFORATION_METHOD,
    ImpactWellAndProdColumn.PROPPANT_MESH_SIZE,
    ImpactWellAndProdColumn.PROPPANT_PER_FT,
    ImpactWellAndProdColumn.PROPPANT_PER_FT_BIN,
    ImpactWellAndProdColumn.PROPPANT_TYPE,
    ImpactWellAndProdColumn.PROPPANT_VOLUME,
    ImpactWellAndProdColumn.RESIN_COATED_VOLUME,
    ImpactWellAndProdColumn.SAND_VOLUME,
  ],
  [
    // Production Group
    ImpactWellAndProdColumn.API_GRAV_LIQUIDS,
    ImpactWellAndProdColumn.API_GRAVITY_RANGE,
    ImpactWellAndProdColumn.ARTIFICIAL_LIFT_METHOD,
    ImpactWellAndProdColumn.BASE_OR_WEDGE,
    ImpactWellAndProdColumn.BOE_20_1,
    ImpactWellAndProdColumn.BOE_6_1,
    ImpactWellAndProdColumn.CHOKE_SIZE_IP_24_TEST,
    ImpactWellAndProdColumn.DATE_ARTIFICIAL_LIFT_TEST,
    ImpactWellAndProdColumn.DATE_BOE_FIRST_RPTD,
    ImpactWellAndProdColumn.DATE_BOE_LAST_RPTD,
    ImpactWellAndProdColumn.DATE_IP_24_TEST,
    ImpactWellAndProdColumn.GAS,
    ImpactWellAndProdColumn.GATHERER_GAS,
    ImpactWellAndProdColumn.LIQUIDS,
    ImpactWellAndProdColumn.NORM_MONTH,
    ImpactWellAndProdColumn.PROD_TYPE,
    ImpactWellAndProdColumn.PROD_TYPE_RPTD,
    ImpactWellAndProdColumn.RATE_BOE_12MO_AVG,
    ImpactWellAndProdColumn.RATE_BOE_3MO_AVG,
    ImpactWellAndProdColumn.RATE_BOE_6MO_AVG,
    ImpactWellAndProdColumn.RATE_BOE_MKT1_12MO_AVG,
    ImpactWellAndProdColumn.RATE_BOE_MKT1_3MO_AVG,
    ImpactWellAndProdColumn.RATE_BOE_MKT1_6MO_AVG,
    ImpactWellAndProdColumn.RATE_GAS_12MO_AVG,
    ImpactWellAndProdColumn.RATE_GAS_3MO_AVG,
    ImpactWellAndProdColumn.RATE_GAS_6MO_AVG,
    ImpactWellAndProdColumn.RATE_IP_24_TEST_BOE,
    ImpactWellAndProdColumn.RATE_IP_24_TEST_BOE_MKT1,
    ImpactWellAndProdColumn.RATE_IP_24_TEST_GAS,
    ImpactWellAndProdColumn.RATE_IP_24_TEST_OIL,
    ImpactWellAndProdColumn.RATE_OIL_12MO_AVG,
    ImpactWellAndProdColumn.RATE_OIL_3MO_AVG,
    ImpactWellAndProdColumn.RATE_OIL_6MO_AVG,
    ImpactWellAndProdColumn.RATE_PEAK_BOE,
    ImpactWellAndProdColumn.RATE_PEAK_BOE_MKT1,
    ImpactWellAndProdColumn.RATE_PEAK_GAS,
    ImpactWellAndProdColumn.RATE_PEAK_OIL,
    ImpactWellAndProdColumn.RATE_WATER_12MO_AVG,
    ImpactWellAndProdColumn.RATE_WATER_3MO_AVG,
    ImpactWellAndProdColumn.RATE_WATER_6MO_AVG,
    ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_BOE,
    ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_BOE_MKT1,
    ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_GAS,
    ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_OIL,
    ImpactWellAndProdColumn.RATIO_OIL,
    ImpactWellAndProdColumn.RATIO_OIL_AT_PEAK,
    ImpactWellAndProdColumn.RATIO_OIL_AT_PEAK_BIN,
    ImpactWellAndProdColumn.TOTAL_PROD_BOE_MKT1_TO_DATE,
    ImpactWellAndProdColumn.TOTAL_PROD_BOE_TO_DATE,
    ImpactWellAndProdColumn.TOTAL_PROD_GAS_TO_DATE,
    ImpactWellAndProdColumn.TOTAL_PROD_OIL_TO_DATE,
    ImpactWellAndProdColumn.TOTAL_PROD_WATER_TO_DATE,
    ImpactWellAndProdColumn.WATER,
    ImpactWellAndProdColumn.WATER_CUT,
  ],
  [
    // Economics Group
    ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL,
    ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL_HISTORICAL,
    ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL_HISTORICAL_BIN,
    ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL_BIN,
    ImpactWellAndProdColumn.BREAKEVEN_GAS_MMBTU,
    ImpactWellAndProdColumn.BREAKEVEN_GAS_MMBTU_HISTORICAL,
    ImpactWellAndProdColumn.BREAKEVEN_GAS_MMBTU_BIN,
    ImpactWellAndProdColumn.BREAKEVEN_OIL_BBL,
    ImpactWellAndProdColumn.BREAKEVEN_OIL_BBL_HISTORICAL,
    ImpactWellAndProdColumn.BREAKEVEN_OIL_BBL_BIN,
    ImpactWellAndProdColumn.CAPEX_WELL_EST_CURRENT,
    ImpactWellAndProdColumn.CAPEX_WELL_EST_HISTORICAL,
    ImpactWellAndProdColumn.CASHFLOW_EST_BTAX,
    ImpactWellAndProdColumn.FREE_CASHFLOW,
    ImpactWellAndProdColumn.NPV,
    ImpactWellAndProdColumn.RPV,
    ImpactWellAndProdColumn.IRR,
  ],
  [
    // Forecasting Group
    ImpactWellAndProdColumn.EUR_DATE_BEG_PARTITION_GAS,
    ImpactWellAndProdColumn.EUR_DATE_BEG_PARTITION_OIL,
    ImpactWellAndProdColumn.EUR_DATE_BEG_PARTITION_WATER,
    ImpactWellAndProdColumn.EUR_DATE_END_PARTITION_GAS,
    ImpactWellAndProdColumn.EUR_DATE_END_PARTITION_OIL,
    ImpactWellAndProdColumn.EUR_DATE_END_PARTITION_WATER,
    ImpactWellAndProdColumn.EUR_GAS_BCF,
    ImpactWellAndProdColumn.EUR_MMBOE,
    ImpactWellAndProdColumn.EUR_MMBOE_MKT1,
    ImpactWellAndProdColumn.EUR_MMBOE_MKT1_BIN,
    ImpactWellAndProdColumn.EUR_NUMBER_OF_PARTITIONS_GAS,
    ImpactWellAndProdColumn.EUR_NUMBER_OF_PARTITIONS_OIL,
    ImpactWellAndProdColumn.EUR_NUMBER_OF_PARTITIONS_WATER,
    ImpactWellAndProdColumn.EUR_OIL_MMBBL,
    ImpactWellAndProdColumn.EUR_WATER_MMBBL,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_B_FACTOR_GAS,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_B_FACTOR_OIL,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_B_FACTOR_WATER,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_DI_GAS,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_DI_OIL,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_DI_WATER,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_QI_GAS,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_QI_OIL,
    ImpactWellAndProdColumn.EUR_CURVE_FIT_QI_WATER,
    ImpactWellAndProdColumn.EUR_DISC_GAS_BCF,
    ImpactWellAndProdColumn.EUR_DISC_MMBOE,
    ImpactWellAndProdColumn.EUR_DISC_MMBOE_MKT1,
    ImpactWellAndProdColumn.EUR_DISC_OIL_MMBBL,
    ImpactWellAndProdColumn.EUR_DISC_WATER_MMBBL,
    ImpactWellAndProdColumn.EUR_TYPE_BOE,
    ImpactWellAndProdColumn.EUR_TYPE_GAS,
    ImpactWellAndProdColumn.EUR_TYPE_OIL,
    ImpactWellAndProdColumn.EUR_TYPE_WATER,
    ImpactWellAndProdColumn.PARTITION_INDICATOR_GAS,
    ImpactWellAndProdColumn.PARTITION_INDICATOR_OIL,
    ImpactWellAndProdColumn.PARTITION_INDICATOR_WATER,
  ],
  [
    // Post Production Group
    ImpactWellAndProdColumn.DECLINE_BOE_12MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_BOE_6MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_BOE_MKT1_12MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_BOE_MKT1_6MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_GAS_12MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_GAS_6MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_OIL_12MO_POSTPEAK,
    ImpactWellAndProdColumn.DECLINE_OIL_6MO_POSTPEAK,
  ],
  [
    // Quality Categorization
    ImpactWellAndProdColumn.CATEGORY_PEAK_BOE,
    ImpactWellAndProdColumn.CATEGORY_PEAK_BOE_MKT1,
    ImpactWellAndProdColumn.CATEGORY_PEAK_GAS,
    ImpactWellAndProdColumn.CATEGORY_PEAK_OIL,
    ImpactWellAndProdColumn.CATEGORY_TYPE,
    ImpactWellAndProdColumn.QUINTILE_PEAK_BOE,
    ImpactWellAndProdColumn.QUINTILE_PEAK_BOE_MKT1,
    ImpactWellAndProdColumn.QUINTILE_PEAK_GAS,
    ImpactWellAndProdColumn.QUINTILE_PEAK_OIL,
    ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_BOE,
    ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_BOE_MKT1,
    ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_GAS,
    ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_OIL,
    ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_TYPE,
    ImpactWellAndProdColumn.QUINTILE_TYPE,
    ImpactWellAndProdColumn.TIER_PEAK_BOE,
    ImpactWellAndProdColumn.TIER_PEAK_BOE_MKT1,
    ImpactWellAndProdColumn.TIER_PEAK_GAS,
    ImpactWellAndProdColumn.TIER_PEAK_OIL,
    ImpactWellAndProdColumn.TIER_TYPE,
  ],
  [
    // Well Spacing Group
    ImpactWellAndProdColumn.AZIMUTH,
    ImpactWellAndProdColumn.SPACING_BOUNDING_STATUS,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_API_14_UWI,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_DATE_VINTAGE,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_OPERATOR,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_STRAIGHT_LINE_DISTANCE_IN_FT,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_API_14_UWI,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_DATE_VINTAGE,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_OPERATOR,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_RELATIONSHIP,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_API_14_UWI,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_DATE_VINTAGE,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_OPERATOR,
    ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_STRAIGHT_LINE_DISTANCE_IN_FT,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_API_14_UWI,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_DATE_VINTAGE,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_OPERATOR,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_STRAIGHT_LINE_DISTANCE_IN_FT,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_API_14_UWI,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_DATE_VINTAGE,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_OPERATOR,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_API_14_UWI,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_DATE_VINTAGE,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_OPERATOR,
    ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_STRAIGHT_LINE_DISTANCE_IN_FT,
    ImpactWellAndProdColumn.WELL_PATH_AZIMUTH,
    ImpactWellAndProdColumn.WELL_PATH_TOE_DELTA,
    ImpactWellAndProdColumn.WELL_PATH_TORTUOSITY_RATIO,
  ],
  [
    // Acreage and Inventory Group
    ImpactWellAndProdColumn.ACREAGE_EST_CLASS,
    ImpactWellAndProdColumn.ACREAGE_IDENTIFIER,
    ImpactWellAndProdColumn.ACREAGE_OPERATOR,
    ImpactWellAndProdColumn.ACREAGE_TOTAL_FOOTAGE_DRILLED,
    ImpactWellAndProdColumn.ACREAGE_TOTAL_FOOTAGE_DRILLED_AND_PROPOSED,
    ImpactWellAndProdColumn.ACREAGE_WELL_PERFORMANCE_CATEGORY_VS_ACREAGE_CLASS,
    ImpactWellAndProdColumn.ACREAGE_WELL_PERFORMANCE_DIFFERENCE_VS_ACREAGE_CLASS,
  ],
];
