import { ThemeMode } from 'impact-react-components';

import { withStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const overrideStyles = withStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return {
    root: {
      border: 'none',
      marginBottom: 1,
      minHeight: 40,
      '&$expanded': {
        minHeight: 40,
      },
      '&:hover': {
        backgroundColor: isDarkTheme ? colors.grey56 : colors.primaryWhite,
      },
    },
    content: {
      margin: '8px 0',
      '& .MuiTypography-root': {
        fontSize: 16,
        color: isDarkTheme ? colors.baseGrey20 : colors.baseGrey80,
      },
      '&$expanded': {
        margin: '8px 0',
      },
    },
    expanded: {},
    expandIcon: {
      marginRight: -4,
      padding: 0,
    },
  };
});
