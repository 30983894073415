import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;

  return createStyles({
    price: {
      color: type === ThemeMode.DARK ? colors.baseGrey20 : colors.grey40,
    },
  });
});
