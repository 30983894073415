import { MapLngLat } from 'app/components/containers/widgets/MapWidget/types';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { WellStatus } from 'types/Well';

export const OVERLAY_DELAY_MS = 1000;

export const PRODUCING_WELL = WellStatus.PRODUCING_WELL;

export const PIXEL_RATIO = 1;
export const PIXEL_RADIUS = Math.ceil(2 * PIXEL_RATIO);

export const COORDINATES_COLUMN = [ImpactWellAndProdColumn.LONGITUDE_SH, ImpactWellAndProdColumn.LATITUDE_SH];

export const DEFAULT_USA_MAP_ZOOM = 3;
export const DEFAULT_USA_MAP_CENTER: MapLngLat = [-97.346, 39.77];

export const DEFAULT_CANADA_MAP_ZOOM = 2.3;
export const DEFAULT_CANADA_MAP_CENTER: MapLngLat = [-94.371, 60.767];
