import { PayloadAction } from '@reduxjs/toolkit';

import { TPolygonData } from 'app/components/containers/widgets/MapWidget/types';
import { EFilterPage } from 'types/ConfigTabs';
import { FieldInfo } from 'types/FieldInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { TCommonConfigTabFilterState } from './types';

export const initialState: TCommonConfigTabFilterState = {
  [EFilterPage.PREDICT_FILTER]: {},
  [EFilterPage.ECONOMICS_FILTER]: {
    mapFilterToggle: true,
    geoPolygons: null,
  },
};

const predictFilterSlice = createSlice({
  name: 'commonConfigTabFilter',
  initialState,
  reducers: {
    changeField(state, { payload: { name, value, type } }: PayloadAction<FieldInfo>) {
      state[type][name] = value;
    },
    changeMapFilterToggle(state, { payload: { mapFilterToggle } }: PayloadAction<{ mapFilterToggle: boolean }>) {
      state.ECONOMICS_FILTER.mapFilterToggle = mapFilterToggle;
    },
    setGeoPolygons(state, { payload: { geoPolygons } }: PayloadAction<{ geoPolygons: TPolygonData[] | null }>) {
      state.ECONOMICS_FILTER.geoPolygons = geoPolygons;
    },
    resetField(state, { payload: { name, type } }: PayloadAction<Omit<FieldInfo, 'value'>>) {
      delete state[type][name];
    },
    resetAll(state) {
      return {
        ...initialState,
        [EFilterPage.ECONOMICS_FILTER]: {
          geoPolygons: state[EFilterPage.ECONOMICS_FILTER].geoPolygons,
        },
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = predictFilterSlice;
