import { api } from 'constants/API';

import axios from 'axios';

import {
  ImpactCurvesSettingsEvent,
  LogEvent,
  MapWellsEvent,
  PlanProductionCurvesEvent,
  PredictHeaderPanelActionEvent,
  PredictMapWidgetActionEvent,
  PredictPageFilterEvent,
  PredictionMapLayerEvent,
  ProductionCurvesSettingsEvent,
  ScenarioLogEvent,
  WellParametersEvent,
} from 'services/LogEvent';
import { LogSource } from 'services/LogSource';

export interface ClientLogMessage {
  event:
    | LogEvent
    | ScenarioLogEvent
    | ImpactCurvesSettingsEvent
    | WellParametersEvent
    | ProductionCurvesSettingsEvent
    | MapWellsEvent
    | PlanProductionCurvesEvent
    | PredictionMapLayerEvent
    | PredictPageFilterEvent
    | PredictHeaderPanelActionEvent
    | PredictMapWidgetActionEvent;
  source: LogSource;
  [key: string]: any;
}

class Logger {
  private log(apiUrl: string) {
    return (message: ClientLogMessage, url?: string) => {
      if (process.env.REACT_APP_LOG_USER_ACTIONS_ENABLED === 'true') {
        axios
          .post(apiUrl, {
            url: url || window.location.toString(),
            message: message,
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.warn('[LOG] Unable to send log message: ', error.message);
          });
      }
    };
  }

  info(message: ClientLogMessage, url?: string) {
    this.log(api.postLogInfo)(message, url);
  }

  error(message: ClientLogMessage, url?: string) {
    this.log(api.postLogError)(message, url);
  }
}

export const log = new Logger();
