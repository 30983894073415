import { useEffect } from 'react';

import { trackUserInfo } from 'common/analytics';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CommonRoute, CommonRouteProps } from '../CommonRoute';
import { PrivateRoute, PrivateRouteProps } from '../PrivateRoute';
import { userInfoSaga } from '../PrivateRoute/saga';
import { reducer, sliceKey } from '../PrivateRoute/slice';
import { SocketHandler } from '../SocketHandler';

export enum ERouteType {
  PRIVATE = 'PRIVATE',
  COMMON = 'COMMON',
}

export type TProps =
  | (CommonRouteProps & {
      routeType: ERouteType;
    })
  | (PrivateRouteProps & {
      routeType: ERouteType;
    });

export const RouteManager = ({ routeType, ...rest }: TProps) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userInfoSaga });

  useEffect(() => {
    trackUserInfo();
  }, []);

  if (routeType === ERouteType.PRIVATE) {
    return (
      <SocketHandler>
        <PrivateRoute {...rest} />
      </SocketHandler>
    );
  }

  if (routeType === ERouteType.COMMON) {
    return (
      <SocketHandler>
        <CommonRoute {...rest} />
      </SocketHandler>
    );
  }

  return <></>;
};
