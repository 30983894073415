import { ThemeMode } from 'impact-react-components';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    dialogPaper: {
      minWidth: 480,
      minHeight: 546,
      maxHeight: 600,
      background: isDarkTheme ? colors.grey56 : colors.primaryWhite,
    },
    dialogHeader: {
      lineHeight: '48px',
      textAlign: 'center',
    },
    dialogContent: {
      padding: theme.spacing(1),
    },
    dialogFooter: {
      padding: theme.spacing(3),
    },
    cancel: {
      minWidth: 72,
    },
    listItem: {
      justifyContent: 'space-between',
    },
    error: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    itemText: {
      maxWidth: 350,
    },
  });
});
