import { AcreageAndInventoryColumn } from 'types/AcreageAndInventoryColumn';
import { CompanyAcreageColumn } from 'types/CompanyAcreageColumn';
import { FaultsColumn } from 'types/FaultsColumns';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { IsopachColumn } from 'types/IsopachColumn';
import { MidstreamPipelinesColumn } from 'types/MidstreamPipelinesColumn';
import { PostgreDataType } from 'types/PostgreDataType';
import { StructureColumn } from 'types/StructureColumn';
import { ThermalMaturityColumn } from 'types/ThermalMaturityColumn';
import { WellPathColumn } from 'types/WellPathColumn';

export const COLOR_BY_COLUMNS: Partial<Record<ImpactWellAndProdColumn, PostgreDataType>> = {
  [ImpactWellAndProdColumn.ACID_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.ACREAGE_EST_CLASS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.ACREAGE_IDENTIFIER]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.ACREAGE_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.ACREAGE_TOTAL_FOOTAGE_DRILLED]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.ACREAGE_TOTAL_FOOTAGE_DRILLED_AND_PROPOSED]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.ACREAGE_WELL_PERFORMANCE_CATEGORY_VS_ACREAGE_CLASS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.ACREAGE_WELL_PERFORMANCE_DIFFERENCE_VS_ACREAGE_CLASS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.UWI_DIGIT]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.API_GRAV_LIQUIDS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.API_GRAVITY_RANGE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.ARTIFICIAL_LIFT_METHOD]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.AZIMUTH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BASE_OR_WEDGE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.BASIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.BOE_20_1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BOE_6_1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL_HISTORICAL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BREAKEVEN_BOE_MKT1_BBL_HISTORICAL_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.BREAKEVEN_GAS_MMBTU]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BREAKEVEN_GAS_MMBTU_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.BREAKEVEN_GAS_MMBTU_HISTORICAL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BREAKEVEN_OIL_BBL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.BREAKEVEN_OIL_BBL_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.BREAKEVEN_OIL_BBL_HISTORICAL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.CAPEX_WELL_EST_CURRENT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.CAPEX_WELL_EST_HISTORICAL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.CASHFLOW_EST_BTAX]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.CATEGORY_PEAK_BOE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.CATEGORY_PEAK_BOE_MKT1]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.CATEGORY_PEAK_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.CATEGORY_PEAK_OIL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.CATEGORY_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.CEMENT_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.CERAMIC_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.CHOKE_SIZE_IP_24_TEST]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.COUNTRY]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.COUNTY]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.DATE_VINTAGE_YEAR]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_BOE_12MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_BOE_6MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_BOE_MKT1_12MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_BOE_MKT1_6MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_GAS_12MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_GAS_6MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_OIL_12MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DECLINE_OIL_6MO_POSTPEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.DRILL_DAYS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.ELAPSED_TIME_NORM_MONTH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_B_FACTOR_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_B_FACTOR_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_B_FACTOR_WATER]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_DI_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_DI_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_DI_WATER]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_QI_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_QI_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_CURVE_FIT_QI_WATER]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_DISC_GAS_BCF]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_DISC_MMBOE]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_DISC_MMBOE_MKT1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_DISC_OIL_MMBBL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_DISC_WATER_MMBBL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_GAS_BCF]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_MMBOE]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_MMBOE_MKT1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_MMBOE_MKT1_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.EUR_NUMBER_OF_PARTITIONS_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_NUMBER_OF_PARTITIONS_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_NUMBER_OF_PARTITIONS_WATER]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_OIL_MMBBL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.EUR_TYPE_BOE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.EUR_TYPE_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.EUR_TYPE_OIL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.EUR_TYPE_WATER]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.EUR_WATER_MMBBL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FIELD]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.FLUID_PER_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FLUID_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FOOTAGE_LATERAL_LENGTH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FOOTAGE_LATERAL_LENGTH_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.FOOTAGE_MD]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FOOTAGE_NEAREST_NEIGHBOR]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FOOTAGE_PERF_INTERVAL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FOOTAGE_TVD]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FORMATION]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.FRAC_STAGES]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.FRAC_SUPERFRAC]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.FRAC_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.FREE_CASHFLOW]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.GATHERER_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.GEL_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.LATITUDE_BH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.LATITUDE_SH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.LEASE_NAME]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.LIFESTAGE_AGE_CURRENT_PROD_WELL]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.LIFESTAGE_PROD_LIFE_INACTIVE_WELL]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.LIFESTAGE_SPUD_TO_PROD]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.LIQUIDS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.LOCATION_RANGE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.LOCATION_SECTION]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.LOCATION_SECTION_NAME_WITH_PLAY]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.LOCATION_TOWNSHIP]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.LONGITUDE_BH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.LONGITUDE_SH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.NORM_MONTH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.OPERATOR_TICKER]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.OPERATOR_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.ORIGINAL_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PAD_DRILLING_INDICATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PARTITION_INDICATOR_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PARTITION_INDICATOR_OIL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PARTITION_INDICATOR_WATER]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PERFORATION_METHOD]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PERMIT_PROD_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PERMIT_RESERVOIR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PLAY_DESIGNATION]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PLAY_SUBREGION]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PLAY_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROD_ENTITY_ID]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROD_ENTITY_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROD_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROD_TYPE_RPTD]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROPPANT_MESH_SIZE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROPPANT_PER_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.PROPPANT_PER_FT_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROPPANT_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.PROPPANT_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_BOE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_BOE_MKT1]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_OIL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_BOE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_BOE_MKT1]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_OIL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_PEAK_PER_LAT_FT_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.QUINTILE_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.RATE_BOE_12MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_BOE_3MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_BOE_6MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_BOE_MKT1_12MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_BOE_MKT1_3MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_BOE_MKT1_6MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_GAS_12MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_GAS_3MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_GAS_6MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_IP_24_TEST_BOE]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_IP_24_TEST_BOE_MKT1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_IP_24_TEST_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_IP_24_TEST_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_OIL_12MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_OIL_3MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_OIL_6MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PEAK_BOE]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PEAK_BOE_MKT1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PEAK_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PEAK_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_BOE]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_BOE_MKT1]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_GAS]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_PER_1000_LAT_FT_PEAK_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_WATER_12MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_WATER_3MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATE_WATER_6MO_AVG]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATIO_OIL]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATIO_OIL_AT_PEAK]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.RATIO_OIL_AT_PEAK_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.RESERVOIR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.RESIN_COATED_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SAND_VOLUME]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SEQUENCE_WELL_IN_PLAY]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SEQUENCE_WELL_IN_SECTION]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_BOUNDING_STATUS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_API_14_UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_HORIZ_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_STRAIGHT_LINE_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_AT_VINTAGE_VERT_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_API_14_UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_HORIZ_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_RELATIONSHIP]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT_BIN]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_CURRENT_VERT_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_API_14_UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_HORIZ_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_STRAIGHT_LINE_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_NEIGHBOR_PARENT_VERT_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_API_14_UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_AT_VINTAGE_STRAIGHT_LINE_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_API_14_UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_API_14_UWI]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_OPERATOR]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.SPACING_RES_NEIGHBOR_PARENT_STRAIGHT_LINE_DISTANCE_IN_FT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.STATE_PROVINCE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.STATUS_CURRENT_PROD]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.STATUS_FINAL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.STATUS_PEAK]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.STATUS_WELL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.TIER_PEAK_BOE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.TIER_PEAK_BOE_MKT1]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.TIER_PEAK_GAS]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.TIER_PEAK_OIL]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.TIER_TYPE]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.TOTAL_PROD_BOE_MKT1_TO_DATE]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.TOTAL_PROD_BOE_TO_DATE]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.TOTAL_PROD_GAS_TO_DATE]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.TOTAL_PROD_OIL_TO_DATE]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.TOTAL_PROD_WATER_TO_DATE]: PostgreDataType.FLOAT8,
  [ImpactWellAndProdColumn.TREATMENT_COMPANY]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.WATER]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.WATER_CUT]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.WELL_NAME]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.WELL_NUMBER]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.WELL_ORIENTATION]: PostgreDataType.VARCHAR,
  [ImpactWellAndProdColumn.WELL_PATH_AZIMUTH]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.WELL_PATH_TOE_DELTA]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.WELL_PATH_TORTUOSITY_RATIO]: PostgreDataType.NUMERIC,
  [ImpactWellAndProdColumn.WELLID]: PostgreDataType.VARCHAR,
};

export const ACREAGE_COLOR_BY_COLUMNS: Partial<Record<AcreageAndInventoryColumn, PostgreDataType>> = {
  [AcreageAndInventoryColumn.OPERATOR]: PostgreDataType.VARCHAR,
  [AcreageAndInventoryColumn.PERCENT_DEVELOPED_TRUNC]: PostgreDataType.NUMERIC,
  [AcreageAndInventoryColumn.ACREAGE_EST_CLASS]: PostgreDataType.VARCHAR,
  [AcreageAndInventoryColumn.PLAY_DESIGNATION]: PostgreDataType.VARCHAR,
  [AcreageAndInventoryColumn.PLAY_SUBPLAY]: PostgreDataType.VARCHAR,
};

export const MIDSTREAM_COLOR_BY_COLUMNS: Partial<Record<MidstreamPipelinesColumn, PostgreDataType>> = {
  [MidstreamPipelinesColumn.CATEGORY]: PostgreDataType.VARCHAR,
};

export const ISOPACH_COLOR_BY_COLUMNS: Partial<Record<any, PostgreDataType>> = {
  [IsopachColumn.DEPTH_FT]: PostgreDataType.VARCHAR,
};

export const SUBSURFACE_STRUCTURE_COLOR_BY_COLUMNS: Partial<Record<StructureColumn, PostgreDataType>> = {
  [StructureColumn.DEPTH_FT]: PostgreDataType.VARCHAR,
};

export const SUBSURFACE_FAULTS_COLOR_BY_COLUMNS: Partial<Record<any, PostgreDataType>> = {
  [FaultsColumn.FORMATION]: PostgreDataType.VARCHAR,
};

export const THERMAL_MATURITY_COLOR_BY_COLUMNS: Partial<Record<ThermalMaturityColumn, PostgreDataType>> = {
  [ThermalMaturityColumn.PLAY_ZONE_TYPE]: PostgreDataType.VARCHAR,
};

export const COMPANY_ACREAGE_COLOR_BY_COLUMNS: Partial<Record<CompanyAcreageColumn, PostgreDataType>> = {
  [CompanyAcreageColumn.OPERATOR]: PostgreDataType.VARCHAR,
};

export const WELL_PATH_COLOR_BY_COLUMNS: Partial<Record<WellPathColumn, PostgreDataType>> = {
  [WellPathColumn.OPERATOR]: PostgreDataType.VARCHAR,
};

export const SINGLE_OPTION = 1;
