import { ProdCurvesResponsePayload } from 'api/curves';

import { MONTH_NUMBER_DATA_KEY } from 'app/components/containers/widgets/ProductionCurves/constants';
import { P_Curve } from 'types/Curve';
import { CurveToModelMap } from 'types/PlanPredictModel';

import { PROD_CURVE_DATA_KEY, PredictProdCurveItem } from './types';

export const generateChartKeyByDataItem = (dataItem: ProdCurvesResponsePayload) => {
  return dataItem.P_Curve === P_Curve.P50 || dataItem.P_Curve === null
    ? (`${dataItem.Task}_${CurveToModelMap[dataItem.Curve]}` as PROD_CURVE_DATA_KEY)
    : (`${dataItem.Task}_${CurveToModelMap[dataItem.Curve]}_${dataItem.P_Curve}` as PROD_CURVE_DATA_KEY);
};

const getChartItemDataByKey = (dataByType: ProdCurvesResponsePayload, key: string) => ({
  [generateChartKeyByDataItem(dataByType)]: dataByType[key],
});

export const normalizeChartData = (rawData: ProdCurvesResponsePayload[]) => {
  const result = rawData.reduce((acc, dataByType) => {
    const chartDataForType = Object.keys(dataByType).reduce((acc, dataByTypeKey) => {
      const monthNumber = Number(dataByTypeKey);

      return !Number.isNaN(monthNumber)
        ? [
            ...acc,
            {
              ...getChartItemDataByKey(dataByType, dataByTypeKey),
              [MONTH_NUMBER_DATA_KEY]: monthNumber,
            },
          ]
        : acc;
    }, [] as Partial<PredictProdCurveItem>[]);

    return chartDataForType.map((chartValue, index) => ({
      ...acc[index],
      ...chartValue,
    }));
  }, [] as PredictProdCurveItem[]);

  return result.sort((itemA, itemB) => itemA.monthNumber - itemB.monthNumber);
};

export function getNumKeys(obj: {}) {
  return Object.keys(obj).filter((key) => !isNaN(Number(key)));
}
