export enum Play {
  ANADARKO_PENNSYLVANIAN = 'Anadarko Pennsylvanian',
  AUSTIN_CHALK = 'Austin Chalk',
  BAKKEN_SHALE = 'Bakken Shale',
  BARNETT_SHALE = 'Barnett Shale',
  BONE_SPRING = 'Bone Spring',
  EAGLEFORD_SHALE = 'Eagleford Shale',
  FAYETTEVILLE_SHALE = 'Fayetteville Shale',
  HAYNESVILLE_SHALE = 'Haynesville Shale',
  MARCELLUS_SHALE = 'Marcellus Shale',
  NIOBRARA_FRACTURE_PLAY = 'Niobrara Fracture Play',
  PRB_DEEP_OIL = 'PRB Deep Oil',
  PRB_SHALLOW_OIL = 'PRB Shallow Oil',
  SCOOP = 'Scoop',
  STACK = 'Stack',
  UTICA_SHALE = 'Utica Shale',
  WATTENBERG = 'Wattenberg',
  WOLFCAMP_DELAWARE = 'Wolfcamp Delaware',
  WOLFCAMP_MIDLAND = 'Wolfcamp Midland',
  WOODFORD_ARKOMA = 'Woodford - Arkoma',
  WOODFORD_CANA = 'Woodford - Cana',
  MONTNEY = 'Montney',
  SPIRIT_RIVER = 'Spirit River',
  DUVERNAY = 'Duvernay',
  CARDIUM = 'Cardium',
  VIKING_SHALLOW = 'Viking Shallow',
}
