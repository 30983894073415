import { isSomeEnum } from 'utils/types/enums-check';

export enum MidstreamPipelinesColumn {
  CATEGORY = 'Category',
  COUNTRY = 'Country',
  NOTES = 'Notes',
  WKT = 'WKT',
}

export const isMidstreamPipelinesColumn = isSomeEnum(MidstreamPipelinesColumn);
