import { withStyles } from '@material-ui/core/styles';

export const overrideStyles = withStyles(() => {
  return {
    root: {
      border: 'none',
      boxShadow: 'none',
      background: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  };
});
