import { TestLocators } from 'constants/Locators';

import { Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsJupyterEnabled } from 'app/containers/PrivateRoute/selectors';

import { ReactComponent as ProfileIcon } from './profile.svg';
import { ProfileMenu } from './ProfileMenu';
import { useStyles } from './styles';

export const Profile = () => {
  const isJupyterEnabled = useSelector(selectIsJupyterEnabled);
  const classes = useStyles({ isJupyterEnabled });
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClickProfileMenu = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setProfileMenuAnchorEl(currentTarget);
  };

  const handleCloseProfileMenu = useCallback(() => {
    setProfileMenuAnchorEl(null);
  }, []);

  return (
    <>
      <div className={classes.profile} onClick={handleClickProfileMenu} data-testid={TestLocators.UserProfile}>
        <div className={classes.profileBackground}>
          <ProfileIcon className={classes.profileIcon} />
        </div>
        {profileMenuAnchorEl !== null && <Box className={classes.animatedBorder} />}
      </div>
      <ProfileMenu anchorEl={profileMenuAnchorEl} onClose={handleCloseProfileMenu} />
    </>
  );
};
