import { CurveType } from 'types/Curve';
import { ProductionUnit } from 'types/ProductionUnit';

import { PredictExportDataKey } from './types';

// Columns' order in the export process
export const StaticExportKeys = [
  PredictExportDataKey.BASIN,
  PredictExportDataKey.GEO_PROVINCE,
  PredictExportDataKey.UWI,
  PredictExportDataKey.PRODUCTION_CURVE,
  PredictExportDataKey.UNIT,
  PredictExportDataKey.TYPE,
  PredictExportDataKey.CURVE,
];

export const ExportingUnitNameByTaskForRate: Record<CurveType, ProductionUnit> = {
  [CurveType.LIQUID]: ProductionUnit.BOE_PER_DAY,
  [CurveType.GAS]: ProductionUnit.BOE_PER_DAY,
  [CurveType.WATER]: ProductionUnit.BOE_PER_DAY,
};

export const ExportingUnitNameByTaskForCumulative: Record<CurveType, ProductionUnit> = {
  [CurveType.LIQUID]: ProductionUnit.BOE,
  [CurveType.GAS]: ProductionUnit.BOE,
  [CurveType.WATER]: ProductionUnit.BOE,
};

export const ZERO_MONTH_COLUMN_NAME = '0';
