import { isSomeEnum } from 'utils/types/enums-check';

// https://confluence.ihsmarkit.com/display/ENR/Predict+Screen+-+Filter+Panel

export enum ImpactWellAndProdColumn {
  UWI = 'API_14_UWI_Unformatted',
  UWI_DIGIT = 'API_14_Digit_UWI',
  BASIN = 'Basin',
  COUNTRY = 'Country',
  COUNTY = 'County',
  DATE_PERMIT_GRANTED = 'Date_Permit_Granted',
  DATE_VINTAGE = 'Date_Vintage',
  DATE_VINTAGE_YEAR = 'Date_Vintage_Year',
  FIELD = 'Field',
  GAS = 'Gas',
  GATHERER_GAS = 'Gatherer_Gas',
  GEL_VOLUME = 'Gel_Volume',
  GEO_PROVINCE = 'Geologic_Province',
  FORMATION = 'Formation',
  // NOTE: LATITUDE_BH fetched from API and further used as LATITUDE_TOE for UI
  LATITUDE_TOE = 'Latitude_Toe',
  LATITUDE_BH = 'Latitude_BH',
  LATITUDE_SH = 'Latitude_SH',
  LEASE_NAME = 'Lease_Name',
  LIFESTAGE_AGE_CURRENT_PROD_WELL = 'Lifestage_Age_Current_Prod_Well',
  LIFESTAGE_PROD_LIFE_INACTIVE_WELL = 'Lifestage_Prod_Life_Inactive_Well',
  LIFESTAGE_SPUD_TO_PROD = 'Lifestage_Spud_to_Prod',
  LOCATION_RANGE = 'Location_Range',
  LOCATION_SECTION = 'Location_Section',
  LOCATION_SECTION_NAME_WITH_PLAY = 'Location_Section_Name_with_Play',
  LOCATION_TOWNSHIP = 'Location_Township',
  // NOTE: LONGITUDE_BH fetched from API and further used as LONGITUDE_TOE for UI
  LONGITUDE_TOE = 'Longitude_Toe',
  LONGITUDE_BH = 'Longitude_BH',
  LONGITUDE_SH = 'Longitude_SH',
  OPERATOR = 'Operator',
  OPERATOR_TICKER = 'Operator_Ticker',
  OPERATOR_TYPE = 'Operator_Type',
  ORIGINAL_OPERATOR = 'Original_Operator',
  PERMIT_PROD_TYPE = 'Permit_Prod_Type',
  PERMIT_RESERVOIR = 'Permit_Reservoir',
  PLAY_SUBREGION = 'Play_Subregion',
  PLAY_DESIGNATION = 'Play_Designation',
  PLAY_TYPE = 'Play_Type',
  PROD_ENTITY_ID = 'Prod_Entity_ID',
  PROD_ENTITY_TYPE = 'Prod_Entity_Type',
  RESERVOIR = 'Reservoir',
  CLEANED_RESERVOIR = 'CleanedReservoir',
  SEQUENCE_WELL_IN_PLAY = 'Sequence_Well_in_Play',
  SEQUENCE_WELL_IN_SECTION = 'Sequence_Well_in_Section',
  STATE_PROVINCE = 'State_Province',
  STATUS_CURRENT_PROD = 'Status_Current_Prod',
  STATUS_FINAL = 'Status_Final',
  STATUS_PEAK = 'Status_Peak',
  STATUS_WELL = 'Status_Well',
  TREATMENT_COMPANY = 'Treatment_Company',
  WELL_NAME = 'Well_Name',
  WELL_NUMBER = 'Well_Number',
  WELL_ORIENTATION = 'Well_Orientation',
  WELLID = 'WellID',
  ACID_VOLUME = 'Acid_Volume',
  CEMENT_VOLUME = 'Cement_Volume',
  CERAMIC_VOLUME = 'Ceramic_Volume',
  DATE_COMPLETION = 'Date_Completion',
  DATE_SPUD = 'Date_Spud',
  DRILL_DAYS = 'Drill_Days',
  FLUID_PER_FT = 'Fluid_per_Ft',
  FLUID_VOLUME = 'Fluid_Volume',
  FOOTAGE_LATERAL_LENGTH = 'Footage_Lateral_Length',
  FOOTAGE_LATERAL_LENGTH_BIN = 'Footage_Lateral_Length_Bin',
  FOOTAGE_MD = 'Footage_MD',
  FOOTAGE_NEAREST_NEIGHBOR = 'Footage_Nearest_Neighbor',
  FOOTAGE_PERF_INTERVAL = 'Footage_Perf_Interval',
  FOOTAGE_TVD = 'Footage_TVD',
  FRAC_STAGES = 'Frac_Stages',
  FRAC_TYPE = 'Frac_Type',
  FRAC_SUPERFRAC = 'Frac_Superfrac',
  PAD_DRILLING_INDICATOR = 'Pad_Drilling_Indicator',
  PERFORATION_METHOD = 'Perforation_Method',
  PROPPANT_MESH_SIZE = 'Proppant_Mesh_Size',
  PROPPANT_PER_FT = 'Proppant_per_Ft',
  PROPPANT_PER_FT_BIN = 'Proppant_per_Ft_Bin',
  PROPPANT_TYPE = 'Proppant_Type',
  PROPPANT_VOLUME = 'Proppant_Volume',
  RESIN_COATED_VOLUME = 'Resin_Coated_Volume',
  SAND_VOLUME = 'Sand_Volume',
  API_GRAV_LIQUIDS = 'API_Grav_Liquids',
  API_GRAVITY_RANGE = 'API_Gravity_Range',
  ARTIFICIAL_LIFT_METHOD = 'Artificial_Lift_Method',
  BASE_OR_WEDGE = 'Base_or_Wedge',
  BOE_20_1 = 'Boe_20_1',
  BOE_6_1 = 'Boe_6_1',
  CHOKE_SIZE_IP_24_TEST = 'Choke_Size_IP_24_Test',
  DATE_ARTIFICIAL_LIFT_TEST = 'Date_Artificial_Lift_Test',
  DATE_BOE_FIRST_RPTD = 'Date_Boe_First_Rptd',
  DATE_BOE_LAST_RPTD = 'Date_Boe_Last_Rptd',
  DATE_IP_24_TEST = 'Date_IP_24_Test',
  LIQUIDS = 'Liquids',
  NORM_MONTH = 'Norm_Month',
  PROD_TYPE = 'Prod_Type',
  PROD_TYPE_RPTD = 'Prod_Type_Rptd',
  RATE_BOE_12MO_AVG = 'Rate_Boe_12mo_Avg',
  RATE_BOE_3MO_AVG = 'Rate_Boe_3mo_Avg',
  RATE_BOE_6MO_AVG = 'Rate_Boe_6mo_Avg',
  RATE_BOE_MKT1_12MO_AVG = 'Rate_Boe_Mkt1_12mo_Avg',
  RATE_BOE_MKT1_3MO_AVG = 'Rate_Boe_Mkt1_3mo_Avg',
  RATE_BOE_MKT1_6MO_AVG = 'Rate_Boe_Mkt1_6mo_Avg',
  RATE_GAS_12MO_AVG = 'Rate_Gas_12mo_Avg',
  RATE_GAS_3MO_AVG = 'Rate_Gas_3mo_Avg',
  RATE_GAS_6MO_AVG = 'Rate_Gas_6mo_Avg',
  RATE_IP_24_TEST_BOE = 'Rate_IP_24_Test_Boe',
  RATE_IP_24_TEST_BOE_MKT1 = 'Rate_IP_24_Test_Boe_Mkt1',
  RATE_IP_24_TEST_GAS = 'Rate_IP_24_Test_Gas',
  RATE_IP_24_TEST_OIL = 'Rate_IP_24_Test_Oil',
  RATE_OIL_12MO_AVG = 'Rate_Oil_12mo_Avg',
  RATE_OIL_3MO_AVG = 'Rate_Oil_3mo_Avg',
  RATE_OIL_6MO_AVG = 'Rate_Oil_6mo_Avg',
  RATE_PEAK_BOE = 'Rate_Peak_Boe',
  RATE_PEAK_BOE_MKT1 = 'Rate_Peak_Boe_Mkt1',
  RATE_PEAK_GAS = 'Rate_Peak_Gas',
  RATE_PEAK_OIL = 'Rate_Peak_Oil',
  RATE_WATER_12MO_AVG = 'Rate_Water_12mo_Avg',
  RATE_WATER_3MO_AVG = 'Rate_Water_3mo_Avg',
  RATE_WATER_6MO_AVG = 'Rate_Water_6mo_Avg',
  RATE_PER_1000_LAT_FT_PEAK_BOE = 'Rate_per_1000_Lat_Ft_Peak_Boe',
  RATE_PER_1000_LAT_FT_PEAK_BOE_MKT1 = 'Rate_per_1000_Lat_Ft_Peak_Boe_Mkt1',
  RATE_PER_1000_LAT_FT_PEAK_GAS = 'Rate_per_1000_Lat_Ft_Peak_Gas',
  RATE_PER_1000_LAT_FT_PEAK_OIL = 'Rate_per_1000_Lat_Ft_Peak_Oil',
  RATIO_OIL = 'Ratio_Oil',
  RATIO_OIL_AT_PEAK = 'Ratio_Oil_At_Peak',
  RATIO_OIL_AT_PEAK_BIN = 'Ratio_Oil_At_Peak_Bin',
  TOTAL_PROD_BOE_MKT1_TO_DATE = 'Total_Prod_Boe_Mkt1_to_Date',
  TOTAL_PROD_BOE_TO_DATE = 'Total_Prod_Boe_to_Date',
  TOTAL_PROD_GAS_TO_DATE = 'Total_Prod_Gas_to_Date',
  TOTAL_PROD_OIL_TO_DATE = 'Total_Prod_Oil_to_Date',
  TOTAL_PROD_WATER_TO_DATE = 'Total_Prod_Water_to_Date',
  WATER = 'Water',
  WATER_CUT = 'Water_Cut',
  BREAKEVEN_BOE_MKT1_BBL = 'Breakeven_Boe_Mkt1_bbl',
  BREAKEVEN_BOE_MKT1_BBL_HISTORICAL = 'Breakeven_Boe_Mkt1_bbl_Historical',
  BREAKEVEN_BOE_MKT1_BBL_HISTORICAL_BIN = 'Breakeven_Boe_Mkt1_bbl_Historical_Bin',
  BREAKEVEN_BOE_MKT1_BBL_BIN = 'Breakeven_Boe_Mkt1_bbl_Bin',
  BREAKEVEN_GAS_MMBTU = 'Breakeven_Gas_MMBtu',
  BREAKEVEN_GAS_MMBTU_HISTORICAL = 'Breakeven_Gas_MMBtu_Historical',
  BREAKEVEN_GAS_MMBTU_BIN = 'Breakeven_Gas_MMBtu_Bin',
  BREAKEVEN_OIL_BBL = 'Breakeven_Oil_bbl',
  BREAKEVEN_OIL_BBL_HISTORICAL = 'Breakeven_Oil_bbl_Historical',
  BREAKEVEN_OIL_BBL_BIN = 'Breakeven_Oil_bbl_Bin',
  CAPEX_WELL_EST_CURRENT = 'Capex_Well_Est_Current',
  CAPEX_WELL_EST_HISTORICAL = 'Capex_Well_Est_Historical',
  CASHFLOW_EST_BTAX = 'Cashflow_Est_BTAX',
  FREE_CASHFLOW = 'Free_Cashflow',
  EUR_DATE_BEG_PARTITION_GAS = 'EUR_Date_Beg_Partition_Gas',
  EUR_DATE_BEG_PARTITION_OIL = 'EUR_Date_Beg_Partition_Oil',
  EUR_DATE_BEG_PARTITION_WATER = 'EUR_Date_Beg_Partition_Water',
  EUR_DATE_END_PARTITION_GAS = 'EUR_Date_End_Partition_Gas',
  EUR_DATE_END_PARTITION_OIL = 'EUR_Date_End_Partition_Oil',
  EUR_DATE_END_PARTITION_WATER = 'EUR_Date_End_Partition_Water',
  EUR_GAS_BCF = 'EUR_Gas_Bcf',
  EUR_MMBOE = 'EUR_MMboe',
  EUR_MMBOE_MKT1 = 'EUR_MMboe_Mkt1',
  EUR_MMBOE_MKT1_BIN = 'EUR_MMboe_Mkt1_Bin',
  EUR_NUMBER_OF_PARTITIONS_GAS = 'EUR_Number_of_Partitions_Gas',
  EUR_NUMBER_OF_PARTITIONS_OIL = 'EUR_Number_of_Partitions_Oil',
  EUR_NUMBER_OF_PARTITIONS_WATER = 'EUR_Number_of_Partitions_Water',
  EUR_OIL_MMBBL = 'EUR_Oil_MMbbl',
  EUR_WATER_MMBBL = 'EUR_Water_MMbbl',
  EUR_CURVE_FIT_B_FACTOR_GAS = 'EUR_Curve_Fit_B_Factor_Gas',
  EUR_CURVE_FIT_B_FACTOR_OIL = 'EUR_Curve_Fit_B_Factor_Oil',
  EUR_CURVE_FIT_B_FACTOR_WATER = 'EUR_Curve_Fit_B_Factor_Water',
  EUR_CURVE_FIT_DI_GAS = 'EUR_Curve_Fit_Di_Gas',
  EUR_CURVE_FIT_DI_OIL = 'EUR_Curve_Fit_Di_Oil',
  EUR_CURVE_FIT_DI_WATER = 'EUR_Curve_Fit_Di_Water',
  EUR_CURVE_FIT_QI_GAS = 'EUR_Curve_Fit_Qi_Gas',
  EUR_CURVE_FIT_QI_OIL = 'EUR_Curve_Fit_Qi_Oil',
  EUR_CURVE_FIT_QI_WATER = 'EUR_Curve_Fit_Qi_Water',
  EUR_DISC_GAS_BCF = 'EUR_Disc_Gas_Bcf',
  EUR_DISC_MMBOE = 'EUR_Disc_MMboe',
  EUR_DISC_MMBOE_MKT1 = 'EUR_Disc_MMboe_Mkt1',
  EUR_DISC_OIL_MMBBL = 'EUR_Disc_Oil_MMbbl',
  EUR_DISC_WATER_MMBBL = 'EUR_Disc_Water_MMbbl',
  EUR_TYPE_BOE = 'EUR_Type_Boe',
  EUR_TYPE_GAS = 'EUR_Type_Gas',
  EUR_TYPE_OIL = 'EUR_Type_Oil',
  EUR_TYPE_WATER = 'EUR_Type_Water',
  PARTITION_INDICATOR_GAS = 'Partition_Indicator_Gas',
  PARTITION_INDICATOR_OIL = 'Partition_Indicator_Oil',
  PARTITION_INDICATOR_WATER = 'Partition_Indicator_Water',
  DECLINE_BOE_12MO_POSTPEAK = 'Decline_Boe_12mo_PostPeak',
  DECLINE_BOE_6MO_POSTPEAK = 'Decline_Boe_6mo_PostPeak',
  DECLINE_BOE_MKT1_12MO_POSTPEAK = 'Decline_Boe_Mkt1_12mo_PostPeak',
  DECLINE_BOE_MKT1_6MO_POSTPEAK = 'Decline_Boe_Mkt1_6mo_PostPeak',
  DECLINE_GAS_12MO_POSTPEAK = 'Decline_Gas_12mo_PostPeak',
  DECLINE_GAS_6MO_POSTPEAK = 'Decline_Gas_6mo_PostPeak',
  DECLINE_OIL_12MO_POSTPEAK = 'Decline_Oil_12mo_PostPeak',
  DECLINE_OIL_6MO_POSTPEAK = 'Decline_Oil_6mo_PostPeak',
  CATEGORY_PEAK_BOE = 'Category_Peak_Boe',
  CATEGORY_PEAK_BOE_MKT1 = 'Category_Peak_Boe_Mkt1',
  CATEGORY_PEAK_GAS = 'Category_Peak_Gas',
  CATEGORY_PEAK_OIL = 'Category_Peak_Oil',
  CATEGORY_TYPE = 'Category_Type',
  QUINTILE_PEAK_BOE = 'Quintile_Peak_Boe',
  QUINTILE_PEAK_BOE_MKT1 = 'Quintile_Peak_Boe_Mkt1',
  QUINTILE_PEAK_GAS = 'Quintile_Peak_Gas',
  QUINTILE_PEAK_OIL = 'Quintile_Peak_Oil',
  QUINTILE_PEAK_PER_LAT_FT_BOE = 'Quintile_Peak_per_Lat_Ft_Boe',
  QUINTILE_PEAK_PER_LAT_FT_BOE_MKT1 = 'Quintile_Peak_per_Lat_Ft_Boe_Mkt1',
  QUINTILE_PEAK_PER_LAT_FT_GAS = 'Quintile_Peak_per_Lat_Ft_Gas',
  QUINTILE_PEAK_PER_LAT_FT_OIL = 'Quintile_Peak_per_Lat_Ft_Oil',
  QUINTILE_PEAK_PER_LAT_FT_TYPE = 'Quintile_Peak_per_Lat_Ft_Type',
  QUINTILE_TYPE = 'Quintile_Type',
  TIER_PEAK_BOE = 'Tier_Peak_Boe',
  TIER_PEAK_BOE_MKT1 = 'Tier_Peak_Boe_Mkt1',
  TIER_PEAK_GAS = 'Tier_Peak_Gas',
  TIER_PEAK_OIL = 'Tier_Peak_Oil',
  TIER_TYPE = 'Tier_Type',
  AZIMUTH = 'Azimuth',
  SPACING_BOUNDING_STATUS = 'Spacing_Bounding_Status',
  SPACING_NEIGHBOR_AT_VINTAGE_API_14_UWI = 'Spacing_Neighbor_at_Vintage_API_14_UWI',
  SPACING_NEIGHBOR_AT_VINTAGE_DATE_VINTAGE = 'Spacing_Neighbor_at_Vintage_Date_Vintage',
  SPACING_NEIGHBOR_AT_VINTAGE_HORIZ_DISTANCE_IN_FT = 'Spacing_Neighbor_at_Vintage_Horiz_Distance_in_ft',
  SPACING_NEIGHBOR_AT_VINTAGE_OPERATOR = 'Spacing_Neighbor_at_Vintage_Operator',
  SPACING_NEIGHBOR_AT_VINTAGE_STRAIGHT_LINE_DISTANCE_IN_FT = 'Spacing_Neighbor_at_Vintage_Straight_Line_Distance_in_ft',
  SPACING_NEIGHBOR_AT_VINTAGE_VERT_DISTANCE_IN_FT = 'Spacing_Neighbor_at_Vintage_Vert_Distance_in_ft',
  SPACING_NEIGHBOR_CURRENT_API_14_UWI = 'Spacing_Neighbor_Current_API_14_UWI',
  SPACING_NEIGHBOR_CURRENT_DATE_VINTAGE = 'Spacing_Neighbor_Current_Date_Vintage',
  SPACING_NEIGHBOR_CURRENT_HORIZ_DISTANCE_IN_FT = 'Spacing_Neighbor_Current_Horiz_Distance_in_ft',
  SPACING_NEIGHBOR_CURRENT_OPERATOR = 'Spacing_Neighbor_Current_Operator',
  SPACING_NEIGHBOR_CURRENT_RELATIONSHIP = 'Spacing_Neighbor_Current_Relationship',
  SPACING_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT = 'Spacing_Neighbor_Current_Straight_Line_Distance_in_ft',
  SPACING_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT_BIN = 'Spacing_Neighbor_Current_Straight_Line_Distance_in_ft_Bin',
  SPACING_NEIGHBOR_CURRENT_VERT_DISTANCE_IN_FT = 'Spacing_Neighbor_Current_Vert_Distance_in_ft',
  SPACING_NEIGHBOR_PARENT_API_14_UWI = 'Spacing_Neighbor_Parent_API_14_UWI',
  SPACING_NEIGHBOR_PARENT_DATE_VINTAGE = 'Spacing_Neighbor_Parent_Date_Vintage',
  SPACING_NEIGHBOR_PARENT_HORIZ_DISTANCE_IN_FT = 'Spacing_Neighbor_Parent_Horiz_Distance_in_ft',
  SPACING_NEIGHBOR_PARENT_OPERATOR = 'Spacing_Neighbor_Parent_Operator',
  SPACING_NEIGHBOR_PARENT_STRAIGHT_LINE_DISTANCE_IN_FT = 'Spacing_Neighbor_Parent_Straight_Line_Distance_in_ft',
  SPACING_NEIGHBOR_PARENT_VERT_DISTANCE_IN_FT = 'Spacing_Neighbor_Parent_Vert_Distance_in_ft',
  SPACING_RES_NEIGHBOR_AT_VINTAGE_API_14_UWI = 'Spacing_Res_Neighbor_at_Vintage_API_14_UWI',
  SPACING_RES_NEIGHBOR_AT_VINTAGE_DATE_VINTAGE = 'Spacing_Res_Neighbor_at_Vintage_Date_Vintage',
  SPACING_RES_NEIGHBOR_AT_VINTAGE_OPERATOR = 'Spacing_Res_Neighbor_at_Vintage_Operator',
  SPACING_RES_NEIGHBOR_AT_VINTAGE_STRAIGHT_LINE_DISTANCE_IN_FT = 'Spacing_Res_Neighbor_at_Vintage_Straight_Line_Distance_in_ft',
  SPACING_RES_NEIGHBOR_CURRENT_API_14_UWI = 'Spacing_Res_Neighbor_Current_API_14_UWI',
  SPACING_RES_NEIGHBOR_CURRENT_DATE_VINTAGE = 'Spacing_Res_Neighbor_Current_Date_Vintage',
  SPACING_RES_NEIGHBOR_CURRENT_OPERATOR = 'Spacing_Res_Neighbor_Current_Operator',
  SPACING_RES_NEIGHBOR_CURRENT_STRAIGHT_LINE_DISTANCE_IN_FT = 'Spacing_Res_Neighbor_Current_Straight_Line_Distance_in_ft',
  SPACING_RES_NEIGHBOR_PARENT_API_14_UWI = 'Spacing_Res_Neighbor_Parent_API_14_UWI',
  SPACING_RES_NEIGHBOR_PARENT_DATE_VINTAGE = 'Spacing_Res_Neighbor_Parent_Date_Vintage',
  SPACING_RES_NEIGHBOR_PARENT_OPERATOR = 'Spacing_Res_Neighbor_Parent_Operator',
  SPACING_RES_NEIGHBOR_PARENT_STRAIGHT_LINE_DISTANCE_IN_FT = 'Spacing_Res_Neighbor_Parent_Straight_Line_Distance_in_ft',
  WELL_PATH_AZIMUTH = 'Well_Path_Azimuth',
  WELL_PATH_TOE_DELTA = 'Well_Path_Toe_Delta',
  WELL_PATH_TORTUOSITY_RATIO = 'Well_Path_Tortuosity_Ratio',
  ACREAGE_EST_CLASS = 'Acreage_Est_Class',
  ACREAGE_IDENTIFIER = 'Acreage_Identifier',
  ACREAGE_OPERATOR = 'Acreage_Operator',
  ACREAGE_TOTAL_FOOTAGE_DRILLED = 'Acreage_Total_Footage_Drilled',
  ACREAGE_TOTAL_FOOTAGE_DRILLED_AND_PROPOSED = 'Acreage_Total_Footage_Drilled_and_Proposed',
  ACREAGE_WELL_PERFORMANCE_CATEGORY_VS_ACREAGE_CLASS = 'Acreage_Well_Performance_Category_vs_Acreage_Class',
  ACREAGE_WELL_PERFORMANCE_DIFFERENCE_VS_ACREAGE_CLASS = 'Acreage_Well_Performance_Difference_vs_Acreage_Class',
  ELAPSED_TIME_NORM_MONTH = 'Elapsed_Time_Norm_Month',
  WKT = 'WKT',
  NPV = 'NPV',
  RPV = 'RPV',
  IRR = 'IRR',
}

export const isImpactWellAndProdColumn = isSomeEnum(ImpactWellAndProdColumn);
