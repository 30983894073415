import turfDistance from '@turf/distance';

import { point as turfPoint } from '@turf/helpers';

/**
 * Get distance in feet (not in meters)
 */
export function getWellBoundaryPointsFeetDistance<T extends { lat: number; lng: number }>(startPoint: T, endPoint: T) {
  const fromPoint = turfPoint([startPoint.lng, startPoint.lat]);
  const toPoint = turfPoint([endPoint.lng, endPoint.lat]);

  return Math.round(turfDistance(fromPoint, toPoint, { units: 'feet' }));
}
